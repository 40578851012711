import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Depository, Experience, Gender, GenderString, kraStatus, MarriedStatus, PageLinks, PersonVideVerifyStatus, Status, Yes_or_No, AccountStatus, BondType, ProjectConfig, Endpoints, Strings } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import DataTable from "react-data-table-component";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplayData, getDownloadFile, getDownloadFileXsl, getDownloadURLExtension, getFormattedLocalDate, getOperationStatus, getFormattedLocalDateTime, getOrderStatus, numberWithCommas } from "../common/Utilities";
import AccountStatusModal from "../modals/AccountStatusModal";
import PreviewJSONModal from "../modals/PreviewJSONModal";
// const HTMLToPDF = require("html-to-pdf");
// import HTMLToPDF from "html-to-pdf"
import { jsPDF } from "jspdf";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Pagination from "@mui/material/Pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";


var startSerialNo = 0;

const UsersDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const customerId = location.state;
  // const [customerId,setCustomerid] = useState(customerId)
  const { updateAccountStatus } = useAppStateAPI();
  const [userId, setUserId] = useState();
  const [personalDetails, setPersonlaDetails] = useState<APIData.personalDetail>();
  const [FACTADetails, setFACTADetails] = useState<APIData.FactaDetails[]>([]);
  const [bankDetails, setBankDetails] = useState<APIData.BankDetailsDatas[]>([]);
  const [Cvl_kraAddresDetails, setCvl_kraAddressDetails] = useState<APIData.AddressDatas_cvl_kra>();
  const [digilocker_aAddresDetails, setDigilockerAddressDetails] = useState<APIData.Digilocker_address_data>();
  const [existing_demat_details, setExistingDematDetails] = useState<APIData.ExistingDematAccountDetails[]>([]);
  const [new_demat_details, setNewDematDetails] = useState<APIData.NewDematAccountDetails[]>([]);
  const [suspended_data, setSuspendedData] = useState({});
  const [account_status_detail, setAccount_status_detail] = useState<APIData.AccountStatusDetails>();
  const [nominee_details, setNomineeDetails] = useState<APIData.NomineeDetails[]>([]);
  const [open, setOpen] = useState(false);
  const [documentDetails, setDocumentDetails] = useState<any>({});
  const [documentProofDetails, setDocumentProofDetails] = useState<APIData.personalDetail>();
  const { setShowPreloader } = useAppStateAPI();
  const [cvlkraJSON, setcvlkraJSON] = useState<any>();
  const [openJSON, setOpenJSON] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [search_text, setSearchText] = useState("");
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [select_module, setSelectModule] = useState<any>(null);

  const [module_list, setModuleList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [userLogList, setUserlogList] = useState<APIData.AlluserLogs[]>([]);

  const [myOrders, setMyOrders] = useState<APIData.UserDetailsMyOrders[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [isFacta, setIsFacta] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * 10 - 10;
  const [preloader, setpreloader] = useState(false);
  let columns1: any = [
    {
      name: 'Sr.No',
width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
    },
    {
      name: "Cust. ID",
      selector: (row: any) => getDisplayData(row.user_customer_id),
      sortable: true,
    },
    {
      name: "Updated Date & Time",
      selector: (row: any) => getDisplayData(row.user_logs_created_at),
      sortable: true,
      format: (row: any) => moment(row.user_logs_created_at).format('DD-MM-YYYY hh:mm A'),
    },
    {
      name: "IP Address",
      selector: (row: any) => getDisplayData(row.ip_address),
      sortable: true,
    },
    {
      name: "Platform",
      selector: (row: any) => getDisplayData(row.platform),
      sortable: true,
    },
    {
      name: "Module",
      selector: (row: any) => getDisplayData(row.module),
      sortable: true,
    },
    {
      name: "Method",
      selector: (row: any) => getDisplayData((row.operation_type === "" || row.operation_type === null && 'N/A') || row.operation_type == 1 && 'POST' || row.operation_type == 2 && 'UPDATE' || row.operation_type == 3 && 'DELETE' || row.operation_type == 4 && 'GET'),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row: any) => getOperationStatus(row.operation_status),
      sortable: true,
    },
    {
      name: "Last Login Date&Time",
      selector: (row: any) => getDisplayData(row.user_last_login),
      sortable: true,
      format: (row: any) => moment(row.user_last_login).format('DD-MM-YYYY hh:mm A'),

    }
  ];
  let columns: any = [
    {
      name: 'Sr.No',
width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
    },
    {
      name: "Order No",
      selector: (row: any) => getDisplayData(row.order_number),
      sortable: true,
    },
    {
      name: "Order Date & Timee",
      selector: (row: any) => getDisplayData(row.order_datetime),
      sortable: true,
      format: (row: any) => moment(row.order_datetime).format('DD-MM-YYYY hh:mm A'),
    },
    {
      name: "Bond / IPO Name",
      selector: (row: any) => getDisplayData(row.bond_name),
      sortable: true,
      width: "200px",
            wrap: true
    },
    {
      name: "No. Of Quantity",
      selector: (row: any) => getDisplayData(row.order_quantity),
      sortable: true,
    },
    {
      name: "Total Amount",
      selector: (row: any) => getDisplayData(row.order_amount),
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row: any) => getOrderStatus(row.order_status),
      sortable: true,
    },
    {
      name: "Action",
      selector: (row: any) => getOperationStatus(row.operation_status),
      cell: (row: any) => [
        <td className="city-action-rable-row ">
          <i
            className="fas fa-eye table-icons pe-1"
            // onClick={() =>
            //   navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
            //     state: { type: res.order_type, id: res.order_id },
            //   })
            // }
            onClick={() => {
              if (row.order_type == BondType.IPO) {
                navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                  state: { type: row.order_type, id: row.order_id, path: "user_order" },
                });
              } else if (row.order_type == BondType.ADminBond) {
                navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                  state: { type: row.order_type, id: row.order_id, path: "user_order" },
                });
              } else if (row.order_type == BondType.SGB) {
                navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                  state: { type: row.order_type, id: row.order_id, path: "user_order" },
                });
              }
            }}
          ></i>
          {row.order_type === BondType.IPO ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(row.order_pdf)}></img> : ""}
        </td>
      ]
    },
  ];
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
  }
  // useEffect(() => {
  //   if(updateAccountStatus == true && userId ) {
  //     user_details_datas(userId);
  //     setpreloader(!preloader);
  //   }
  // }, [updateAccountStatus])

  const handleStatusReload = (e: any) => {
    console.log(e)
    // e.preventDefault();
    if (e == true && userId) {
      console.log(e)
      setpreloader(!preloader);
      user_details_datas(userId);
    }
  }

  useEffect(() => {
    console.log(userId)
  }, [userId])


  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className='datePickerStyle'>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
      </div>
    );
  });
  useEffect(() => {
    if (customerId != 0) {
      user_details_datas(customerId);
      // myOrdersLists(customerId);
    } else {
      toast.error("Customer Details not found");
    }
  }, [customerId]);

  // const myOrdersLists = (userId: number) => {
  //   setShowPreloader(true);
  //   ServerAPI.UserDetailMyOrders(userId).then((res) => {
  //       setMyOrders(res.data);
  //       setTotalCount(res.total_records);
  //     })
  //     .finally(() => {
  //       setShowPreloader(false);
  //     });
  // };

  const user_details_datas = (userId: number) => {
    setShowPreloader(true);
    ServerAPI.UserDetail(userId).then((res) => {
      setDocumentDetails(res.datas["document_details"]);
      setAccount_status_detail(({ ...res.datas["account_status"] }));
      setSuspendedData(res.datas["suspension_details"]);
      setPersonlaDetails(res.datas["personal_details"]);
      setUserId(res.datas["personal_details"].user_id);
      setFACTADetails(res.datas["new_demat_account_details"]);
      setBankDetails(res.datas["bank_details"]);
      setCvl_kraAddressDetails(res.datas["external_details"].cvl_kra);
      setDigilockerAddressDetails(res.datas["external_details"].digilocker);
      setExistingDematDetails(res.datas["exist_demat_account_details"]);
      setNewDematDetails(res.datas["new_demat_account_details"]);
      setNomineeDetails(res.datas["nominee_details"]);
      setDocumentProofDetails(res.datas["document_details"].personal_details);
      setcvlkraJSON(res.datas["external_details"].cvl_kra_records);
      setIsFacta(res.datas["personal_details"].citizen_usa);
    })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  useEffect(() => {
    if (userId && tabIndex == 2) {
      setShowPreloader(true);
      ServerAPI.UserLogs(pageNumber, userId, search_text, toData, fromDate, select_module)
        .then((res) => {
          setUserlogList(res.data);
          setTotalCount(res.total_records);
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
    // if(userId) {
    //   loadTableData(search_text,pageNumber,fromDate,toData,userId,0,Endpoints.individual_user_logs)
    // }
    // if(customerId){
    //   loadTableData(search_text,pageNumber,fromDate,toData,0,customerId,Endpoints.SpecificOrder)
    // }
  }, [pageNumber, search_text, toData, fromDate, select_module, userId, tabIndex]);

  useEffect(() => {
    if (customerId && tabIndex == 1) {
      setShowPreloader(true);
      ServerAPI.UserDetailMyOrders(pageNumber, customerId, search_text, toData, fromDate).then((res) => {
        setMyOrders(res.data);
        setTotalCount(res.total_records);
      })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  }, [pageNumber, search_text, toData, fromDate, customerId, tabIndex]);

  const loadTableData = (searchString?: string, pageno?: number, fromDate?: any, toDate?: any, userId?: number, customerID?: number, endPoint?: string) => {
    setShowPreloader(false);
    if (endPoint !== undefined) {
      let params: { [k: string]: any } = {};
      if (pageno != undefined ? pageno > 0 : '') {
        params['page_number'] = pageno;
        params['limit'] = ProjectConfig.EntriesPerPage;
      } else {
        params['page_number'] = 1;
        params['limit'] = ProjectConfig.EntriesPerPage;
      }
      if ((fromDate != '' && fromDate != null) && (toDate != '' && toDate != null)) {
        params['from_date'] = moment(fromDate).format('YYYY-MM-DD');
        params['to_date'] = moment(toDate).format('YYYY-MM-DD');
      }
      if (select_module !== "" && select_module !== null) {
        params['select_module'] = select_module;
      }
      if (searchString !== '') {
        params['search_text'] = searchString;
      }
      if (userId) {
        params["user_id"] = userId;
      }
      if (customerID) {
        params["user_id"] = customerID;
      }
      setShowPreloader(true);
      ServerAPI.executeAPI(endPoint + `${endPoint == Endpoints.SpecificOrder ? '_list' : ""}`, ServerAPI.APIMethod.GET, true, null, params).then(response => {
        // const responseTag = endPoint.replaceAll('-', '_');
        if (response) {
          console.log(response)
          if (Endpoints.individual_user_logs == endPoint && tabIndex == 2) {
            console.log('individual user logs')
            setUserlogList(response.data);
            setTotalCount(response.total_records);
          } else {
            console.log('my order')
            setMyOrders(response.data);
            setTotalCount(response.total_records);
          }
        } else {
          toast.error(Strings.ErrorAPIFailed);
        }
      }).finally(() => {
        setShowPreloader(false);
      })
    }
  }
  const handelClickXsl = () => {
    setShowPreloader(true);
    getDownloadFileXsl("https://trust-api.trustmony.com/admin/api/v1/user-logs-xl")
      setShowPreloader(false);
  }
  const handleClickOpen = () => { };

  // const handleClose = (e: any) => {
  //   setOpen(e);
  //   user_details_datas();
  // };

  const status_update = (e: any) => {
    setOpen(true);
    setUserId(e);
    // if(e){
    //   user_details_datas(e)
    // }
  };

  const handleCVLKRAjson = (data: any) => {
    console.log(data);
    // const jsonString = JSON.stringify(data);
    // const newWindow = window.open("", "JSON Data");
    // if (newWindow) {
    //     newWindow.document.write(`<html><body><pre>${jsonString}</pre></body></html>`);
    // }
    setOpenJSON(true);
  };

  useEffect(() => {
    ServerAPI.UserLogsModule().then((res: any) => {
      setModuleList(res);
    });
  }, [pageNumber]);

  return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                    <div className="page-title-rightds">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trustmony</a>
                        </li>
                        <li className="breadcrumb-item ">Users</li>
                        <li className="breadcrumb-item active">
                          <b>Detail View</b>
                        </li>
                      </ol>
                    </div>
                    <div className="cursor-pointer" onClick={() => navigate(PageLinks.USERS)}>
                      <p className="mb-0 active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                          <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                        </svg>
                        <span> Back </span>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header col-xl-12 w-100">
                          <div className="d-flex justify-content-end align-items-center">
                            {/* <h5 className="my-3 header"> Users Details </h5> */}

                            <div className="">
                              {/* <a
                                className="save-btn"
                                onClick={() => {
                                  handleCVLKRAjson(cvlkraJSON);
                                }}
                              >
                                CVL KRA json
                              </a> */}

                              {/* <a className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2" onClick={() => navigate(PageLinks.INDIVIDUAL_USER_LOGS, { state: customerId })}>
                                {" "}
                                User Logs <i className="fas fa-eye table-icons px-2"></i>
                              </a> */}
                              {/* {account_status_detail?.user_status_key !== AccountStatus.Suspended && (
                                <a className="cancel-btn_1 border-light quicksand-medium px-2 py-2" onClick={(e) => status_update(personalDetails?.user_id)}>
                                  {" "}
                                  Account Status: {account_status_detail?.user_status_key === AccountStatus.Active && <span className="text-green quicksand-bold mx-2">Active</span>}
                                  {account_status_detail?.user_status_key === AccountStatus.Closed && <span className="text-red quicksand-bold mx-2">Closed</span>}
                                  {account_status_detail?.user_status_key === AccountStatus.Register && <span className="text-blue quicksand-bold mx-2">Register</span>}
                                  <i className="fa fa-angle-down" aria-hidden="true"></i>{" "}
                                </a>
                              )} */}
                            </div>
                          </div>

                          {account_status_detail?.user_status_key === AccountStatus.Suspended && (
                            <div className="card">
                              <div className="card-header">
                                <h5 className="m-0 header"> Suspension Details</h5>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Start Date</p>
                                    {account_status_detail?.suspended_start_date === "" || account_status_detail?.suspended_start_date === null ? <h6> N/A </h6> : <h6>{moment(account_status_detail?.suspended_start_date).format("DD-MM-YYYY")}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">End Date</p>
                                    {account_status_detail?.suspended_end_date === "" || account_status_detail?.suspended_end_date === null ? <h6> N/A </h6> : <h6>{moment(account_status_detail?.suspended_end_date).format("DD-MM-YYYY")}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Reason</p>
                                    {account_status_detail?.account_status_reason === "" || account_status_detail?.account_status_reason === null ? <h6> N/A </h6> : <h6>{account_status_detail?.account_status_reason}</h6>}
                                  </div>
                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                    <p className="detail-title">Remark</p>
                                    {account_status_detail?.account_status_remarks === "" || account_status_detail?.account_status_remarks === null ? <h6> N/A </h6> : <h6>{account_status_detail?.account_status_remarks}</h6>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <Tabs selectedIndex={tabIndex} onSelect={(index) => { setTabIndex(index); setSearchText(''); }}>
                            <TabList className="Product_detail_tablist" style={{ marginBottom: "0rem" }}>
                              <div className=" d-flex justify-content-between align-items-end">
                                <div>
                                  <Tab className="product_detail_tab">Personal Details</Tab>
                                  <Tab className="product_detail_tab">My Orders</Tab>
                                  <Tab className="product_detail_tab">User Logs</Tab>
                                </div>
                                <div>
                                  {account_status_detail?.user_status_key !== AccountStatus.Suspended && (
                                    <a className="cancel-btn_1 border-light quicksand-medium px-2 py-2" onClick={(e) => status_update(personalDetails?.user_id)}>
                                      {" "}
                                      Account Status: {account_status_detail?.user_status_key === AccountStatus.Active && <span className="text-green quicksand-bold mx-2">Active</span>}
                                      {account_status_detail?.user_status_key === AccountStatus.Closed && <span className="text-red quicksand-bold mx-2">Closed</span>}
                                      {account_status_detail?.user_status_key === AccountStatus.Register && <span className="text-blue quicksand-bold mx-2">Register</span>}
                                      <i className="fa fa-angle-down" aria-hidden="true"></i>{" "}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </TabList>
                            <TabPanel>
                              <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                                <div className="card-header" style={{ borderBottom: "none" }}>
                                  <div className="navigation_menu" id="navigation_menu">
                                    <ul className="navigation_tabs" id="navigation_tabs">
                                      <li className={`${personalDetails?.in_person_verification == 1 ? "tab_inactive" : "tab_disabled"}`}>
                                        <a>Personal Info</a>
                                      </li>
                                      <li className={`${personalDetails?.is_pan_verified == 1 ? "tab_inactive" : "tab_disabled"}`}>
                                        <a>PAN Details</a>
                                      </li>
                                      <li className={`${personalDetails?.is_aadhar_verified == 1 ? "tab_inactive" : "tab_disabled"}`}>
                                        <a>Aadhaar Details</a>
                                      </li>
                                      <li className={`${personalDetails?.is_nominee_added == 1 || personalDetails?.is_nominee_added == 0 ? "tab_inactive" : "tab_disabled"}`}>
                                        <a>Nominee Details</a>
                                      </li>
                                      <li className={`${isFacta != 1 ? "tab_inactive" : "tab_disabled"}`}>
                                        <a>FACTA Details</a>
                                      </li>
                                      <li className={`${bankDetails.filter((e: APIData.BankDetailsDatas) => e.is_bank_account_verified == 1).length >= 1 ? "tab_inactive" : "tab_disabled"}`}>
                                        <a>BANK Details</a>
                                      </li>
                                      <li className={`${new_demat_details.filter((e: APIData.NewDematAccountDetails) => e.demat_account_status == 1).length >= 1 || existing_demat_details.filter((e: APIData.ExistingDematAccountDetails) => e.demat_account_status == 1).length >= 1 ? "tab_inactive" : "tab_disabled"}`}>
                                        <a>Demat Details</a>
                                      </li>
                                      <li className="tab_disabled">
                                        <a>Account Details</a>
                                      </li>
                                      <li className={`${(documentDetails?.cheque_cancelled_image != undefined && documentDetails?.cheque_cancelled_image.length > 0) || (documentDetails?.personal_details != undefined && Object.keys(documentDetails?.personal_details).length != 0) || (documentDetails?.esign_pdf != undefined && Object.keys(documentDetails?.esign_pdf).length != 0) ? "tab_inactive" : "tab_disabled"}`}>
                                        <a>Document Details</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Customer ID</p>
                                      {personalDetails?.customer_id === "" || personalDetails?.customer_id === null ? <h6> N/A </h6> : <h6>{personalDetails?.customer_id}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Customer Type</p>
                                      {personalDetails?.customer_id === "" || personalDetails?.customer_id === null ? <h6> N/A </h6> : <h6>{personalDetails?.customer_id}</h6>}
                                    </div>
                                    {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Customer Category</p>
                                      {personalDetails?.customer_id === "" || personalDetails?.customer_id === null ? <h6> N/A </h6> : <h6>{personalDetails?.customer_id}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Customer Sub Category</p>
                                      {personalDetails?.customer_id === "" || personalDetails?.customer_id === null ? <h6> N/A </h6> : <h6>{personalDetails?.customer_id}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Customer Group</p>
                                      {personalDetails?.firstname === null || personalDetails?.firstname === "null" ? <h6>N/A</h6> : <h6>{personalDetails?.firstname}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Title</p>
                                      {personalDetails?.firstname === null || personalDetails?.firstname === "null" ? <h6>N/A</h6> : <h6>{personalDetails?.firstname}</h6>}
                                    </div> */}
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">First Name</p>
                                      {personalDetails?.firstname === null || personalDetails?.firstname === "null" ? <h6>N/A</h6> : <h6>{personalDetails?.firstname}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Middle Name</p>
                                      {personalDetails?.firstname === "" || personalDetails?.firstname === null ? <h6> N/A </h6> : <h6>{personalDetails?.firstname}</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Last Name</p>
                                      {personalDetails?.lastname === "" || personalDetails?.lastname === null ? <h6> N/A </h6> : <h6>{personalDetails?.lastname}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Mothers Maiden Name</p>
                                      {personalDetails?.mothers_maiden_name === "" || personalDetails?.mothers_maiden_name === null ? <h6> N/A </h6> : <h6>{personalDetails?.mothers_maiden_name}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Date Of Birth</p>
                                      {personalDetails?.dob === "" || personalDetails?.dob === null ? <h6> N/A </h6> : <h6>{personalDetails?.dob}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Phone Number - Primary</p>
                                      {personalDetails?.mobile_number === null || personalDetails?.mobile_number === null ? (
                                        <h6> N/A </h6>
                                      ) : (
                                        <h6>
                                          {personalDetails?.mobile_number} {personalDetails?.is_mobile_verified === Status.VERIFY ? <a className="verified mx-2">Verified</a> : <a className="not-verified mx-2">Verify</a>}
                                        </h6>
                                      )}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Email Id</p>
                                      {personalDetails?.email_id === null || personalDetails?.email_id === null ? (
                                        <h6> N/A </h6>
                                      ) : (
                                        <Fragment>
                                          <h6> {personalDetails?.email_id}</h6>
                                          {personalDetails?.is_email_verified === Status.VERIFY ? <a className="verified mx-2">Verified</a> : <a className="not-verified mx-2">Verify</a>}
                                        </Fragment>
                                      )}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Gender</p>
                                      {personalDetails?.gender === null && <h6>N/A</h6>}
                                      {personalDetails?.gender === 0 && <h6>N/A</h6>}
                                      {(personalDetails?.gender === Gender.MALE || personalDetails?.gender === GenderString.MALE) && <h6>Male</h6>}
                                      {(personalDetails?.gender === Gender.FEMALE || personalDetails?.gender === GenderString.FEMALE) && <h6>Female</h6>}
                                      {(personalDetails?.gender === Gender.OTHER || personalDetails?.gender === GenderString.OTHER) && <h6>Other</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Marital Status</p>
                                      {personalDetails?.married_status === null && <h6> N/A </h6>}
                                      {/* {personalDetails?.married_status === MarriedStatus.SINGLE && <h6>Single</h6>}
                                  {personalDetails?.married_status === MarriedStatus.MARRIED && <h6>Married</h6>} */}
                                      <h6>{personalDetails?.married_status_name}</h6>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Profession</p>
                                      {personalDetails?.occupation === "" || personalDetails?.occupation === null || personalDetails?.occupation === "null" ? <h6> N/A </h6> : <h6>{personalDetails?.occupation === 1 ? "Salary" : "Business"}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Income</p>
                                      {personalDetails?.annual_income === null && <h6>N/A</h6>}
                                      {personalDetails?.annual_income === Annual_income_status.below_one && <h6>Below 1 Lakh</h6>}
                                      {personalDetails?.annual_income === Annual_income_status.one_to_five && <h6> 1 - 5 Lakhs</h6>}
                                      {personalDetails?.annual_income === Annual_income_status.five_to_ten && <h6>1 - 5 Lakhs</h6>}
                                      {personalDetails?.annual_income === Annual_income_status.ten_to_twentyfive && <h6>10 - 25 Lakhs</h6>}
                                      {personalDetails?.annual_income === Annual_income_status.above_twntyfive && <h6>Above 25 Lakhs</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Trading Experience</p>
                                      {personalDetails?.trading_experience === null && <h6>N/A</h6>}
                                      {personalDetails?.trading_experience === Experience.below_one && <h6>Less than 1 year</h6>}
                                      {personalDetails?.trading_experience === Experience.one_to_two && <h6>1 - 2 Years </h6>}
                                      {personalDetails?.trading_experience === Experience.two_to_five && <h6>2 - 5 Years</h6>}
                                      {personalDetails?.trading_experience === Experience.five_to_ten && <h6>5 - 10 Years</h6>}
                                      {personalDetails?.trading_experience === Experience.ten_to_twenty && <h6>10 - 20 Years</h6>}
                                      {personalDetails?.trading_experience === Experience.twenty_to_twentyfive && <h6>20 - 25 Years</h6>}
                                      {personalDetails?.trading_experience === Experience.above_twentyfive && <h6>Above 25 Years</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">IPV</p>
                                      {personalDetails?.in_person_verification != null && personalDetails?.in_person_verification == 0 ? (
                                        <>
                                          {personalDetails?.in_person_verification === PersonVideVerifyStatus.NOT_VERIFY && (
                                            <h6>
                                              {personalDetails.customer_id}.mp4{" "}
                                              <Link to={PageLinks.IPVVERIFY} state={customerId} className="not-verified mx-2">
                                                Verify
                                              </Link>{" "}
                                            </h6>
                                          )}
                                          {personalDetails?.in_person_verification === PersonVideVerifyStatus.VERIFY && (
                                            <h6>
                                              {personalDetails.customer_id}.mp4{" "}
                                              <Link to={PageLinks.IPVVERIFY} state={customerId} className="verified mx-2">
                                                Verified
                                              </Link>{" "}
                                            </h6>
                                          )}
                                          {personalDetails?.in_person_verification === PersonVideVerifyStatus.REJECT && (
                                            <h6>
                                              {personalDetails.customer_id}.mp4{" "}
                                              <Link to={PageLinks.IPVVERIFY} state={customerId} className="not-verified mx-2">
                                                Rejected
                                              </Link>{" "}
                                            </h6>
                                          )}
                                        </>
                                      ) : personalDetails?.in_person_verification != null && personalDetails?.in_person_verification == 1 ? <a className="verified">
                                        CVL KRA Verified
                                      </a> : (
                                        <h6> N/A </h6>
                                      )}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Last Update</p>
                                      {personalDetails?.personal_details_updated_at === "" || personalDetails?.personal_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(personalDetails?.personal_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />

                              {account_status_detail?.user_status_key !== AccountStatus.Register && account_status_detail?.user_status_key !== AccountStatus.Suspended && (
                                <div className="card">
                                  <div className="card-header">
                                    <h5 className="m-0 header"> Account Status</h5>
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Status</p>

                                        {account_status_detail?.user_status_key === AccountStatus.Active && <h6 className="text-green">Active</h6>}
                                        {account_status_detail?.user_status_key === AccountStatus.Closed && <h6 className="text-red"> Closed </h6>}
                                      </div>

                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Date</p>
                                        {/* <h6>N/A</h6> */}
                                        {account_status_detail?.account_status_updated_at === "" || account_status_detail?.account_status_updated_at === null ? <h6> N/A </h6> : <h6>{moment(account_status_detail?.account_status_created_at).format("DD/MM/YYYY")}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Reason</p>
                                        {/* <h6>N/A</h6> */}
                                        <h6>{getDisplayData(account_status_detail?.account_status_reason)}</h6>
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Remark</p>
                                        {/* <h6>N/A</h6> */}
                                        <h6>{getDisplayData(account_status_detail?.account_status_remarks)}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header"> PAN Details / GST Details / KRA Status </h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">PAN</p>
                                      {personalDetails?.pan_number != null ? (
                                        <h6>
                                          {personalDetails?.pan_number}
                                          {personalDetails?.is_pan_verified === Status.VERIFY && <a className="verified mx-2">Verified</a>}
                                          {personalDetails?.is_pan_verified === Status.NOT_VERIFY && (
                                            <Link to={PageLinks.IPVVERIFY} className="verified mx-2">
                                              Verified
                                            </Link>
                                          )}
                                        </h6>
                                      ) : (
                                        <h6>N/A</h6>
                                      )}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">PAN Name (As per Record)</p>
                                      {/* <h6>{!personalDetails?.pan_name && "N/A"}</h6> */}
                                      {personalDetails?.pan_name === "" || personalDetails?.pan_name === null ? <h6> N/A </h6> : <h6>{personalDetails?.pan_name}</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Father Name (As per Record)</p>
                                      <h6>{getDisplayData(Cvl_kraAddresDetails?.cvl_kra_father_name)}</h6>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Aadhaar Seeding Status</p>
                                      <h6>{getDisplayData(Cvl_kraAddresDetails?.cvl_kra_aadhaar_seeding_status == Yes_or_No.YES ? "Yes" : "No")}</h6>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">IPV Seeding Status</p>
                                      {Cvl_kraAddresDetails?.cvl_kra_ipv_seeding_status === "" || Cvl_kraAddresDetails?.cvl_kra_ipv_seeding_status === null ? <h6> N/A </h6> : <h6>{Cvl_kraAddresDetails?.cvl_kra_ipv_seeding_status == Yes_or_No.YES ? "Yes" : "No"}</h6>}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">GST Available</p>
                                      <h6>{getDisplayData(Cvl_kraAddresDetails?.cvl_kra_gst_available)}</h6>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">GST No</p>
                                      <h6>{getDisplayData(Cvl_kraAddresDetails?.cvl_kra_gst_number)}</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">API Type</p>
                                      <h6>{getDisplayData(Cvl_kraAddresDetails?.cvl_kra_api_type)}</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Last Update</p>
                                      {Cvl_kraAddresDetails?.cvl_kra_updated_at === "" || Cvl_kraAddresDetails?.cvl_kra_updated_at === null ? <h6> N/A </h6> : <h6>{moment(Cvl_kraAddresDetails?.cvl_kra_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />

                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header"> Aadhaar Details </h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Aadhaar Number</p>
                                      {personalDetails?.aadhar_number != null ? (
                                        <h6>
                                          {personalDetails?.aadhar_number}
                                          {personalDetails?.is_aadhar_verified === Status.VERIFY && <a className="verified mx-2">Verified</a>}
                                          {personalDetails?.is_aadhar_verified === Status.NOT_VERIFY && (
                                            <Link to={PageLinks.IPVVERIFY} className="verified mx-2">
                                              Verified
                                            </Link>
                                          )}
                                        </h6>
                                      ) : (
                                        <h6>N/A</h6>
                                      )}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Name (As per Record) </p>
                                      {personalDetails?.aadhar_name === "" || personalDetails?.aadhar_name === null ? <h6> N/A </h6> : <h6>{personalDetails?.aadhar_name}</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Date Of Birth</p>
                                      {!digilocker_aAddresDetails?.date_of_birth && <h6>N/A</h6>}
                                      {digilocker_aAddresDetails?.date_of_birth === "" || digilocker_aAddresDetails?.date_of_birth === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.date_of_birth}</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Gender</p>
                                      {/* {!digilocker_aAddresDetails?.gender && } */}
                                      {digilocker_aAddresDetails?.gender != null || digilocker_aAddresDetails?.gender !== "" ? (
                                        <>
                                          {(digilocker_aAddresDetails?.gender === Gender.MALE || digilocker_aAddresDetails?.gender === GenderString.MALE) && <h6>Male</h6>}
                                          {(digilocker_aAddresDetails?.gender === Gender.FEMALE || digilocker_aAddresDetails?.gender === GenderString.FEMALE) && <h6>Female</h6>}
                                          {(digilocker_aAddresDetails?.gender === Gender.OTHER || digilocker_aAddresDetails?.gender === GenderString.OTHER) && <h6>Other</h6>}
                                        </>
                                      ) : (
                                        <h6>N/A</h6>
                                      )}
                                      {!digilocker_aAddresDetails?.gender && <h6>N/A</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">API Type </p>
                                      {!digilocker_aAddresDetails?.api_type && <h6>N/A</h6>}
                                      {digilocker_aAddresDetails?.api_type === "" || digilocker_aAddresDetails?.api_type === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.api_type}</h6>}
                                    </div>
                                    {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Type (As per Aadhaar)</p>
                                                                    {!digilocker_aAddresDetails?.address_type && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.address_type === "" || digilocker_aAddresDetails?.address_type === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.address_type}</h6>}
                                                                </div> */}

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Address Line 1</p>
                                      {/* {!digilocker_aAddresDetails?.care_of && <h6>N/A</h6>} */}
                                      {/* {digilocker_aAddresDetails?.care_of && (
                                        <>
                                          {digilocker_aAddresDetails?.care_of === "" || digilocker_aAddresDetails?.care_of === null ? (
                                            <h6> N/A </h6>
                                          ) : (
                                            <h6>
                                              {digilocker_aAddresDetails?.house_no},{digilocker_aAddresDetails?.street},{digilocker_aAddresDetails?.landmark}
                                            </h6>
                                          )}
                                        </>
                                      )} */}
                                      <h6>
                                        {digilocker_aAddresDetails?.house_no},{digilocker_aAddresDetails?.street},{digilocker_aAddresDetails?.landmark}
                                      </h6>
                                      {/* <h6>{getDisplayData(personalDetails?.address_line_1)}</h6> */}
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Address Line 2</p>
                                      {/* <h6>{getDisplayData(digilocker_aAddresDetails?.address_line_2)}</h6> */}
                                      <h6>
                                        {digilocker_aAddresDetails?.village_town_city},{digilocker_aAddresDetails?.location}
                                      </h6>
                                      {/* {!digilocker_aAddresDetails?.landmark && <h6>N/A</h6>}
                                      {digilocker_aAddresDetails?.landmark && (
                                        <>
                                          {digilocker_aAddresDetails?.landmark === "" || digilocker_aAddresDetails?.landmark === null ? (
                                            <h6> N/A </h6>
                                          ) : (
                                            <h6>
                                              {digilocker_aAddresDetails?.village_town_city},{digilocker_aAddresDetails?.location}
                                            </h6>
                                          )}
                                        </>
                                      )} */}
                                    </div>
                                    {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 3</p>
                                                                    {!digilocker_aAddresDetails?.address_line_3 && <h6>N/A</h6>}
                                                                    {digilocker_aAddresDetails?.address_line_3 === "" || digilocker_aAddresDetails?.address_line_3 === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.address_line_3}</h6>}
                                                                </div> */}
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">City</p>
                                      <h6>{getDisplayData(digilocker_aAddresDetails?.village_town_city)}</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">State</p>
                                      {!digilocker_aAddresDetails?.state && <h6>N/A</h6>}
                                      {digilocker_aAddresDetails?.state === "" || digilocker_aAddresDetails?.state === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.state}</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Pincode</p>
                                      {!digilocker_aAddresDetails?.pincode && <h6>N/A</h6>}
                                      {digilocker_aAddresDetails?.pincode === "" || digilocker_aAddresDetails?.pincode === null ? <h6> N/A </h6> : <h6>{digilocker_aAddresDetails?.pincode}</h6>}
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Last Update</p>
                                      {digilocker_aAddresDetails?.digilocker_details_updated_at === "" || digilocker_aAddresDetails?.digilocker_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(digilocker_aAddresDetails?.digilocker_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <br />
                              {personalDetails?.address_proof_id !== undefined && personalDetails?.address_proof_id !== null &&
                                <div className="card">
                                  <div className="card-header">
                                    <h5 className="m-0 header"> Updated Address</h5>
                                  </div>
                                  <div className="card-body">
                                    <div className="row">
                                      {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Type </p>
                                                                    {!personalDetails?.address_type && <h6>N/A</h6>}
                                                                    {personalDetails?.address_type === "" || personalDetails?.address_type === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_type}</h6>}
                                                                </div> */}

                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Address Line 1</p>
                                        <h6>{getDisplayData(personalDetails?.address_line_1)}</h6>
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Address Line 2</p>
                                        {personalDetails?.address_line_2 === "" || personalDetails?.address_line_2 === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_line_2.split("null")}</h6>}
                                      </div>
                                      {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 3</p>
                                                                    {personalDetails?.address_line_3 === "" || personalDetails?.address_line_3 === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_line_3.split("null")}</h6>}
                                                                </div> */}
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">State</p>
                                        {personalDetails?.address_state === "" || personalDetails?.address_state === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_state}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">City</p>
                                        {personalDetails?.address_city === "" || personalDetails?.address_city === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_city}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Pincode</p>
                                        {personalDetails?.address_zip === "" || personalDetails?.address_zip === null ? <h6> N/A </h6> : <h6>{personalDetails?.address_zip}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                        <p className="detail-title">Last Update</p>
                                        {personalDetails?.personal_details_updated_at === "" || personalDetails?.personal_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(personalDetails?.personal_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                      </div>
                                      <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3"> </div>
                                    </div>
                                  </div>
                                </div>
                              }
                              <br />
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header"> Nominee Details</h5>
                                </div>

                                {nominee_details != undefined && nominee_details.length > 0 && personalDetails?.is_nominee_added !== 0
                                  ? nominee_details.map((nominee: APIData.NomineeDetails, inx: number) => {
                                    return (
                                      <div className="card-body">
                                        <h5 className="m-0 header py-4"> Nominee {inx + 1}</h5>
                                        <div className="row">
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Percentage</p>
                                            <h6>{getDisplayData(nominee?.nominee_percentage)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Title</p>
                                            <h6>{getDisplayData(nominee?.name_title)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Full Name</p>
                                            <h6>{getDisplayData(nominee?.nominee_fullname)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Relationship with Application</p>
                                            <h6>{getDisplayData(nominee?.relationship_with_applicant)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Date Of Birth</p>
                                            <h6>{getDisplayData(nominee?.nominee_dob)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Phone Number - Primary</p>
                                            <h6>{getDisplayData(nominee?.nominee_mobile_number)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">
                                              {nominee?.nominee_identification_id == 1 && <span className="my-account-input-title">PAN Number</span>}
                                              {nominee?.nominee_identification_id == 2 && <span className="my-account-input-title">Aadhar Card Number</span>}
                                              {nominee?.nominee_identification_id == 3 && <span className="my-account-input-title">Voter ID Number</span>}
                                              {nominee?.nominee_identification_id == 4 && <span className="my-account-input-title">Driving License Number</span>}
                                              {nominee?.nominee_identification_id == 5 && <span className="my-account-input-title">Passport</span>}
                                            </p>
                                            <h6>{getDisplayData(nominee?.nominee_identification_number)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Address Line 1</p>
                                            <h6>{getDisplayData(nominee?.nominee_address_line_1)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Address Line 2</p>
                                            <h6>{getDisplayData(nominee?.nominee_address_line_2)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">State</p>
                                            <h6>{getDisplayData(nominee?.nominee_address_state)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">City</p>
                                            <h6>{getDisplayData(nominee?.nominee_address_city)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Pincode</p>
                                            <h6>{getDisplayData(nominee?.nominee_address_pincode)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Created at</p>
                                            <h6>{getFormattedLocalDateTime(nominee?.nominee_created_at)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Last Update</p>
                                            <h6>{getFormattedLocalDateTime(nominee?.nominee_updated_at)}</h6>
                                          </div>
                                        </div>
                                        {nominee?.nominee_guardian_records != undefined && nominee?.nominee_guardian_records.length > 0
                                          ? nominee?.nominee_guardian_records.map((guard: APIData.guardianRecords, index: number) => {
                                            return (
                                              <>
                                                <h5 className="m-0 header py-4"> Guardian {index + 1}</h5>
                                                <div className="row">
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Title</p>
                                                    <h6>{getDisplayData(guard?.name_title)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Full Name</p>
                                                    <h6>{getDisplayData(guard?.nominee_guardian_fullname)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Relationship with Application</p>
                                                    <h6>{getDisplayData(guard?.relationship_with_applicant)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Phone Number - Primary</p>
                                                    <h6>{getDisplayData(guard?.nominee_guardian_mobile_number)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">
                                                      {guard?.nominee_identification_id == 1 && <span className="my-account-input-title">PAN Number</span>}
                                                      {guard?.nominee_identification_id == 2 && <span className="my-account-input-title">Aadhar Card Number</span>}
                                                      {guard?.nominee_identification_id == 3 && <span className="my-account-input-title">Voter ID Number</span>}
                                                      {guard?.nominee_identification_id == 4 && <span className="my-account-input-title">Driving License Number</span>}
                                                      {guard?.nominee_identification_id == 5 && <span className="my-account-input-title">Passport</span>}
                                                    </p>
                                                    <h6>{getDisplayData(guard?.nominee_guardian_identification_number)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Address Line 1</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_line_1)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Address Line 2</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_line_2)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">State</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_state)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">City</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_city)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Pincode</p>
                                                    <h6>{getDisplayData(nominee?.nominee_address_pincode)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Created at</p>
                                                    <h6>{getFormattedLocalDateTime(guard?.nominee_identification_created_at)}</h6>
                                                  </div>
                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                    <p className="detail-title">Last Update</p>
                                                    <h6>{getFormattedLocalDateTime(guard?.nominee_guardian_updated_at)}</h6>
                                                  </div>
                                                </div>
                                              </>
                                            );
                                          })
                                          : ""}
                                      </div>
                                    );
                                  })
                                  : <div className="card-body">
                                    <div className="row"><p className="detail-title">Not Added Nominee Details </p></div></div>}
                              </div>

                              <br />
                              {FACTADetails != undefined && FACTADetails.length > 0 && (
                                <>
                                  <div className="card">
                                    <div className="card-header">
                                      <h5 className="m-0 header"> FACTA Details</h5>
                                    </div>
                                    {FACTADetails.map((items: APIData.FactaDetails, i: any) => {
                                      return (
                                        <div className="card-body" key={i}>
                                          <div className="row">
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Where were you born?</p>
                                              {items.born_place === "" || items.born_place === null ? <h6> N/A </h6> : <h6>{items.born_place}</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Are you a citizen of the U.S.A?</p>
                                              {items.dp_resident_usa === Yes_or_No.YES ? <h6>Yes</h6> : <h6>No</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Is your country of tax Residency, India?</p>
                                              {items.dp_resident_india === Yes_or_No.YES ? <h6>Yes</h6> : <h6>No</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Your primary source of wealth?</p>
                                              {items.primary_source === "" || items.primary_source === null ? <h6>N/A</h6> : <h6>{items.primary_source}</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Last Update</p>
                                              {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}

                              <br />
                              {bankDetails.length > 0 && (
                                <>
                                  <div className="card">
                                    <div className="card-header">
                                      <h5 className="m-0 header"> BANK Details</h5>
                                    </div>
                                    {bankDetails.map((banks_details: APIData.BankDetailsDatas, i: any) => {
                                      return (
                                        <div className="card-body" key={i}>
                                          <div className="row">
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Status</p>
                                              {banks_details.is_primary === Yes_or_No.YES ? <h6 className="text-green">Active</h6> : <h6 className="text-red">In Active</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Name (as per Bank Records)</p>
                                              {!banks_details.account_name && <h6>N/A</h6>}
                                              {banks_details.account_name === "" || banks_details.account_name === null ? <h6>N/A</h6> : <h6>{banks_details.account_name}</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Bank Name</p>
                                              {banks_details.bank_name === "" || banks_details.bank_name === null ? <h6>N/A</h6> : <h6>{banks_details.bank_name}</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Branch Name</p>
                                              {banks_details.branch_name === "" || banks_details.branch_name === null ? <h6>N/A</h6> : <h6>{banks_details.branch_name}</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Account Number</p>
                                              {banks_details.account_number === "" || banks_details.account_number === null ? <h6>N/A</h6> : <h6>{banks_details.account_number}</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">IFSC Code</p>
                                              {banks_details.ifsc_code === "" || banks_details.ifsc_code === null ? <h6>N/A</h6> : <h6>{banks_details.ifsc_code}</h6>}
                                            </div>

                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Branch Address</p>
                                              {banks_details.branch_address === "" || banks_details.branch_address === null ? <h6>N/A</h6> : <h6>{banks_details.branch_address}</h6>}
                                            </div>

                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Account Type 1</p>
                                              {banks_details.account_type_1 === "" || banks_details.account_type_1 === null ? <h6>N/A</h6> : <h6>{banks_details.account_type_1 === 1 ? "Saving" : "Current"}</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Account Type 2</p>
                                              {banks_details.account_type_2 === "" || banks_details.account_type_2 === null ? <h6>N/A</h6> : <h6>{banks_details.account_type_2 === 1 ? "Single" : "Joint"}</h6>}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Last Update</p>
                                              {banks_details?.bank_details_updated_at === "" || banks_details?.bank_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(banks_details?.bank_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                            </div>
                                            <div className="col-sm-9 col-md-9 col-lg-9"></div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              )}

                              <br />
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header"> Existing Demat Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    {existing_demat_details != undefined && existing_demat_details.length > 0 ? (
                                      <>
                                        {existing_demat_details.map((res: APIData.ExistingDematAccountDetails, i: any) => {
                                          return (
                                            <>
                                              <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3" key={i}>
                                                <p className="detail-title">Status</p>
                                                {/* <h6>{!res.status && "N/A"}</h6>
                                                                                {res?.status === null ? <h6> N/A </h6> : <h6>{res?.status}</h6>} */}
                                                {res?.demat_account_status === Yes_or_No.YES ? <h6 className="text-green">Active</h6> : <h6 className="text-red">In Active</h6>}
                                              </div>
                                              <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">Depository</p>
                                                {/* {res?.depository === "" || res?.depository === null ? <h6> N/A </h6> : <h6>{Number(res?.depository) === Depository.CSDL ? "CSDL" : "NSDL"}</h6>} */}
                                                <h6>{getDisplayData(res.depository)}</h6>
                                              </div>
                                              <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">DP Name</p>
                                                {res?.dp_name === "" || res?.dp_name === null ? <h6> N/A </h6> : <h6>{res?.firstname}{res?.lastname}</h6>}
                                              </div>
                                              <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">DP ID</p>
                                                {res?.dp_id === "" || res?.dp_id === null ? <h6> N/A </h6> : <h6>{res?.dp_id}</h6>}
                                              </div>
                                              <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">Client ID</p>
                                                <h6>{!res.client_id && "N/A"}</h6>
                                                {res?.client_id === null ? <h6> N/A </h6> : <h6>{res?.client_id}</h6>}
                                              </div>
                                              <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                <p className="detail-title">Last Update</p>
                                                {res?.exist_demat_account_details_updated_at === "" || res?.exist_demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(res?.exist_demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>}
                                              </div>
                                              <div className="col-sm-12 col-md-12 col-lg-12"></div>
                                            </>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      "No Data Found"
                                    )}
                                  </div>
                                </div>
                              </div>

                              <br />
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header"> New Demat Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    {new_demat_details != undefined && new_demat_details.length > 0
                                      ? new_demat_details.map((new_demand: APIData.NewDematAccountDetails, index: number) => {
                                        return (
                                          <>
                                            {/* {new_demat_details.length > 0 && <> */}
                                            {/* {new_demat_details.map((res: APIData.NewDematAccountDetails, i: any) => {
                                                                        return (
                                                                            <> */}
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Status</p>
                                              {/* <h6>{getDisplayData(new_demat_details?.demat_account_details_status)}</h6> */}
                                              {new_demand?.demat_account_status === Yes_or_No.YES ? <h6 className="text-green">Active</h6> : <h6 className="text-red">In Active</h6>}
                                              {/* {res?.born_place === null ? <h6> N/A </h6> : <h6>{res?.born_place}</h6>} */}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Depository</p>
                                              <h6>{getDisplayData(new_demand?.depository)}</h6>
                                              {/* {res?.dp_resident_usa === "" || res?.depository === null ? <h6> N/A </h6> : <h6>{Number(res?.dp_resident_usa) === Yes_or_No.YES? "Yes":"No" }</h6>} */}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">DP Name</p>
                                              <h6>{getDisplayData(new_demand?.fname) + " " + getDisplayData(new_demand?.lname)}</h6>
                                              {/* {res?.dp_resident_india === "" || res?.depository === null ? <h6> N/A </h6> : <h6>{Number(res?.dp_resident_india) === Yes_or_No.YES? "Yes":"No" }</h6>} */}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">DP ID</p>
                                              <h6>{getDisplayData(new_demand?.dp_account_number)}</h6>
                                              {/* {res?.primary_source === "" || res?.primary_source === null ? <h6> N/A </h6> : <h6>{res?.primary_source}</h6>} */}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Client ID</p>
                                              <h6>{getDisplayData(new_demand?.dp_account_number)}</h6>
                                              {/* {res?.client_id === null ? <h6> N/A </h6> : <h6>{res?.client_id}</h6>} */}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Created at</p>
                                              <h6>{getFormattedLocalDateTime(new_demand?.demat_account_details_created_at)}</h6>
                                              {/* {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>} */}
                                            </div>
                                            <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                              <p className="detail-title">Last Update</p>
                                              <h6>{getFormattedLocalDateTime(new_demand?.demat_account_details_updated_at)}</h6>
                                              {/* {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>} */}
                                            </div>
                                            <div className="col-sm-9 col-md-9 col-lg-9"></div>
                                          </>
                                        );
                                      })
                                      : "No Data Found"}
                                  </div>
                                </div>
                              </div>

                              <br />
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Account Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Registration Date</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Empanelment Date</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Empanelment Frequency</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Empanelment With</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Net worth</p>
                                      <h6>N/A</h6>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">AUM</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Custodian Segment</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Custodian Settlement</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Settlement Mode</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Customer for IPO Only</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">IPO Category</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">IPO SUB Category</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">NSCCL & ICCL UCC</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Client Type</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">ITR Submitted</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Remarks</p>
                                      <h6>N/A</h6>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Last Update</p>
                                      <h6>N/A</h6>
                                      {/* {items?.demat_account_details_updated_at === "" || items?.demat_account_details_updated_at === null ? <h6> N/A </h6> : <h6>{moment(items?.demat_account_details_updated_at).format("DD-MM-YYYY,  hh:mm A")}</h6>} */}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <br />
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Document Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="table-rep-plugin">
                                    <div className="table-responsive " data-pattern="priority-columns">
                                      <table id="tech-companies-1" className="table table table-bordered">
                                        <thead className="city-table">
                                          <tr>
                                            <th className="text-center">Sr.No</th>
                                            <th className="text-center">Upload Date</th>
                                            <th className="text-center">Document Name</th>
                                            <th className="text-center">Uploads</th>
                                            <th className="text-center">Verified</th>
                                            <th className="text-center">Remarks</th>
                                            <th className="text-center">Download</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr>
                                            {typeof documentDetails?.esign_pdf != "undefined" && Object.keys(documentDetails?.esign_pdf).length != 0 && (
                                              <>
                                                <td className="text-center">1 </td>
                                                <td className="text-center">{getFormattedLocalDate(documentDetails?.esign_pdf.last_update)}</td>
                                                <td className="text-center">{getDisplayData(documentDetails?.esign_pdf.document_name)}</td>
                                                <td className="text-center text-decoration-underline">{getDownloadURLExtension(documentDetails?.esign_pdf.user_application_form)}</td>
                                                <td className="text-center">{documentDetails?.esign_pdf.user_application_form_status === 1 ? <span className="text-green">Yes</span> : <span className="text-red">No</span>}</td>
                                                <td className="text-center">N/A</td>
                                                <td className="text-center">
                                                  <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => getDownloadFile(documentDetails?.esign_pdf.user_application_form)}></i>{" "}
                                                </td>
                                              </>
                                            )}
                                          </tr>

                                          <tr>
                                            {typeof documentDetails?.address_proof_details != "undefined" && Object.keys(documentDetails?.address_proof_details).length != 0 && (
                                              <>
                                                <td className="text-center">2</td>
                                                <td className="text-center">{getFormattedLocalDate(documentDetails?.address_proof_details.last_update)}</td>
                                                <td className="text-center">{getDisplayData(documentDetails?.address_proof_details.document_name)}</td>
                                                <td className="text-center text-decoration-underline">
                                                  {getDownloadURLExtension(documentDetails?.address_proof_details.address_proof_front_image)}
                                                  <br />
                                                  {getDownloadURLExtension(documentDetails?.address_proof_details.address_proof_back_image)}
                                                </td>
                                                <td className="text-center">{documentDetails?.address_proof_details.address_proof_verified === 1 ? <span className="text-green">Yes</span> : <span className="text-red">No</span>}</td>
                                                <td className="text-center">N/A</td>
                                                <td className="text-center">
                                                  <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => getDownloadFile(documentDetails?.address_proof_details.address_proof_front_image)}></i>
                                                  <br />
                                                  <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => getDownloadFile(documentDetails?.address_proof_details.address_proof_back_image)}></i>
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                          <tr>
                                            {documentDetails?.bank_details !== undefined &&
                                              documentDetails?.bank_details.length > 0 &&
                                              documentDetails?.bank_details.map((bank: APIData.bank, index: number) => {
                                                return (
                                                  <>
                                                    <td className="text-center">{index + 3}</td>
                                                    <td className="text-center">{getFormattedLocalDate(bank.last_update)}</td>
                                                    <td className="text-center">{getDisplayData(bank.document_name)}</td>
                                                    <td className="text-center text-decoration-underline">{getDownloadURLExtension(bank.bank_cancel_cheque_image)}</td>
                                                    <td className="text-center">{bank.bank_is_verified === 1 ? <span className="text-green">Yes</span> : <span className="text-red">No</span>}</td>
                                                    <td className="text-center">N/A</td>
                                                    <td className="text-center">
                                                      <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(bank.bank_cancel_cheque_image)}></i>{" "}
                                                    </td>
                                                  </>
                                                );
                                              })}
                                          </tr>

                                          {/* {documentProofDetails && (
                                        <tr>
                                          {(documentProofDetails.proof_front_image !== "" || documentProofDetails.proof_front_image !== null) && (
                                            <>
                                              <td className="text-center">02 </td>
                                              <td className="text-center">
                                                {documentProofDetails.personal_details_updated_at !== "" || documentProofDetails.personal_details_updated_at !== null ? (
                                                  <> {moment(documentProofDetails.personal_details_updated_at).format("DD-MM-YYYY")}</>
                                                ) : (
                                                  <> N/A </>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null ? <> {documentProofDetails.proof_type} Front</> : <> N/A </>}
                                              </td>
                                              <td className="text-center text-decoration-underline">
                                                {documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null ? <> {documentProofDetails.proof_type} Front.Pdf</> : <> N/A </>}
                                              </td>
                                              <td className="text-center">
                                                <span className="text-green">Yes</span>
                                              </td>
                                              <td className="text-center">N/A</td>
                                              <td className="text-center">
                                                <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(documentProofDetails.proof_front_image)}></i>{" "}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )}

                                      {documentProofDetails && (
                                        <tr>
                                          {(documentProofDetails.proof_back_image !== "" || documentProofDetails.proof_back_image !== null) && (
                                            <>
                                              <td className="text-center">03 </td>
                                              <td className="text-center">
                                                {documentProofDetails.personal_details_updated_at !== "" || documentProofDetails.personal_details_updated_at !== null ? (
                                                  <> {moment(documentProofDetails.personal_details_updated_at).format("DD-MM-YYYY")}</>
                                                ) : (
                                                  <> N/A </>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null ? <> {documentProofDetails.proof_type} Back</> : <> N/A </>}
                                              </td>
                                              <td className="text-center text-decoration-underline">
                                                {documentProofDetails.proof_type !== "" || documentProofDetails.proof_type !== null ? <> {documentProofDetails.proof_type} Back.Pdf</> : <> N/A </>}
                                              </td>
                                              <td className="text-center">
                                                <span className="text-green">Yes</span>
                                              </td>
                                              <td className="text-center">N/A</td>
                                              <td className="text-center">
                                                <i className="fa fa-download cursor-pointer" aria-hidden="true" onClick={() => window.location.replace(documentProofDetails.proof_back_image)}></i>{" "}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )}

                                      {documentDetails.demat_signature && (
                                        <tr>
                                          {(documentDetails["demat_signature"].demat_signature !== "" || documentDetails["demat_signature"].demat_signature !== null) && (
                                            <>
                                              <td className="text-center">04 </td>
                                              <td className="text-center">
                                                {documentDetails["demat_signature"].demat_account_details_updated_at !== "" ||
                                                documentDetails["demat_signature"].demat_account_details_updated_at !== null ? (
                                                  <> {moment(documentDetails["demat_signature"].demat_account_details_updated_at).format("DD-MM-YYYY")}</>
                                                ) : (
                                                  <> N/A </>
                                                )}
                                              </td>
                                              <td className="text-center">Demat Signature</td>
                                              <td className="text-center text-decoration-underline">Demat Signature.pdf </td>
                                              <td className="text-center">
                                                <span className="text-green">Yes</span>
                                              </td>
                                              <td className="text-center">N/A</td>
                                              <td className="text-center">
                                                <i
                                                  className="fa fa-download cursor-pointer"
                                                  aria-hidden="true"
                                                  onClick={() => window.location.replace(documentDetails["demat_signature"].demat_signature)}
                                                ></i>{" "}
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      )} */}
                                          {/* <tr>
                                                                                <td className="text-center">03 </td>
                                                                                <td className="text-center">30-08-2022</td>
                                                                                <td className="text-center">Aadhaar Card</td>
                                                                                <td className="text-center">AadhaarCard.jpg</td>
                                                                                <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                <td className="text-center"></td>
                                                                                <td className="text-center"><i className="fa fa-download" aria-hidden="true"></i>   </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">04 </td>
                                                                                <td className="text-center">30-08-2022</td>
                                                                                <td className="text-center">Cancelled Cheque</td>
                                                                                <td className="text-center">CancelledCheque.jpg</td>
                                                                                <td className="text-center"><span className='text-green'>Yes</span></td>
                                                                                <td className="text-center"></td>
                                                                                <td className="text-center"><i className="fa fa-download" aria-hidden="true"></i>   </td>
                                                                            </tr> */}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <br />
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header"> Dealer Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Dealer Type</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Dealer Name</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">From Date</p>
                                      <h6>N/A</h6>
                                    </div>
                                    <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">From Date</p>
                                      <h6>N/A</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                      <div className="row">
                                        <div className="col-xl-7">
                                          <div className="d-flex justify-content-start align-items-center">
                                            <h3 className="card-title text-end p-2 m-0">My Orders</h3>
                                            <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                            <form className="app-search card-title p-2 m-0">
                                              <div className="position-relative">
                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Cust. ID,Updated Date & Time..." />
                                              </div>
                                            </form>
                                          </div>
                                        </div>

                                        <div className="col-xl-5 text-end">
                                          <div className="d-flex justify-content-end align-items-center">
                                            <form className="app-search card-title p-2 m-0 w-100">
                                              <div className="position-relative">

                                                <DatePicker
                                                  selected={fromDate}
                                                  closeOnScroll={true}
                                                  onChange={(date: Date) => setFromDate(date)}
                                                  placeholderText="DD/MM/YYYY"
                                                  className='date-picker-style'
                                                  peekNextMonth
                                                  showMonthDropdown
                                                  showYearDropdown
                                                  dropdownMode="select"
                                                  customInput={(<CustomInput />)}
                                                />
                                              </div>
                                            </form>
                                            <h4 className="card-title p-2 m-0">TO</h4>
                                            <form className="app-search card-title p-2 m-0 w-100">
                                              <div className="position-relative">
                                                <DatePicker
                                                  selected={toData}
                                                  closeOnScroll={true}
                                                  onChange={(date: Date) => setToData(date)}
                                                  placeholderText="DD/MM/YYYY"
                                                  className='date-picker-style'
                                                  peekNextMonth
                                                  showMonthDropdown
                                                  showYearDropdown
                                                  dropdownMode="select"
                                                  customInput={(<CustomInput />)}
                                                />
                                              </div>
                                            </form>
                                            <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="table-rep-plugin">
                                        <div className="table-responsive " data-pattern="priority-columns">
                                          {/* <table id="tech-companies-1" className="table table table-bordered">
                                            <thead className="city-table">
                                              <tr>
                                                <th>Sr.No</th>
                                                <th>Order ID</th>
                                                <th>Order Date & Timee</th>
                                                <th>Bond / IPO Name</th>
                                                <th>No. Of Quantity</th>
                                                <th>Total Amount</th>
                                                <th>Order Status</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            {myOrders != undefined && myOrders.length > 0 ? (
                                              <>
                                                {myOrders.map((res: APIData.UserDetailsMyOrders, i: any) => (
                                                  <tbody key={i}>
                                                    <tr>
                                                      <td> {startSerialNo + i + 1} </td>
                                                      <td>{getDisplayData(res.order_id)}</td>
                                                      <td>{res.order_datetime != null && moment(res.order_datetime).format("DD-MM-YYYY,  hh:mm A")}</td>
                                                      <td>{getDisplayData(res.bond_name)}</td>
                                                      <td>{getDisplayData(res.order_quantity)}</td>
                                                      <td>₹ {numberWithCommas(res.order_amount)}</td>
                                                      <td>{getOrderStatus(res.order_status)}</td>
                                                      <td className="city-action-rable-row ">
                                                        <i
                                                          className="fas fa-eye table-icons pe-1"
                                                          onClick={() =>
                                                            navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                              state: { type: res.order_type, id: res.order_id },
                                                            })
                                                          }
                                                          onClick={() => {
                                                            if (res.order_type == BondType.IPO) {
                                                              navigate(PageLinks.IPO_ORDER_DETAIL_VIEW, {
                                                                state: { type: res.order_type, id: res.order_id, path: "user_order" },
                                                              });
                                                            } else if (res.order_type == BondType.ADminBond) {
                                                              navigate(PageLinks.BOND_ORDER_DETAIL_VIEW, {
                                                                state: { type: res.order_type, id: res.order_id, path: "user_order" },
                                                              });
                                                            } else if (res.order_type == BondType.SGB) {
                                                              navigate(PageLinks.SGB_ORDER_DETAIL_VIEW, {
                                                                state: { type: res.order_type, id: res.order_id, path: "user_order" },
                                                              });
                                                            }
                                                          }}
                                                        ></i>
                                                        {res.order_type === BondType.IPO ? <img src="../../assets/images/download.svg" style={{ width: "15px", margin: "0px 5px", cursor: "pointer" }} onClick={() => getDownloadFile(res.order_pdf)}></img> : ""}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                ))}
                                              </>
                                            ) : (
                                              <tbody>
                                                <tr className="py-4">
                                                  <td className="text-center py-5" colSpan={11}>
                                                    No Data Found.
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )}
                                          </table> */}
                                          <DataTable
                                            data={myOrders}
                                            columns={columns}
                                            noHeader
                                            defaultSortFieldId={"broker_id"}
                                            sortIcon={<i className="fas fa-sort"></i>}
                                            defaultSortAsc={true}
                                            highlightOnHover
                                            dense
                                          />
                                        </div>
                                      </div>

                                      <div className="row mt-3">
                                        <div className="col-sm-12 col-md-5">
                                          {totalCount > 10 ?
                                            <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {myOrders.length} of {totalCount} entries</div> :
                                            <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                          <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                            <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                              setPageNumber(Number(value))
                                              setCurrentPage(Number(value));
                                            }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className="row">
                                <div className="col-12">
                                  <div className="card">
                                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                      <div className="row">
                                        <div className="col-xl-5">
                                          <div className="d-flex justify-content-start align-items-center">
                                            <h3 className="card-title text-end p-2 m-0">User Logs</h3>
                                            <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                            <form className="app-search card-title p-2 m-0">
                                              <div className="position-relative">
                                                <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Cust. ID,Updated Date & Time..." />
                                              </div>
                                            </form>
                                          </div>
                                        </div>

                                        <div className="col-xl-7 text-end">
                                          <div className="d-flex justify-content-end align-items-center">
                                            <form className="app-search card-title  p-2 m-0 w-100">
                                              <div className="position-relative ">
                                                <select className="select-style" name="user_status" onChange={(e) => setSelectModule(e.target.value)}>
                                                  {module_list.map((res: any, i: any) => (
                                                    <option key={i} id={res.user_logs_module_id} value={res.user_logs_module_name}>
                                                      {res.user_logs_module_name}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            </form>
                                            <button className="xl_sheet" onClick={handelClickXsl}>Download <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 84.227 95.107" className="xsl_icon">
                                              <defs>
                                                <clipPath id="clip-path">
                                                  <rect id="Rectangle_3009" data-name="Rectangle 3009" width="84.227" height="95.107" />
                                                </clipPath>
                                              </defs>
                                              <g id="Group_10650" data-name="Group 10650" transform="translate(-0.667 0.247)">
                                                <g id="Group_10649" data-name="Group 10649" transform="translate(0.667 -0.247)" clip-path="url(#clip-path)">
                                                  <path id="Path_21458" data-name="Path 21458" d="M78.9,34.019H76.614V23a1.791,1.791,0,0,0-.019-.208,1.83,1.83,0,0,0-.443-1.207L57.814.638C57.808.632,57.8.631,57.8.625a1.851,1.851,0,0,0-.371-.311c-.04-.027-.08-.049-.122-.073a1.956,1.956,0,0,0-.368-.155c-.035-.009-.066-.023-.1-.032A1.87,1.87,0,0,0,56.409,0H11.344a3.733,3.733,0,0,0-3.73,3.73V34.018H5.331A5.331,5.331,0,0,0,0,39.349V67.071A5.332,5.332,0,0,0,5.331,72.4H7.614V91.377a3.734,3.734,0,0,0,3.73,3.73h61.54a3.735,3.735,0,0,0,3.73-3.73V72.4H78.9a5.332,5.332,0,0,0,5.33-5.33V39.35a5.329,5.329,0,0,0-5.33-5.331M11.344,3.73h43.2V22.812a1.865,1.865,0,0,0,1.865,1.865H72.883v9.343H11.344ZM53.192,61.185v5.409H35.4V38.109h6.468V61.185ZM7.381,66.593l8.242-14.411L7.676,38.109h7.4l2.493,5.2c.846,1.732,1.48,3.128,2.157,4.734h.083c.678-1.818,1.226-3.086,1.944-4.734l2.409-5.2h7.354l-8.031,13.9,8.454,14.58H24.5L21.92,61.438c-1.056-1.987-1.733-3.465-2.535-5.114H19.3c-.592,1.649-1.309,3.129-2.2,5.114l-2.367,5.156H7.381Zm65.5,23.775H11.344V72.4h61.54V90.367Zm-8.916-23.35a18.641,18.641,0,0,1-8.07-1.734l1.308-5.325a16.452,16.452,0,0,0,7.144,1.776c2.959,0,4.522-1.226,4.522-3.085,0-1.776-1.352-2.79-4.776-4.016-4.733-1.649-7.819-4.269-7.819-8.412,0-4.861,4.057-8.579,10.779-8.579a17.48,17.48,0,0,1,7.268,1.437l-1.437,5.2a13.531,13.531,0,0,0-5.959-1.353c-2.789,0-4.141,1.267-4.141,2.748,0,1.817,1.6,2.621,5.282,4.016,5.029,1.859,7.4,4.478,7.4,8.494,0,4.777-3.678,8.834-11.5,8.834" />
                                                </g>
                                              </g>
                                            </svg>
                                            </button>
                                            <form className="app-search card-title p-2 m-0 w-100">
                                              <div className="position-relative">

                                                <DatePicker
                                                  selected={fromDate}
                                                  closeOnScroll={true}
                                                  onChange={(date: Date) => setFromDate(date)}
                                                  placeholderText="DD/MM/YYYY"
                                                  className='date-picker-style'
                                                  peekNextMonth
                                                  showMonthDropdown
                                                  showYearDropdown
                                                  dropdownMode="select"
                                                  customInput={(<CustomInput />)}
                                                />
                                              </div>
                                            </form>
                                            <h4 className="card-title p-2 m-0">TO</h4>
                                            <form className="app-search card-title p-2 m-0 w-100">
                                              <div className="position-relative">
                                                <DatePicker
                                                  selected={toData}
                                                  closeOnScroll={true}
                                                  onChange={(date: Date) => setToData(date)}
                                                  placeholderText="DD/MM/YYYY"
                                                  className='date-picker-style'
                                                  peekNextMonth
                                                  showMonthDropdown
                                                  showYearDropdown
                                                  dropdownMode="select"
                                                  customInput={(<CustomInput />)}
                                                />
                                              </div>
                                            </form>
                                            <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                                          </div>
                                        </div>
                                      </div>
                                      {/* </div> */}
                                      {/* <div className="card-body"> */}
                                      <div className="table-rep-plugin">
                                        <div className="table-responsive " data-pattern="priority-columns">
                                          {/* <table id="tech-companies-1" className="table table table-bordered">
                                            <thead className="city-table">
                                              <tr>
                                                <th>Sr.No</th>
                                                <th>Cust. ID</th>
                                                <th>Updated Date & Time</th>
                                                <th>IP Address</th>
                                                <th className="w-10">Platform</th>
                                                <th>Module</th>
                                                <th>Method</th>
                                                <th>Status</th>
                                                <th>Last Login Date&Time</th>
                                              </tr>
                                            </thead>
                                            {userLogList != undefined && userLogList.length > 0 ? (
                                              <>
                                                {userLogList.map((res: APIData.AlluserLogs, i: any) => (
                                                  <tbody key={i}>
                                                    <tr>
                                                      <td> {startSerialNo + i + 1} </td>
                                                      <td>{res.user_customer_id !== "" || res.user_customer_id !== null ? <>{res.user_customer_id}</> : "N/A"}</td>
                                                      <td>{res.user_logs_created_at != null && moment(res.user_logs_created_at).format("DD-MM-YYYY,  hh:mm A")}</td>
                                                      <td>{res.ip_address !== "" || res.ip_address !== null ? <>{res.ip_address}</> : "N/A"}</td>
                                                      <td>{res.platform !== "" || res.platform !== null ? <>{res.platform}</> : "N/A"}</td>
                                                      <td>{res.module !== "" || res.module !== null ? <>{res.module}</> : "N/A"}</td>

                                                      {res.operation_type === "" || (res.operation_type === null && <td>N/A</td>)}
                                                      {res.operation_type === "1" && <td>POST</td>}
                                                      {res.operation_type === "2" && <td>UPDATE</td>}
                                                      {res.operation_type === "3" && <td>DELETE</td>}
                                                      {res.operation_type === "4" && <td>GET</td>}

                                                      {res.operation_status === "" || (res.operation_status === null && <td>N/A</td>)}
                                                      {res.operation_status === "1" && <td>Success</td>}
                                                      {res.operation_status === "2" && <td>Failed</td>}
                                                      {res.user_last_login && <td>{moment(res.user_last_login).format("DD-MM-YYYY,  hh:mm A")}</td>}
                                                      {res.operation_status === "0" && <td>Success</td>}
                                                    </tr>
                                                  </tbody>
                                                ))}
                                              </>
                                            ) : (
                                              <tbody>
                                                <tr className="py-4">
                                                  <td className="text-center py-5" colSpan={11}>
                                                    No Data Found.
                                                  </td>
                                                </tr>
                                              </tbody>
                                            )}
                                          </table> */}
                                          <DataTable
                                            data={userLogList}
                                            columns={columns1}
                                            noHeader
                                            defaultSortFieldId={"broker_id"}
                                            sortIcon={<i className="fas fa-sort"></i>}
                                            defaultSortAsc={true}
                                            highlightOnHover
                                            dense
                                          />
                                        </div>
                                      </div>

                                      <div className="row mt-3">
                                        <div className="col-sm-12 col-md-5">
                                          {totalCount > 10 ?
                                            <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {userLogList.length} of {totalCount} entries</div> :
                                            <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing  {totalCount == 0 ? 0 : totalCount} entries</div>}

                                        </div>
                                        <div className="col-sm-12 col-md-7">
                                          <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                            <Pagination count={Math.ceil(totalCount / 10)} onChange={(event: any, value: any) => {
                                              setPageNumber(Number(value))
                                              setCurrentPage(Number(value));
                                            }} variant="outlined" color="primary" shape="rounded" className='pagination-style' />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <AccountStatusModal userId={userId} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => {
        // handleClose(e)
        setOpen(e);
      }}
        reload={(e: any) => {
          handleStatusReload(e);
        }
        } />
      {/* <SimpleModal contents={res.suspended_remarks} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
      <PreviewJSONModal contents={cvlkraJSON} closeModel={() => setOpenJSON(false)} showModel={openJSON} />
    </div>
  );
};

export default UsersDetails;
