import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Depository, Experience, Gender, GenderString, kraStatus, MarriedStatus, PageLinks, PersonVideVerifyStatus, Status, Yes_or_No, AccountStatus } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplayData, getDisplaySelectOption, getDownloadFile, getDownloadURLExtension, getFormattedLocalDateTime, numberWithCommas } from "../common/Utilities";
import AccountStatusModal from "../modals/AccountStatusModal";

const BrokerDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location.state;
    const [brokerId, setBrokerId] = useState(0);

    const [BrokerList, setBrokerList] = useState<APIData.BrokerList>(InitialData.BrokerList);

    const { setShowPreloader } = useAppStateAPI();

    useEffect(() => {
        loadBondDetail(location.state.id);
        // console.log(moment(documentProofDetails?.personal_details_updated_at).format("DD-MM-YYYY"));
    }, [id]);

    const loadBondDetail = (id: number) => {
        if (id) {
            setShowPreloader(true);
            ServerAPI.BrokerListID(id).then((res) => {
                console.log(res);
                setBrokerList(res.data);
                setBrokerId(res.data.broker_id);

            }).finally(() => {
                setShowPreloader(false)
            })
        }
    }


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                                        <div className="page-title-rightds">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item" onClick={() => navigate(PageLinks.BROKER_LIST)}>Broker Lists</li>
                                                <li className="breadcrumb-item active"><b>Detail View</b></li>
                                            </ol>
                                        </div>
                                        <div className='cursor-pointer' onClick={() => navigate(-1)}>
                                            <p className='mb-0 active'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                                                <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                                            </svg>
                                                <span> Back </span></p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header col-xl-12 w-100">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className="my-3 header"> Broker Details </h5>
                                                        <div className="">
                                                            <a className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2" onClick={() => navigate(PageLinks.BROKER_ADD, { state: { id: brokerId, mode: 'edit' } })} ><img src="../../assets/images/edit.svg" style={{ marginBottom: "4px", width: "16px" }} /> Edit</a>
                                                        </div>
                                                    </div>

                                                    <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                        {/* <div className="card-header">
                                                            <h5 className="m-0 header"> Personal Details </h5>
                                                        </div> */}
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Broker Name</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">NSE Broker Code</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_nse_code)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">BSE Broker Code</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_bse_code)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">MCX Broker Code</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_mcx_code)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 1</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_address_line_1)}</h6>
                                                                </div>



                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 2</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_address_line_2)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">City</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_address_city)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Exchange Type</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_exchange)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">GST No</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_gst_no)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">GST State</p>
                                                                    <h6>{'N/A'}</h6>
                                                                </div>



                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Pincode</p>
                                                                    <h6>{'N/A'}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Phone No</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_phone_no)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Mobile No</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_mobile_no)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Fax No</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_fax_no)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Email ID</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_email)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">PAN No</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_pan_no)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ST Reg No</p>
                                                                    <h6>{getDisplayData(BrokerList.broker_st_reg_no)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ST Regn Category</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_st_reg_category)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ST Service Category</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_st_service_category)}</h6>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Advisory Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                        <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Advisory Name</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Advisory PAN No</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_pan_no)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 1</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_address_line_1)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Address Line 2</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_address_line_2)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Contact Person</p>
                                                                    <h6>{'N/A'}</h6>
                                                                </div>



                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">City</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_address_city)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Pincode</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_address_pincode)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Advisory State</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_address_state)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Advisory GST</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_gst_no)}</h6>
                                                                </div>
                                                               



                                                                <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                                                    <p className="detail-title">Remark</p>
                                                                    <h6>{getDisplayData(BrokerList?.broker_advisory?.advisory_remarks)}</h6>
                                                                </div>
                                                              


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            {/* <AccountStatusModal userId={userId} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
            {/* <SimpleModal contents={res.suspended_remarks} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}

        </div >
    );
}

export default BrokerDetails;