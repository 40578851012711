import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { Form, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import ConfirmationModal from "./Confirmation-modal";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DatePicker from "react-datepicker";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../common/InitialValue";
import { Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import * as Yup from "yup";
import { useAppStateAPI } from "../common/AppStateAPI";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from "moment";

interface modalDatas {
  openModal?: any;
  handleClose?: any;
  handleOpen?: any;
  customerData?: any;
  // currentPage?:any
  // handleOpen
  listReload?:any;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 950,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  boxShadow: 24,
  p: 2,
  borderRadius: 3,
};

const BondEnquiryDetailPopup: React.FC<modalDatas> = ({ handleOpen, customerData, openModal, handleClose,listReload}) => {
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [all_datas, setAllDatas] = useState({
    // userId:userId,
    user_status: 2,
    account_status_reason: "Lorem ipsum dolor sit amet consectetur adipisicing elit 1.",
    account_status_remarks: "",
  });
  // const [select_status, setSelectStatus] = useState<any>(1)
  const navigate = useNavigate();
  const [customerDetails, setCustomerDetails] = useState<APIData.CustomerEnquire>(InitialData.CustomerEnquire);
  const [formikInitialData, setFormikInitialData] = useState<APIData.CustomerEnquire>(InitialData.CustomerEnquire);
  const formikRef = useRef<FormikProps<APIData.CustomerEnquire>>(null);
  const { setShowPreloader } = useAppStateAPI();
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [orderType, setOrderType] = useState<number>(0);

  useEffect(() => {
    if (openModal === true) {
      setOpen(true);
      setCustomerDetails(customerData);
    } else {
      setOpen(false);
    }
  }, [openModal]);

  const handleCloses = () => {
    setOpen(false);
    // setOpenModal(false)
    handleClose(false);
  };
  const handleClosed = (e: any) => {
    setOpenModal(e);
    setOpen(e);
    handleClose(e);
  };

  const update_status = (e: any) => {
    setAllDatas({
      ...all_datas,
      [e.target.name]: e.target.value,
    });
  };

  const save_modal = () => {
    setOpen(false);
    setOpenModal(true);
    handleClose(false);
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerInputFeildStyle" style={{ height: "44px", padding: "8px 6px" }}>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img src="../../assets/images/calender-icon.svg" onClick={props.onClick} ref={ref} />
      </div>
    );
  });

  // const submitData = (data: any, setSubmitting: any) => {
  //   console.log(data);
  //   setShowPreloader(true);
  //   ServerAPI.putBond(data)
  //     .then((res) => {
  //       toast.success(res.message);
  //       navigate(PageLinks.BOND_DETAIL, { state: res.bond_isin_number });
  //       setSubmitting(false);
  //     })
  //     .finally(() => {
  //       setShowPreloader(false);
  //     });
  // };


  const schema = Yup.object({
    price: Yup.number().typeError("you must specify a number"),
    yield: Yup.number().typeError("you must specify a number"),
    quantity: Yup.number().typeError("you must specify a number"),
    buy_sell_id: Yup.string().notOneOf(['0'], 'Please select a valid option').required('Required'),
  });

  const { register, handleSubmit,reset, formState:{ errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data:any)=> {
    console.log(data);
    console.log(data.yeild);
    setShowPreloader(true);
      ServerAPI.BondEnquiryUpdate(customerData.customer_bond_enquire_id,customerData.settlement_date_id,moment(startDate).format("YYYY-MM-DD"),moment(endDate).format("YYYY-MM-DD"),data.price,data.yield,data.quantity,data.buy_sell_id).then((res) => {
          toast.success(res.message);
          handleCloses();
          reset();
          listReload();
        })
        .finally(() => {
          setShowPreloader(false);
        });
  };

  return (
    <div className="dialog">
      <Modal open={open} className="dialog-box" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className="bond_enquiry_details">
            {/* <Formik
              validationSchema={yupSchema}
              enableReinitialize={true}
              validateOnChange={false}
              initialValues={formikInitialData}
              innerRef={formikRef}
              onSubmit={(values, { setSubmitting }) => {
                submitData(values, setSubmitting);
              }}
            >
              {(formikProps) => {
                return (
                  <Form onSubmit={formikProps.handleSubmit}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}> */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="modal-box-title">
                        <p className="m-0 quicksand-medium">Bond Enquiry Details</p>
                        <p className="m-0" onClick={handleCloses}>
                          <i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i>
                        </p>
                      </div>
                      <hr />
                      <div className="col-lg-12 col-md-12 col-sm-12 modal-box-content">
                        <h4>Customer Details</h4>
                        <div className="d-flex form-group">
                          <div className="form-group-feild">
                            <h6>Customer ID</h6>
                            <h6 style={{ color: "#22263D" }}>{customerDetails?.customer_id}</h6>
                          </div>
                          <div className="form-group-feild">
                            <h6>Customer Name</h6>
                            <h6 style={{ color: "#22263D" }}>{customerDetails?.customer_name}</h6>
                          </div>
                          <div className="form-group-feild">
                            <h6>Mobile Number</h6>
                            <h6 style={{ color: "#22263D" }}>{customerDetails?.customer_mobile_number}</h6>
                          </div>
                          <div className="form-group-feild">
                            <h6>Email ID</h6>
                            <h6 style={{ color: "#22263D" }}>{customerDetails?.customer_email_id}</h6>
                          </div>
                        </div>

                        <h4>Bond Details</h4>
                        <h3>{customerDetails?.bond_name}</h3>
                        <div className="d-flex form-group">
                          <div className="new-price">
                            <h6>Add New Price</h6>
                            
                            <Grid direction="row" container spacing={2}>
                              <Grid item xs={2}>
                                <div className="d-grid">
                                  <label htmlFor="bond_issuer_name" className="bond-label">
                                    Price
                                  </label>
                                  {/* <input id="bond_issuer_name" name="bond_issuer_name" type="text" className="bond-input" /> */}
                                  <input {...register("price")} type="number" className="bond-input"/>
                                  <p style={{fontSize:"12px",margin:"6px 4px",color:"red"}}>{errors.price?.message}</p>
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className="d-grid">
                                  <label htmlFor="bond_issuer_name" className="bond-label">
                                    Yield
                                  </label>
                                  {/* <input id="bond_issuer_name" name="bond_issuer_name" type="text" className="bond-input" /> */}
                                  <input {...register("yield")} type="number" className="bond-input"/>
                                  <p style={{fontSize:"12px",margin:"6px 4px",color:"red"}}>{errors.yield?.message}</p>
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className="d-grid">
                                  <label htmlFor="bond_issuer_name" className="bond-label">
                                    Quantity
                                  </label>
                                  {/* <input id="bond_issuer_name" name="bond_issuer_name" type="text" className="bond-input" /> */}
                                  <input {...register("quantity")} type="number" className="bond-input"/>
                                  <p style={{fontSize:"12px",margin:"6px 4px",color:"red"}}>{errors.quantity?.message}</p>
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className="d-grid">
                                  <label htmlFor="Bond Name" className="bond-label">
                                    Start Date
                                  </label>
                                  <DatePicker
                                    selected={startDate}
                                    dateFormat="dd/MM/yyyy"
                                    closeOnScroll={true}
                                    onChange={(date: Date) => {console.log(date);setStartDate(date)}}
                                    placeholderText="DD/MM/YYYY"
                                    className="date-picker-style"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={<CustomInput />}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className="d-grid">
                                  <label htmlFor="Bond Name" className="bond-label">
                                    End Date
                                  </label>
                                  <DatePicker
                                    selected={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    closeOnScroll={true}
                                    onChange={(date: Date) =>{ console.log(date);setEndDate(date)}}
                                    placeholderText="DD/MM/YYYY"
                                    className="date-picker-style"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    customInput={<CustomInput />}
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={2}>
                                <div className="d-grid">
                                  <label htmlFor="Nature of Instrument" className="bond-label">
                                    Order Type
                                  </label>
                                  <select
                                        {...register("buy_sell_id")}
                                      onChange={(e) => {
                                        console.log(e.target.value)
                                        setOrderType(parseInt(e.target.value));
                                      }}
                                      value={orderType}
                                    className="bond-input-select"
                                  >
                                    <option value={0}>Select</option>
                                    <option value={1}>Buy</option>
                                    <option value={2}>Sell</option>
                                  </select>
                                  <p style={{fontSize:"12px",margin:"6px 4px",color:"red"}}>{errors.buy_sell_id?.message}</p>
                                </div>
                              </Grid>
                            </Grid>
                           
                          </div>
                        </div>
                        <div className="d-flex justify-content-between my-3">
                          <button className="yeild-cal">
                            <span className="fa fa-calculator" style={{ padding: "0px 10px" }}></span>Yield / Price Calculator
                          </button>
                          <div className="d-flex">
                            <a className="cancel-btn mx-3" onClick={() => handleCloses()}>
                              Cancel
                            </a>
                            <button type="submit" className="save-btn">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                      </form>
                    {/* </LocalizationProvider>
                  </Form>
                );
              }}
            </Formik> */}
          </Typography>
        </Box>
      </Modal>
      <ConfirmationModal contents="Are you sure you want to confirm this?" details_datas={all_datas} currentPage="update-status" openModal={open_modal} handleClose={(e: any) => handleClosed(e)} />
    </div>
  );
};

export default BondEnquiryDetailPopup;
