import moment from "moment";
import { type } from "os";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { Annual_income_status, Depository, Experience, Gender, GenderString, kraStatus, MarriedStatus, PageLinks, PersonVideVerifyStatus, Status, Yes_or_No, AccountStatus } from "../common/Constants";
import { APIData, FormDataTypes, FormDefaultConstants } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import { getDisplayData, getDisplaySelectOption, getDownloadFile, getDownloadURLExtension, getFormattedLocalDateTime, getURLExtension, numberWithCommas } from "../common/Utilities";
import AccountStatusModal from "../modals/AccountStatusModal";
import ImagePreviewModal from "../modals/ImagePreviewModal";

const IPODetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const customerId = location.state;
    // const [customerId,setCustomerid] = useState(customerId)
    const [userId, setUserId] = useState()
    const [personalDetails, setPersonlaDetails] = useState<APIData.personalDetail>();
    const [FACTADetails, setFACTADetails] = useState<APIData.FactaDetails[]>([]);
    const [bankDetails, setBankDetails] = useState<APIData.BankDetailsDatas[]>([]);
    const [Cvl_kraAddresDetails, setCvl_kraAddressDetails] = useState<APIData.AddressDatas_cvl_kra>();
    const [digilocker_aAddresDetails, setDigilockerAddressDetails] = useState<APIData.Digilocker_address_data>();
    const [existing_demat_details, setExistingDematDetails] = useState<APIData.ExistingDematAccountDetails[]>([])
    const [new_demat_details, setNewDematDetails] = useState<APIData.NewDematAccountDetails[]>([]);
    const [suspended_data, setSuspendedData] = useState({});
    const [account_status_detail, setAccount_status_detail] = useState<APIData.AccountStatusDetails>();
    const [nominee_details, setNomineeDetails] = useState<APIData.NomineeDetails>();
    const [open, setOpen] = useState(false);
    const [documentDetails, setDocumentDetails] = useState<any>({});
    const [documentProofDetails, setDocumentProofDetails] = useState<APIData.personalDetail>()
    const { setShowPreloader } = useAppStateAPI();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [IPODetails, setIPODetails] = useState<APIData.IPODetailView>(InitialData.IPODetailView);



    useEffect(() => {
        loadIPODetail(customerId);
    }, [customerId]);

    const loadIPODetail = (customerId: string) => {
        if (customerId != '') {
            setShowPreloader(true);
            ServerAPI.IPODetail(customerId).then((res) => {
                setIPODetails(res);
            }).finally(() => {
                setShowPreloader(false)
            })
        }
    }

    // const back_page = () => {
    //     navigate(PageLinks.USERS)
    // }
    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
        // user_details_datas()
        // setUserId(customerId);
    }

    const handlePreviewClose = (e: any) => {
        setOpen(e);
    }

    const status_update = (e: any) => {
        setOpen(true);
        setUserId(e)
    }

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between mt-4 pt-4">
                                        <div className="page-title-rightds">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item ">Bonds</li>
                                                <li className="breadcrumb-item active"><b>IPO Detail View</b></li>

                                            </ol>
                                        </div>
                                        <div className='cursor-pointer' onClick={() => navigate(-1)}>
                                            <p className='mb-0 active'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                                                <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                                            </svg>
                                                <span> Back </span></p>
                                        </div>


                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-header col-xl-12 w-100">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <h5 className="my-3 header">IPO Bond Details </h5>
                                                        <div className="">
                                                            <a className="cancel-btn_1 border-light quicksand-medium px-2 mx-3 py-2" onClick={() => navigate(PageLinks.IPO_ADD, { state: { id: customerId, mode: 'edit' } })} ><img src="../../assets/images/edit.svg" style={{ marginBottom: "4px", width: "16px" }} /> Edit</a>
                                                        </div>
                                                    </div>

                                                    <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                        {/* <div className="card-header">
                                                            <h5 className="m-0 header"> Personal Details </h5>
                                                        </div> */}
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issuer Name</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_issuer_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Bond Name</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Nature of Instrument</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_nature_of_instrument, 'bond_nature_of_instrument')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ISIN Number</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_isin_number)}</h6>
                                                                </div>
                                                               
                                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Our Status</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_our_status, 'bond_our_status')}</h6>
                                                                </div>
                                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Listing</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_listing, 'bond_listing')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Opening Date</p>
                                                                    <h6>{getFormattedLocalDateTime(IPODetails?.bond_opening_date)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Closing Date</p>
                                                                    <h6>{getFormattedLocalDateTime(IPODetails?.bond_closing_date)}</h6>
                                                                </div>
                                                                  <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Yeild (%)</p>
                                                                    <h6>{getDisplayData(IPODetails?.bonds_yeild)}</h6>
                                                                </div>
                                                                 <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Face Value (₹)</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_face_value)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Depository</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_depository, 'bond_depository')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Script ID</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_script_id)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">IPO Sell Window Days</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_ipo_sell_windows_days)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Coupon on</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_coupon_on)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Minimum Application</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_minimum_application)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issue Size (In crores)</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_issue_size)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Bond Logo</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_logo)}>{getDownloadURLExtension(IPODetails?.bond_logo)}</h6>
                                                                </div>
                                                               
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Security Type</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_security_type, 'bond_security_type')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issue Status</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_issue_status, 'bond_issue_status')}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Effort Basis</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_effort_basis, 'bond_effort_basis')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Registrar</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_registrar_name, 'bond_registrar_name')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Trustee</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_trustee_name)}</h6>
                                                                </div>
                                                              
                                                              


                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Int on App Money</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_int_on_app_money)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Int on Refund Money</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_int_on_refund_money)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Early Bird Incentive</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_early_bid_incentive)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Other Incentive</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_other_incentive)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">E-Form Incentive</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_e_form_incentive)}</h6>
                                                                </div>
                                                              

                                                               
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Allotment Date</p>
                                                                    <h6>{getFormattedLocalDateTime(IPODetails?.bond_allotment_date)}</h6>
                                                                </div>
                                                              
                                                               
                                                               

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Green Shoe</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_green_shoe, 'bond_green_shoe')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Green Shoe Size</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_green_shoe_size)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Final Issue Amount (₹)</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_final_issue_amount)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Procurement Amount (₹)</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_procurement_amount)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">GST</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails?.bond_gst, 'bond_gst')}%</h6>
                                                                </div>

                                                               
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Arranger Name (if any)</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_arranger_name)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">UPI App No Series (For Trust)</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_upi_app_no_series_1)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ASBA App No Series (For Trust)</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_asba_app_no_series_1)}</h6>
                                                                </div>
                                                               
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Category Instruction</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_category_instructions)}>{getDownloadURLExtension(IPODetails?.bond_category_instructions)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Sub Category Code</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_sub_category_code)}>{getDownloadURLExtension(IPODetails?.bond_sub_category_code)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">App No Series (Distributor)</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_app_no_series)}>{getDownloadURLExtension(IPODetails?.bond_app_no_series)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Series Instruction</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_series_instructions)}>{getDownloadURLExtension(IPODetails?.bond_series_instructions)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Product Note</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_product_note)}>{getDownloadURLExtension(IPODetails?.bond_product_note)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Listing Circle</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_listing_circle)}>{getDownloadURLExtension(IPODetails?.bond_listing_circle)}</h6>
                                                                </div>

                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Category Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">I - Institutional</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_category_institutional)}%</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">II - Non-Institutiona1</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_category_non_institutional)}%</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">III - HNI</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_category_hni)}%</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">IV - Retail</p>
                                                                    <h6>{getDisplayData(IPODetails?.bond_category_retail)}%</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header">Maturity Details</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Maturity Date</p>
                                                                            <h6>{getDisplayData(IPODetails?.bond_maturity_date)}</h6>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Maturity Amount</p>
                                                                            <h6>{numberWithCommas(IPODetails?.bond_maturity_amount)}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header">Coupon Details</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Coupon Date</p>
                                                                            <h6>{getDisplayData(IPODetails?.bond_coupon_date)}</h6>
                                                                        </div>
                                                                        <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Coupon Amount</p>
                                                                            <h6>{numberWithCommas(IPODetails?.bond_coupon_amount)}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header">Call Details</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Call Date</p>
                                                                            <h6>18 / 11 / 2022</h6>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Call Amount</p>
                                                                            <h6>------------</h6>
                                                                        </div> */}
                                                                        {
                                                                            IPODetails?.bond_call_details != undefined && IPODetails?.bond_call_details.length > 0 ?
                                                                                IPODetails?.bond_call_details.map((call: APIData.BondCallDetails, index: number) => {
                                                                                    return (
                                                                                        <div className="row">
                                                                                            <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                                <p className="detail-title">Call Date</p>
                                                                                                <h6>{getDisplayData(call.bond_call_detail_datetime)}</h6>
                                                                                            </div>
                                                                                            <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                                <p className="detail-title">Call Amount</p>
                                                                                                <h6>{numberWithCommas(call.bond_call_detail_amount)}</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) : <h5>No Data Found</h5>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header">Put Details</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        {/* <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Put Date</p>
                                                                            <h6>18 / 11 / 2022</h6>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Put Amount</p>
                                                                            <h6>------------</h6>
                                                                        </div> */}
                                                                        {
                                                                            IPODetails?.bond_put_details != undefined && IPODetails?.bond_put_details.length > 0 ?
                                                                                IPODetails?.bond_put_details.map((put: APIData.BondPutDetails, index: number) => {
                                                                                    return (
                                                                                        <div className="row">
                                                                                            <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                                <p className="detail-title">Call Date</p>
                                                                                                <h6>{getDisplayData(put.bond_put_detail_datetime)}</h6>
                                                                                            </div>
                                                                                            <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                                                <p className="detail-title">Call Amount</p>
                                                                                                <h6>{numberWithCommas(put.bond_put_detail_amount)}</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }) : <h5>No Data Found</h5>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    {/* <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Interest Calculate by Yearly</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="table-rep-plugin">
                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                        <thead className="city-table">
                                                                            <tr>
                                                                                <th className="text-center">Sr.No</th>
                                                                                <th className="text-center">Interest Date</th>
                                                                                <th className="text-center">Interest Rate</th>
                                                                                <th className="text-center">Interest Amount</th>
                                                                                <th className="text-center">No. of Days</th>
                                                                                <th className="text-center"></th>
                                                                                <th className="text-center"></th>
                                                                                <th className="text-center"></th>
                                                                                <th className="text-center"></th>
                                                                            </tr>
                                                                        </thead>


                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="text-center">01</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">02</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">03</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">04</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td className="text-center">05</td>
                                                                                <td className="text-center">14 / 12 / 2022</td>
                                                                                <td className="text-center">9.45%</td>
                                                                                <td className="text-center">₹ 1,00,000</td>
                                                                                <td className="text-center">365</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br /> */}

                                                    {/* <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Bond's Rating Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="card-header px-0" style={{ borderBottom: 'none' }}>
                                                                <h5 className="m-0 header">CRISIL</h5>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Short Rating</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Long Rating</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Rating Agency</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Bond’s Rating image</p>
                                                                    <h6 style={{ textDecoration: "underline",cursor:"pointer" }}>AAA.png <img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px" }} /></h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Rationale Document</p>
                                                                    <h6 style={{ textDecoration: "underline",cursor:"pointer" }}>Test.Pdf <img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px" }} /></h6>
                                                                </div>
                                                            </div>
                                                            <div className="card-header px-0" style={{ borderBottom: 'none' }}>
                                                                <h5 className="m-0 header">CRISIL</h5>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Short Rating</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Long Rating</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Rating Agency</p>
                                                                    <h6>Lorem ipsum</h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Bond’s Rating image</p>
                                                                    <h6 style={{ textDecoration: "underline",cursor:"pointer" }}>AAA.png <img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px" }} /></h6>
                                                                </div>
                                                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                    <p className="detail-title">Rationale Document</p>
                                                                    <h6 style={{ textDecoration: "underline",cursor:"pointer" }}>Test.Pdf <img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px" }} /></h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br /> */}
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">IPO's Rating Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            {
                                                                IPODetails.bond_rating_details != undefined && IPODetails.bond_rating_details.length > 0 ?
                                                                    IPODetails.bond_rating_details.map((rat: APIData.BondRatingDetails) => {
                                                                        return (
                                                                            <div className="row">
                                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                                    <p className="detail-title">Rating Agency</p>
                                                                                    <h6>{getDisplayData(rat?.bond_rating_agency)}</h6>
                                                                                </div>
                                                                                {/* <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                                    <p className="detail-title">Bond Rating Image</p>
                                                                                    <h6>{getDownloadFile(rat.)}</h6>
                                                                                </div> */}
                                                                                <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3">
                                                                                    <p className="detail-title">Bond’s Rating Certification</p>
                                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_product_note)}>{getDownloadURLExtension(rat?.bond_rating_certificate)} <img src="../../assets/images/download.svg" style={{ width: "12px", marginBottom: "2px" }} /></h6>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }) : <h5> No Data Found</h5>
                                                            }

                                                        </div>
                                                    </div>
                                                    <br />

                                                    {/* <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Investment Purpose Categories</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-6">
                                                                    <div className="col brand-tag-active p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Earn higher fixed income than FDs</div>
                                                                    <div className="col brand-tag-active p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Pledge for F&O</div>
                                                                    <div className="col brand-tag p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Invest for short term</div>
                                                                    <div className="col brand-tag-active p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Invest in NRI eligible bonds</div>
                                                                    <div className="col brand-tag p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Get tax exemption (HUF)</div>
                                                                    <div className="col brand-tag p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Save capital gains tax</div>
                                                                    <div className="col brand-tag p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Get tax free returns</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Eligible Investors Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-8 ">
                                                                    <div className="col brand-tag-active p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Individual</div>
                                                                    <div className="col brand-tag-active p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">HUF</div>
                                                                    <div className="col brand-tag p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Corporate</div>
                                                                    <div className="col brand-tag-active p-1  mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Trust</div>
                                                                    <div className="col brand-tag p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center">Instition</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br /> */}

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Investment Purpose Categories</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-12">
                                                                    {
                                                                        IPODetails.bond_brand_tags != undefined && IPODetails.bond_brand_tags.length > 0 ?
                                                                            IPODetails.bond_brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
                                                                                return (
                                                                                    <div className={`col-3 brand-tag${tags.bond_brand_tag_available != null && tags.bond_brand_tag_available != 0 ? '-active' : ''} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(tags.brand_tag_name)}</div>
                                                                                )
                                                                            }) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Eligible Investors Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-12 ">
                                                                    {
                                                                        IPODetails.bond_eligible_investors != undefined && IPODetails.bond_eligible_investors.length > 0 ?
                                                                            IPODetails.bond_eligible_investors.map((inv: APIData.BondEligibleInvestors, index: number) => {
                                                                                return (
                                                                                    <div className={`col-3 brand-tag${inv.eligible_investors_available != null && inv.eligible_investors_available != 0 ? '-active' : ''} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(inv.eligible_investor_name)}</div>
                                                                                )
                                                                            }) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">About This Bond</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="container text-center">
                                                                <div className="row row-cols-12 ">
                                                                    <h6>{getDisplayData(IPODetails?.bond_about_this_ipo)}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Key Highlights</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <ul>
                                                                <li>{getDisplayData(IPODetails.bond_key_highlights)}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                                                        <div className="card-header">
                                                            <h5 className="m-0 header">Issuer Details</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issuer's Type</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails.bond_issuer_details[0]?.bond_issuer_type, 'bond_issuers_type')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Total Annual Revenue</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_total_annual_revenue)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Year of Inception</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_year_of_inception)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Industry</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_industry)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Head Office</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_head_office)}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Type of Issuer</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails.bond_issuer_details[0]?.bond_type_of_issuer, 'bond_type_of_issuer')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Series</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_series)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Tenor (years)</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_tenor)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Coupon Type</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails.bond_issuer_details[0]?.bond_issuer_coupon_type, 'bond_issuer_coupon_type')}</h6>
                                                                </div>

                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Frequency of Interest</p>
                                                                    <h6>{getDisplaySelectOption(IPODetails.bond_issuer_details[0]?.bond_issuer_frequency_of_interest, 'bond_issuer_frequency_of_interest')}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">First IP Date</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_first_ip_date)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">IP Date</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_ip_dates)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Effective Yield</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_effective_yield)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issuers Profile Link</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_profile_link)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">Issuers Finance Details</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_finance_details)}</h6>
                                                                </div>
                                                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                    <p className="detail-title">ISSUE DOCUMENTS</p>
                                                                    <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_issuer_details[0]?.bond_issuer_documents)}>{getDownloadURLExtension(IPODetails.bond_issuer_details[0]?.bond_issuer_documents)}</h6>
                                                                </div>

                                                                <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                                                    <p className="detail-title">Paragraph</p>
                                                                    <h6>{getDisplayData(IPODetails.bond_issuer_details[0]?.bond_issuer_about)}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />

                                                    {/* <div className="row">
                                                        <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                            <div className="card-header d-flex justify-content-between align-items-center">
                                                                <div className='d-flex justify-content-start'>
                                                                    <h5 className="m-0 header"> Specific Terms for Each Series of NCDs Details </h5>
                                                                </div>
                                                                <div className='d-flex justify-content-end align-items-center '>
                                                                    <div className="form-check form-switch mx-2">
                                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                        <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                        <thead className="city-table">
                                                                            <tr>
                                                                                <th>Sr. No</th>
                                                                                <th>Series 1</th>
                                                                                <th>Series 2</th>
                                                                                <th>Series 3</th>
                                                                                <th>Series 4</th>
                                                                                <th>Series 5</th>
                                                                                <th>Series 6</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr >
                                                                                <td>1</td>
                                                                                <td>Frequency of Interest Payment</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                            </tr>
                                                                            <tr >
                                                                                <td>2</td>
                                                                                <td>Tenor</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="card-body">
                                                        <div className="table-responsive " data-pattern="priority-columns">
                                                            <table id="tech-companies-1" className="table table table-bordered">
                                                                <thead className="city-table">
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>Name</th>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((res: APIData.BondNcdSeries, index: number) => {
                                                                                return (
                                                                                    <th>Series{index + 1}</th>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Frequency of Interest Payment</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{getDisplaySelectOption(feq.bond_ncd_frequency_of_interest_payment, 'bond_ncd_frequency_of_interest_payment')}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>2</td>
                                                                        <td>Minimum Application (₹)</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{feq.bond_ncd_minimum_application}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>3</td>
                                                                        <td>In Multiples of thereafter (₹)</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{feq.bond_ncd_in_multiple_terms_of_thereafter}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>4</td>
                                                                        <td>Face Value/ Issue Price of NCDs (`/ NCD) (₹)</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{feq.bond_ncd_issue_price_of_ncd}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>5</td>
                                                                        <td>Tenor</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{feq.bond_ncd_tenor}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>6</td>
                                                                        <td>Coupon (% per annum)</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{feq.bond_ncd_coupon}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>7</td>
                                                                        <td>Effective Yield (% per annum)</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{feq.bond_ncd_effective_yield}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>8</td>
                                                                        <td>Mode of Interest Payment</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{getDisplaySelectOption(feq.bond_ncd_mode_of_interest_payment, 'bond_ncd_mode_of_interest_payment')}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>9</td>
                                                                        <td>Redemption Amount (₹ / NCD) on Maturity</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{feq.bond_ncd_redemption_amount}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td>10</td>
                                                                        <td>Put and Call Option</td>
                                                                        {
                                                                            IPODetails.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                return (
                                                                                    <td>{getDisplaySelectOption(feq.bond_ncd_put_and_option, 'bond_ncd_put_and_option')}</td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                            <div className="card-header d-flex justify-content-between align-items-center">
                                                                <div className='d-flex justify-content-start'>
                                                                    <h5 className="m-0 header">Brokerage Details</h5>
                                                                </div>
                                                            </div>
                                                            {/* <div className="card-body">
                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                        <thead className="city-table">
                                                                            <tr>
                                                                                <th>Sr. No</th>
                                                                                <th>Category Name</th>
                                                                                <th>Series 1 (36 Months)</th>
                                                                                <th>Series 2 (36 Months)</th>
                                                                                <th>Series 3 (36 Months)</th>
                                                                                <th>Series 4 (36 Months)</th>
                                                                                <th>Series 5 (36 Months)</th>
                                                                                <th>Series 6 (36 Months)</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                IPODetails.bond_brokerage_details != undefined && IPODetails.bond_brokerage_details.length > 0 ?
                                                                                    IPODetails.bond_brokerage_details.map((brokerage: APIData.BondBrokerageDetails, index: number) => {
                                                                                        return (
                                                                                            <tr >
                                                                                                <td>{index+1}</td>
                                                                                                <td>{getDisplayData(brokerage.bond_brokerage_value)}</td>
                                                                                                <td>Monthly</td>
                                                                                                <td>Cumulative</td>
                                                                                                <td>Monthly</td>
                                                                                                <td>Cumulative</td>
                                                                                                <td>Monthly</td>
                                                                                                <td>Monthly</td>
                                                                                            </tr>

                                                                                        )
                                                                                    })
                                                                            }
                                                                            <tr >
                                                                                <td>2</td>
                                                                                <td>Tenor</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                                <td>Cumulative</td>
                                                                                <td>Monthly</td>
                                                                                <td>Monthly</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div> */}
                                                            {/* <div className="card-body">
                                                                {
                                                                    IPODetails.bond_brokerage_details.length > 0 ?
                                                                        <div className="table-responsive " data-pattern="priority-columns">
                                                                            <table id="tech-companies-1" className="table table table-bordered">
                                                                                <thead className="city-table">
                                                                                    <tr>
                                                                                        <th>Category Name</th>
                                                                                        {
                                                                                            IPODetails.bond_brokerage_details.map((res: APIData.BondBrokerageDetails, index: number) => {
                                                                                                return (
                                                                                                    <th>{`Series(${res.bond_breakage_series_number})`}</th>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        IPODetails.bond_brokerage_details.map((res: APIData.BondBrokerageDetails, i: any) => (
                                                                                            <tr key={i}>
                                                                                                <td>{i + 1}</td>
                                                                                                <td>{res.bond_brokerage_value}</td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        : <tbody>
                                                                            <tr className='py-4'>
                                                                                <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                            </tr>
                                                                        </tbody>
                                                                }
                                                            </div> */}
                                                            <tbody>
                                                                <tr className='py-4'>
                                                                    <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                </tr>
                                                            </tbody>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                            <div className="card-header">
                                                                <h5 className="m-0 header"> Disclaimer </h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <h6>{getDisplayData(IPODetails.bond_disclaimer)}</h6>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header">ASBA Details</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_asba_details)}><img src="../../assets/images/download.svg" style={{ width: "18px", marginBottom: "2px", paddingRight: "5px" }} />{getDownloadURLExtension(IPODetails.bond_asba_details)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h5 className="m-0 header">Terms & Conditions Link</h5>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(IPODetails?.bond_term_condition_link)}><img src="../../assets/images/link.svg" style={{ width: "18px", marginBottom: "2px", paddingRight: "5px" }} />{getDisplayData(IPODetails.bond_term_condition_link)}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <AccountStatusModal userId={userId} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            {/* <SimpleModal contents={res.suspended_remarks} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} /> */}
            <ImagePreviewModal userId={userId} handleOpen={() => handleClickOpen} openModal={previewOpen} handleClose={(e: any) => handleClose(e)} />
        </div >
    );
}

export default IPODetails;