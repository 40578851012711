// import { useState } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { PageLinks } from "./common/Constants";
// import Dashboard from "./components/Dashboard";
import Login from "./components/login";
import Cities from "./components/Masters/Cities";
import Users from "./components/Users";
import UsersDetails from "./components/UsersDetails";
import IpvVerification from "./modals/IpvVerification";
import PanVerification from "./modals/PanVerification";
import SuspendedUserList from "./components/SuspendedUsersList";
import AllUsersLogs from "./components/AllUsersLog";
import UserLogs from "./components/IndividualUserLogs"
import Preloader from "./common/Preloader";
import BondList from "./components/BondList";
import BondAdd from "./components/BondAdd";
import IPOAdd from "./components/IPOAdd";
import BondDetails from "./components/BondDetails";
import IPODetails from "./components/IPODetails";
import IPOFormList from "./components/IPOFormList";
import IPOFormAdd from "./components/IPOFormAdd";
import DealerList from "./components/DealerList";
import DealerAdd from "./components/DealerAdd";
import DealerDetailView from "./components/DealerDetailView";
import DistributorList from "./components/DistributorList";
import DistributorAdd from "./components/DistributorAdd";
import DistributorTypeList from "./components/DistributorTypeList ";
import DistributorTypeAdd from "./components/DistributorTypeAdd";
import DistributorSubTypeList from "./components/DistributorSubTypeList ";
import DistributorSubTypeAdd from "./components/DistributorSubTypeAdd";
import DistributorCategoryList from "./components/DistributorCategoryList ";
import DistributorCategoryAdd from "./components/DistributorCategoryAdd";
import BrandTagList from "./components/BrandTagList";
import BrandTagAdd from "./components/BrandTagAdd";
import EligibleInvestorList from "./components/EligibleInvestorList";
import EligibleInvestorAdd from "./components/EligibleInvestorAdd";
import BrokerList from "./components/BrokerList";
import BrokerAdd from "./components/BrokerAdd";
import ASBABankLocationList from "./components/ASBABankLocationList";
import ASBABankLocationAdd from "./components/ASBABankLocationAdd";
import SGBList from "./components/SGBList";
import SGBAdd from "./components/SGBAdd";
import SGBDetails from "./components/SGBDetails";
import IPOFormAllocation from "./components/IPOFormAllocation";
import BrokerageCategoryAdd from "./components/BrokerageCategoryAdd";
import BrokerageCategoryList from "./components/BrokerageCategoryList";
import CustomerOrderList from "./components/CustomerOrderList";
import CustomerOrderDetailView from "./components/CustomerOrderDetailView";
import BondSubcribeList from "./components/BondSubcribeList";
import TrustMonyUsers from "./components/TrustMonyUsers";
import BondPriceDetailView from "./components/BondPriceDetailView";
import UserOrderDetailView from "./components/UserOrderDetailView";
import IPOOrderDetailView from "./components/IPOOrderDetailView";
import BondOrderDetailview from "./components/BondOrderDetailview";
import SGBOrderDetailview from "./components/SGBOrderDetailview";
import DistributorDetailView from "./components/DistributorDetailView";
import BrokerDetails from "./components/BrokerDetails";
import ApiStatus from "./components/ApiStatus";

const AppNavigate: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path={PageLinks.LOGIN} element={<Login />} />
                <Route path={PageLinks.USERS} element={<Users />} />
                <Route path={PageLinks.CITIES} element={<Cities />} />
                <Route path={PageLinks.USERSDETAILS} element={<UsersDetails />} />
                <Route path={PageLinks.IPVVERIFY} element={<IpvVerification />} />
                <Route path={PageLinks.PANVERIFY} element={<PanVerification />} />
                <Route path={PageLinks.SUSPENDED_USER_LIST} element={<SuspendedUserList />} />
                <Route path={PageLinks.ALL_USERS_LOGS} element={<AllUsersLogs />} />
                <Route path={PageLinks.INDIVIDUAL_USER_LOGS} element={<UserLogs />} />
                <Route path={PageLinks.BOND_LIST} element={<BondList />} />
                <Route path={PageLinks.BOND_ADD} element={<BondAdd />} />
                <Route path={PageLinks.IPO_ADD} element={<IPOAdd />} />
                <Route path={PageLinks.BOND_DETAIL} element={<BondDetails />} />
                <Route path={PageLinks.IPO_DETAIL} element={<IPODetails />} />
                <Route path={PageLinks.BROKER_DETAIL} element={<BrokerDetails />} />
                <Route path={PageLinks.IPO_FORM_LIST} element={<IPOFormList />} />
                <Route path={PageLinks.IPO_FORM_ADD} element={<IPOFormAdd />} />
                <Route path={PageLinks.DEALER_LIST} element={<DealerList />} />
                <Route path={PageLinks.DEALER_ADD} element={<DealerAdd />} />
                <Route path={PageLinks.DEALER_Detail} element={<DealerDetailView />} />
                <Route path={PageLinks.DISTRIBUTOR_LIST} element={<DistributorList />} />
                <Route path={PageLinks.DISTRIBUTOR_ADD} element={<DistributorAdd />} />
                <Route path={PageLinks.DISTRIBUTOR_TYPE_LIST} element={<DistributorTypeList />} />
                <Route path={PageLinks.DISTRIBUTOR_TYPE_ADD} element={<DistributorTypeAdd />} />
                <Route path={PageLinks.DISTRIBUTOR_SUB_TYPE_LIST} element={<DistributorSubTypeList />} />
                <Route path={PageLinks.DISTRIBUTOR_SUB_TYPE_ADD} element={<DistributorSubTypeAdd />} />
                <Route path={PageLinks.DISTRIBUTOR_CATEGORY_LIST} element={<DistributorCategoryList />} />
                <Route path={PageLinks.DISTRIBUTOR_CATEGORY_ADD} element={<DistributorCategoryAdd />} />
                <Route path={PageLinks.BRAND_TAG_LIST} element={<BrandTagList />} />
                <Route path={PageLinks.BRAND_TAG_ADD} element={<BrandTagAdd />} />
                <Route path={PageLinks.ELIGIBLE_INVENTOR_LIST} element={<EligibleInvestorList />} />
                <Route path={PageLinks.ELIGIBLE_INVENTOR_ADD} element={<EligibleInvestorAdd />} />
                <Route path={PageLinks.API_STATUS} element={<ApiStatus />} />
                <Route path={PageLinks.BROKER_LIST} element={<BrokerList />} />
                <Route path={PageLinks.BROKER_ADD} element={<BrokerAdd />} />
                <Route path={PageLinks.ASBA_LIST} element={<ASBABankLocationList />} />
                <Route path={PageLinks.ASBA_ADD} element={<ASBABankLocationAdd />} />
                <Route path={PageLinks.SGB_LIST} element={<SGBList />} />
                <Route path={PageLinks.SGB_ADD} element={<SGBAdd />} />
                <Route path={PageLinks.SGB_DETAIL} element={<SGBDetails />} />
                <Route path={PageLinks.IPO_FORM_ALLOCATION} element={<IPOFormAllocation />} />
                <Route path={PageLinks.BOND_BROKERAGE_CATEGORIE_ADD} element={<BrokerageCategoryAdd />} />
                <Route path={PageLinks.BOND_BROKERAGE_CATEGORIE_LIST} element={<BrokerageCategoryList />} />
                <Route path={PageLinks.CUSTOMER_BOOK} element={<CustomerOrderList />} />
                <Route path={PageLinks.CUSTOMER_BOOK_DETAIL_VIEW} element={<CustomerOrderDetailView />} />
                <Route path={PageLinks.BOND_SUBCRIBE_LIST} element={<BondSubcribeList />} />
                <Route path={PageLinks.TRUSTMONEY_UERS} element={<TrustMonyUsers />} />
                <Route path={PageLinks.BOND_PRICE_DETAIL_VIEW} element={<BondPriceDetailView />} />
                <Route path={PageLinks.USER_ORDER_DETAIL_VIEW} element={<UserOrderDetailView />} />
                <Route path={PageLinks.IPO_ORDER_DETAIL_VIEW} element={<IPOOrderDetailView />} />
                <Route path={PageLinks.BOND_ORDER_DETAIL_VIEW} element={<BondOrderDetailview />} />
                <Route path={PageLinks.SGB_ORDER_DETAIL_VIEW} element={<SGBOrderDetailview />} />
                <Route path={PageLinks.DISTRIBUTOR_DETAIL} element={<DistributorDetailView />} />
                
                <Route path="/" element={<Navigate to={PageLinks.LOGIN} />} />
            </Routes>
            <Preloader />
        </Router>
    )
}

export default AppNavigate;
