import Pagination from '@mui/material/Pagination';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { Depository, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import DataTable from "react-data-table-component";
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { InitialData } from '../common/InitialValue';
import { TabList, Tabs, Tab, TabPanel } from 'react-tabs';
import { DropZone, frameSelectOptions, getURLExtension, localStringToIST } from '../common/Utilities';
import 'react-tabs/style/react-tabs.css';
import toast from 'react-hot-toast';
import { getDisplayData, getDealerStatus, getFormattedLocalDateTime } from '../common/Utilities';
import { isNull } from 'util';

const DistributorAdd: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [ifscDetails, setIfscDetails] = useState<APIData.IfscDetails>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.DistributList>(InitialData.DistributList);
    const [NDCList, setNDCList] = useState<any[]>(NCDDetailsList);
    const [addComments, setAddComments] = useState<any | undefined>(EditorState.createEmpty());
    const [addComments_text, setAddComments_text] = useState("");
    const formikRef = useRef<FormikProps<APIData.DistributList>>(null);
    const [DistributID, setDistributID] = useState(0);
    const [segmentName, setSegmentName] = useState<any[]>([]);
    const [EMPName, setEMPName] = useState<any[]>([]);
    const [companiesName, setCompaniesName] = useState<any[]>([]);
    const [ifscCode, setIfscCode] = useState("");
    const [GenderOptions, setGenderOptions] = useState<any[]>([]);
    const [DType, setDType] = useState<any[]>([]);
    const [DSubType, setDSubType] = useState<any[]>([]);
    const [DCatType, setDCatType] = useState<any[]>([]);
    const [StateType, setStateType] = useState<any[]>([]);

    let columns: any = [
        {
            name: "Contact Person",
            selector: (row: any) => getDisplayData(row.distributor_contact_name),
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row: any) => getDisplayData(row.distributor_contact_designation),
            sortable: true,
        },
        {
            name: "Landline",
            selector: (row: any) => getDisplayData(row.distributor_contact_landline),
            sortable: true,
        },
        {
            name: "Mobile No",
            selector: (row: any) => getDisplayData(row.distributor_contact_mobile),
            sortable: true,
        },
        {
            name: "FAX No",
            selector: (row: any) => getDisplayData(row.distributor_contact_fax),
            sortable: true,
        },
        {
            name: "Email ID",
            selector: (row: any) => getDisplayData(row.distributor_contact_email),
            sortable: true,
        },
        {
            name: "Action",
            cell: (row: any) => [
                <td className="city-action-rable-row d-flex">
                    <i className="fas fa-pencil table-icons pe-1 my-auto" aria-hidden="true"></i>
                    {/* <i className="fas fa-eye table-icons pe-1 my-auto"></i> */}
                    <img src='../../assets/images/delete.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} />
                </td>
            ]
        },
    ];

    const onEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddComments(editorState);
        setAddComments_text(htmlResult);
    };

    function DateIcon() {
        return (
            <SvgIcon viewBox="0 0 72.872 83.283" style={{ padding: "2px" }}>
                <path id="Path_27181" data-name="Path 27181" d="M-1078.15,955.569v7.808h-72.872v-7.808a7.809,7.809,0,0,1,7.808-7.808h7.807v-7.808a2.61,2.61,0,0,1,2.6-2.6h5.205a2.61,2.61,0,0,1,2.6,2.6v7.808h20.82v-7.808a2.609,2.609,0,0,1,2.6-2.6h5.2a2.609,2.609,0,0,1,2.6,2.6v7.808h7.808A7.81,7.81,0,0,1-1078.15,955.569Zm-72.872,13.013h72.872v44.243a7.811,7.811,0,0,1-7.808,7.809h-57.256a7.81,7.81,0,0,1-7.808-7.809Zm10.41,18.869a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.821-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.82-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Z" transform="translate(1151.022 -937.351)" />
            </SvgIcon>
        );
    }

    // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);

    const [value, setValue] = React.useState<Dayjs | null>(
        dayjs('2014-08-18T21:11:54'),
    );

    const handleChange = (newValue: Dayjs | null) => {
        setValue(newValue);
    };

    useEffect(() => {
        suspended_list();
    }, [pageNumber, search_text, toData, fromDate, preData, open])

    useEffect(() => {
        loadGender();
        ServerAPI.getDtype().then((res) => {
            setDistributID(res.data.distributor_type_id)
            try {
                let Options = frameSelectOptions(res.data, 'distributor_type_id', 'distributor_type_name');
                setFormikInitialData((e) => ({
                    ...e,
                    distributor_sharing_terms: null,
                    distributor_type_id: res.data.distributor_type_id,
                }));
                setDType(Options);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
        ServerAPI.getStype().then((res) => {
            try {
                let Options = frameSelectOptions(res.distributor_sub_types, 'distributor_sub_type_id', 'distributor_sub_type_name');
                setDSubType(Options);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
        ServerAPI.getCategory().then((res) => {
            try {
                let Options = frameSelectOptions(res.distributor_types, 'distributor_category_id', 'distributor_category_name');
                setDCatType(Options);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
        ServerAPI.getState().then((res) => {
            try {
                let Options = frameSelectOptions(res, 'state_id', 'state_name');
                setStateType(Options);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
        if (location.state.mode && location.state.mode == "edit") {
            loadIntitalBOND(location.state.Did);
           
        }
        setFormikInitialData((e) => ({
            ...e,
            distributor_id: DistributID,
        }));
        if (location.state.mode == "add") {
            loadsegment();
            loadcompanies();
            loademp();
            setFormikInitialData((e) => ({
                ...e,
                distributor_id: DistributID,
                distributor_address: [InitialData.distributoraddress],
                distributor_bank: [InitialData.distributorbank],
                distributor_contacts: [InitialData.distributorcontacts],
                distributor_dealer: [InitialData.distributordealer],
                distributor_demat: [InitialData.distributordemat],
                distributor_reg: [InitialData.distributorreg],
                distributor_sgl: [InitialData.distributorsgl],
                distributor_companies: [],
                distributor_segment: [],
                distributor_emp: [],    
                distributor_document: [InitialData.distributordocument],
            }));
            console.log(formikInitialData);
        }
    }, []);
    const loadIntitalBOND = (Did: any) => {
        if (Did) {
            setShowPreloader(true);
            ServerAPI.DistributListID(Did)
                .then((res) => {
                    console.log('12', res);
                    setDistributID(res.data.distributor_id);
                    setFormikInitialData((e) => ({
                        ...res.data,
                        distributor_id: DistributID
                    }));
                })
                .finally(() => {
                    setShowPreloader(false);
                });
        }
    }

    const loadsegment = () => {
        setShowPreloader(true);
        ServerAPI.segment().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.DistributList = ({ ...formikRef.current.values });
                if ((currValues.distributor_segment).length == 0) {
                    res.map((inv: APIData.distributorsegment, index: number) => {
                        currValues.distributor_segment.push({
                            distributor_segment_id: null,
                            segment_id: inv.distributor_segment_id,
                            segment_name: inv.segment_name,
                            distributor_segment_selected: null
                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const updatesegment = (checked: boolean, index: number) => {
        console.log(checked)
        console.log(index)
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = ({ ...formikRef.current.values });
            currValues.distributor_segment.map((tags: APIData.distributorsegment) => {
                if (currValues.distributor_segment.filter((e, i) => i == index).length == 1) {
                    currValues.distributor_segment[index].segment_id = checked == true ? index + 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
    }
    const loademp = () => {
        setShowPreloader(true);
        ServerAPI.emp_list().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.DistributList = ({ ...formikRef.current.values });
                if ((currValues.distributor_emp).length == 0) {
                    res.map((tags: APIData.distributor_emp, index: number) => {
                        currValues.distributor_emp.push({
                            distributor_empanelment_id: null,
                            empanelment_id: tags.distributor_empanelment_id,
                            empanelment_name: tags.empanelment_name,
                            distributor_empanelment_selected: null,

                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
            setEMPName(res);

        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const updateemp = (checked: boolean, index: number) => {
        console.log(checked)
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = ({ ...formikRef.current.values });
            currValues.distributor_emp.map((tags: APIData.distributor_emp) => {
                if (currValues.distributor_emp.filter((e, i) => i == index).length == 1) {
                    currValues.distributor_emp[index].empanelment_id = checked == true ? index + 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
    }
    const loadcompanies = () => {
        setShowPreloader(true);
        ServerAPI.companies_List().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.DistributList = ({ ...formikRef.current.values });
                if ((currValues.distributor_companies).length == 0) {
                    res.map((inv: APIData.distributorcompanies, index: number) => {
                        currValues.distributor_companies.push({
                            distributor_companies_id: null,
                            companies_id: inv.distributor_companies_id,
                            companies_name: inv.companies_name,
                            distributor_companies_selected: null
                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    const updatecompanies = (checked: boolean, index: number) => {
        console.log(checked)
        console.log(index)
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = ({ ...formikRef.current.values });
            currValues.distributor_companies.map((tags: APIData.distributorcompanies) => {
                if (currValues.distributor_companies.filter((e, i) => i == index).length == 1) {
                    currValues.distributor_companies[index].companies_id = checked == true ? index + 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
    }

    const addBank = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = deepCopyIPO(formikRef.current.values);
            if (currValues.distributor_bank.filter((e) => e.distributor_bank_account_number == '' || e.distributor_bank_account_ifsc == '').length == 0) {
                currValues.distributor_bank.push({
                    distributor_bank_account_number: '',
                    distributor_bank_account_id: 0,
                    distributor_bank_account_ifsc: ''
                });
            } else {
                toast.error('Please fill rating feild');
            }
            setFormikInitialData(currValues);
        }
    }
    const removeBank = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.distributor_bank.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, distributor_bank: paramValues });
        }
    }
    const addSgl = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = deepCopyIPO(formikRef.current.values);
            if (currValues.distributor_sgl.filter((e) => e.distributor_sgl_account_bank_name == '' || e.distributor_sgl_account_name == '' || e.distributor_sgl_account_number == '').length == 0) {
                currValues.distributor_sgl.push({
                    distributor_sgl_account_id: 0,
                    distributor_sgl_account_bank_name: '',
                    distributor_sgl_account_name: '',
                    distributor_sgl_account_number: ''
                });
            } else {
                toast.error('Please fill rating feild');
            }
            setFormikInitialData(currValues);
        }
    }
    const removeSgl = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.distributor_sgl.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, distributor_sgl: paramValues });
        }
    }
    const addDemat = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = deepCopyIPO(formikRef.current.values);
            if (currValues.distributor_demat.filter((e) => e.distributor_demat_account_depository == '' || e.distributor_demat_account_dp_name == '' || e.distributor_demat_account_dp_id == '').length == 0) {
                currValues.distributor_demat.push({
                    distributor_demat_account_id: 0,
                    distributor_demat_account_depository: '',
                    distributor_demat_account_dp_name: '',
                    distributor_demat_account_dp_id: '',
                    distributor_demat_account_client_id: ''
                });
            } else {
                toast.error('Please fill rating feild');
            }
            setFormikInitialData(currValues);
        }
    }
    const removeDemat = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.distributor_demat.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, distributor_demat: paramValues });
        }
    }
    const addReg = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = deepCopyIPO(formikRef.current.values);
            if (currValues.distributor_reg.filter((e) => e.distributor_registration_exchange_name == '' || e.distributor_registration_ap_reg_name == '' || e.distributor_registration_approval_date == '').length == 0) {
                currValues.distributor_reg.push({
                    distributor_registration_id: 0,
                    distributor_registration_exchange_name: '',
                    distributor_registration_ap_reg_name: '',
                    distributor_registration_approval_date: ''
                });
            } else {
                toast.error('Please fill rating feild');
            }
            setFormikInitialData(currValues);
        }
    }
    const removeReg = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.DistributList = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.distributor_reg.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, distributor_reg: paramValues });
        }
    }
    const deepCopyIPO = (Distribut: APIData.DistributList) => {
        let returnIPOAdd: APIData.DistributList = { ...Distribut };
        if (Distribut.distributor_bank !== undefined) {
            returnIPOAdd.distributor_bank = [...Distribut.distributor_bank];
        } else {
            returnIPOAdd.distributor_bank = [];
        }
        if (Distribut.distributor_sgl !== undefined) {
            returnIPOAdd.distributor_sgl = [...Distribut.distributor_sgl];
        } else {
            returnIPOAdd.distributor_sgl = [];
        }
        if (Distribut.distributor_demat !== undefined) {
            returnIPOAdd.distributor_demat = [...Distribut.distributor_demat];
        } else {
            returnIPOAdd.distributor_demat = [];
        }
        if (Distribut.distributor_reg !== undefined) {
            returnIPOAdd.distributor_reg = [...Distribut.distributor_reg];
        } else {
            returnIPOAdd.distributor_reg = [];
        }
        return returnIPOAdd;
    }
    const ifscCaps = (value: string) => {
        let ifscValue = value.toLocaleUpperCase();
        setIfscCode(ifscValue);
    };
    const ifsc = (value: string) => {
        setShowPreloader(true);
        ServerAPI.IfscCode(value)
            .then((res: any) => {
                setIfscDetails(res);
            })
            .finally(() => {
                setShowPreloader(false);
            });
    };
    // useEffect(() => {
    //     if (formikRef.current?.values) {
    //         if (ifscCode != "") {
    //             console.log(ifscCode);
    //             let currValues: APIData.DistributList = { ...formikRef.current?.values };
    //             if (currValues.distributor_bank.filter((e) => e.distributor_bank_account_ifsc == '' || e.distributor_bank_account_number == '' || e.distributor_bank_account_id == 0).length == 0) {
    //                 currValues.distributor_bank.push({
    //                     distributor_bank_account_number: '',
    //                     distributor_bank_account_id: 0,
    //                     distributor_bank_account_ifsc: '',
    //                 });
    //             }
    //         }
    //     }
    // }, [ifscCode]);
    const suspended_list = () => {
        setShowPreloader(true);
        ServerAPI.SuspentedUserList(pageNumber, search_text, toData, fromDate).then((res) => {
            setUserList(res.datas);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })

    }
    const loadGender = () => {
        setShowPreloader(true);
        ServerAPI.getloadGender().then((res) => {
            try {
                let Options = frameSelectOptions(res.message, 'gender_id', 'gender');

                setGenderOptions(Options);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    const updateNDCList = () => {
        let updatedList = NDCList;
        setNDCList(prev => ({ ...prev, header: [...prev, { name: 'Serial 2' }] }))
    }

    useEffect(() => {
        console.log(NDCList);
    }, [NDCList])


    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
    }

    const handleClose_modal = (e: any) => {
        setOpenModal(e);
        suspended_list();
    }

    const activate_user = (id: any) => {
        setOpenModal(true);
        setUserId(id);
    }

    const view_more_content = (e: any) => {
        setViewContent(e)
        setOpen(true)
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerInputFeildStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    const yupSchema = Yup.object().shape({
    //     distributor_bank_account_number: Yup.string().required("Password required"),
    // distributor_bank_account_number_confirm: Yup
    // .string()
    // .oneOf([Yup.ref("distributor_bank_account_number"), null], "Passwords don't match"),
          distributor_pan_number: Yup.string()
            .matches(/^.*[A-Z]{5}[0-9]{4}[A-Z]{1}.*$/, ' Invalid PAN number')
            .required('Required')

    });

    const submitData = (data: any, setSubmitting: any) => {

        if (location.state.mode == 'edit') {
            setShowPreloader(true);
            ServerAPI.putDistribut(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.DISTRIBUTOR_LIST, { state: res.distributor_id });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            setShowPreloader(true);
            ServerAPI.postDistribut(data).then((res) => {
                toast.success(res.message);
                navigate(PageLinks.DISTRIBUTOR_LIST, { state: res.distributor_id });
                setSubmitting(false);
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }

    return (
        // <div>
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Distributor List </a></li>
                                                <li className="breadcrumb-item active ">Add Distributor</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    values.distributor_id = location.state.mode == "edit" ? DistributID : 0;
                                                    submitData(values, setSubmitting);
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Add New Distributor </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Distributor Name</label>
                                                                                    <input id="distributor_name" name="distributor_name" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_name: e.target.value }))}
                                                                                        value={formikProps.values.distributor_name} />

                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Unique Code</label>
                                                                                    <input id="distributor_unique_code" name="distributor_unique_code" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_unique_code: e.target.value }))}
                                                                                        value={formikProps.values.distributor_unique_code} />

                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>In Prospective</label>
                                                                                    <select id="distributor_prospective" name="distributor_prospective"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_prospective: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_prospective}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">Food</option>
                                                                                        <option value="2">Entertainment</option>
                                                                                        <option value="3">Academic</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>PAN Number</label>
                                                                                    <input id="distributor_pan_number" name="distributor_pan_number" type="text" className='bond-input'
                                                                                        maxLength={10}
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_pan_number: e.target.value.toUpperCase() }))}
                                                                                        value={formikProps.values.distributor_pan_number} />
                                                                                    {formikProps.errors.distributor_pan_number ? (<div style={{ color: "#FF0000" }}>{(formikProps.errors.distributor_pan_number)+''}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>ARN Code</label>
                                                                                    <input id="distributor_arn_code" name="distributor_arn_code" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_arn_code: e.target.value }))}
                                                                                        value={formikProps.values.distributor_arn_code} />
                                                                                    {/* {formikProps.errors.distributor_arn_code ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.distributor_arn_code}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>GST Number</label>
                                                                                    <input id="distributor_gst_number" name="distributor_gst_number" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_gst_number: e.target.value }))}
                                                                                        value={formikProps.values.distributor_gst_number} />
                                                                                    {/* {formikProps.errors.distributor_gst_number ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.distributor_gst_number}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>State name (Code)</label>
                                                                                    <select id="distributor_state_id" name="distributor_state_id"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_state_id: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_state_id}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {StateType !== undefined && !StateType.some(StateType => StateType.value === "0") && (
                                                                                            <option value="0">Select</option>
                                                                                        )}
                                                                                        {StateType && StateType.map((option, index) => {
                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Gendar</label>

                                                                                    <select id="distributor_gender" name="distributor_gender"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_gender: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_gender}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {GenderOptions !== undefined && !GenderOptions.some(GenderOptions => GenderOptions.value === "0") && (
                                                                                            <option value="0">Select</option>
                                                                                        )}
                                                                                        {GenderOptions && GenderOptions.map((option, index) => {
                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Distributor Type</label>
                                                                                    <select id="distributor_type_id" name="distributor_type_id"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_type_id: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_type_id}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {DType !== undefined && !DType.some(DType => DType.value === "0") && (
                                                                                            <option value="0">Select</option>
                                                                                        )}
                                                                                        {DType && DType.map((option, index) => {
                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Distributor Sub Type</label>
                                                                                    <select id="distributor_sub_type_id" name="distributor_sub_type_id"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_sub_type_id: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_sub_type_id}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {DSubType !== undefined && !DSubType.some(DSubType => DSubType.value === "0") && (
                                                                                            <option value="0">Select</option>
                                                                                        )}
                                                                                        {DSubType && DSubType.map((option, index) => {
                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Distributor Category</label>
                                                                                    <select id="distributor_category_id" name="distributor_category_id"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_category_id: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_category_id}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {DCatType !== undefined && !DCatType.some(DCatType => DCatType.value === "0") && (
                                                                                            <option value="0">Select</option>
                                                                                        )}
                                                                                        {DCatType && DCatType.map((option, index) => {
                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                        })}
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Net Worth</label>
                                                                                    <input id="distributor_net_worth" name="distributor_net_worth" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_net_worth: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_net_worth} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>AUM</label>
                                                                                    <input id="distributor_aum" name="distributor_aum" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_aum: e.target.value }))}
                                                                                        value={formikProps.values.distributor_aum} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Location</label>
                                                                                    <input id="distributor_location" name="distributor_location" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_location: e.target.value }))}
                                                                                        value={formikProps.values.distributor_location} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Website (if any)</label>
                                                                                    <input id="distributor_website" name="distributor_website" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_website: e.target.value }))}
                                                                                        value={formikProps.values.distributor_website} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Existing Status</label>
                                                                                    <select id="distributor_existing_status" name="distributor_existing_status"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_existing_status: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_existing_status}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">Food</option>
                                                                                        <option value="2">Entertainment</option>
                                                                                        <option value="3">Academic</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Introduce By</label>
                                                                                    <select id="distributor_intro_by" name="distributor_intro_by"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_intro_by: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_intro_by}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">Food</option>
                                                                                        <option value="2">Entertainment</option>
                                                                                        <option value="3">Academic</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="distributor_zone" className='bond-label'>Distributor Zone</label>
                                                                                    <select id="distributor_zone" name="distributor_zone"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_zone: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values.distributor_zone}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">Food</option>
                                                                                        <option value="2">Entertainment</option>
                                                                                        <option value="3">Academic</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="distributor_dob" className='bond-label'>Date of Birth / Incorporation</label>

                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.distributor_dob)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, distributor_dob: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="distributor_empanelment_date" className='bond-label'>Empanelment Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.distributor_empanelment_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, distributor_empanelment_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="distributor_remarks" className='bond-label'>Remark (if any)</label>
                                                                                    <input id="distributor_remarks" name="distributor_remarks" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, distributor_remarks: e.target.value }))}
                                                                                        value={formikProps.values.distributor_remarks} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header">Segments (in which he operates) </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            <div className="row row-cols-12">
                                                                                {
                                                                                    formikProps.values?.distributor_segment != undefined && formikProps.values?.distributor_segment.length > 0 ?
                                                                                        <>
                                                                                            {
                                                                                                formikProps.values?.distributor_segment.map((dissegment, index) => {
                                                                                                    return (
                                                                                                        <div className={`col-3 brand-tag${dissegment.segment_id != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            onClick={() =>
                                                                                                                updatesegment(dissegment.segment_id != null ? false : true, index)
                                                                                                            }
                                                                                                        >{dissegment.segment_name}</div>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Companies</h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            <div className="row row-cols-12">

                                                                                {
                                                                                    formikProps.values?.distributor_companies != undefined && formikProps.values?.distributor_companies.length > 0 ?
                                                                                        <>
                                                                                            {
                                                                                                formikProps.values?.distributor_companies.map((companies, index) => {
                                                                                                    return (
                                                                                                        <div className={`col-3 brand-tag${companies.companies_id != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            onClick={() =>
                                                                                                                updatecompanies(companies.companies_id != null ? false : true, index)
                                                                                                            }
                                                                                                        >{companies.companies_name}</div>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Empanelment with Trust </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            <div className="row row-cols-12">
                                                                                {
                                                                                    formikProps.values?.distributor_emp != undefined && formikProps.values?.distributor_emp.length > 0 ?
                                                                                        <>
                                                                                            {
                                                                                                formikProps.values?.distributor_emp.map((emp, index) => {
                                                                                                    return (
                                                                                                        <div className={`col-3 brand-tag${emp.empanelment_id != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            onClick={() =>
                                                                                                                updateemp(emp.empanelment_id != null ? false : true, index)
                                                                                                            }
                                                                                                        >{emp.empanelment_name}</div>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Bank Account Details </h5>
                                                                        <button className='bond-add-btn' onClick={() => addBank()}>
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </button>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.distributor_bank.map((distributorbank, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2}>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_bank_account_ifsc" className='bond-label'>IFSC Code *</label>
                                                                                                    <div className="d-flex">
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            placeholder="Enter IFSC Code"
                                                                                                            maxLength={11}
                                                                                                            name="distributor_bank_account_ifsc"
                                                                                                            value={distributorbank.distributor_bank_account_ifsc}
                                                                                                            onChange={(e: any) => {
                                                                                                                ifscCaps(e.target.value);
                                                                                                                { distributorbank.distributor_bank_account_ifsc = e.target.value; setFormikInitialData(({ ...formikInitialData })) }
                                                                                                                handleChange(e);
                                                                                                            }}
                                                                                                            className='bond-input'
                                                                                                        />
                                                                                                        <button
                                                                                                            className={distributorbank.distributor_bank_account_ifsc.length == 11 ? "bank-ifsc-get-btn-active mx-2" : "bank-ifsc-get-btn mx-2"}
                                                                                                            onClick={(e: any) => {
                                                                                                                if (distributorbank.distributor_bank_account_ifsc.length == 11) {
                                                                                                                    e.preventDefault();
                                                                                                                    ifsc(distributorbank.distributor_bank_account_ifsc);
                                                                                                                } else {
                                                                                                                    e.preventDefault();
                                                                                                                }
                                                                                                            }}
                                                                                                        >
                                                                                                            GET
                                                                                                        </button></div>
                                                                                                </div>


                                                                                            </Grid>
                                                                                            <Grid item xs={10}>
                                                                                                <div className="col-xl-12 d-flex bank-ifsc-get-div mr-5">
                                                                                                    <div>
                                                                                                        {ifscDetails != undefined && (
                                                                                                            <div className="bank-details mx-2">
                                                                                                                <p>{ifscDetails.BANK}</p>
                                                                                                                <table>
                                                                                                                    <thead>
                                                                                                                        <tr>
                                                                                                                            <th colSpan={2} className="bank-details-table-head w-30">
                                                                                                                                IFSC Code
                                                                                                                            </th>
                                                                                                                            <th colSpan={2} className="bank-details-table-head w-20">
                                                                                                                                Branch
                                                                                                                            </th>
                                                                                                                            <th colSpan={2} className="bank-details-table-head w-50">
                                                                                                                                Address
                                                                                                                            </th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        <tr>
                                                                                                                            <td colSpan={2} className="bank-details-table-body">
                                                                                                                                {ifscDetails.IFSC}
                                                                                                                            </td>
                                                                                                                            <td colSpan={2} className="bank-details-table-body">
                                                                                                                                {ifscDetails.BRANCH}
                                                                                                                            </td>
                                                                                                                            <td colSpan={2} className="bank-details-table-body">
                                                                                                                                {ifscDetails.ADDRESS}
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </tbody>
                                                                                                                </table>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className="email-input input_field input-1">
                                                                                                            <label className="input-title">Bank Account Number *</label>
                                                                                                            <input type="password" placeholder="Enter Bank Account Number" maxLength={16}
                                                                                                                name="distributor_bank_account_number"
                                                                                                                value={distributorbank.distributor_bank_account_number}
                                                                                                                onChange={e => { distributorbank.distributor_bank_account_number = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                                className='bond-input' />
                                                                                                            {/* {distributorbank.distributor_bank_account_number ? (<div style={{ color: "#FF0000" }}>{distributorbank.distributor_bank_account_number}</div>) : null} */}

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='mx-2'>
                                                                                                        <div className="email-input input_field input-1 mx-2">
                                                                                                            <label className="input-title">Re-enter Bank Account Number *</label>
                                                                                                            <input type="text" maxLength={16} className="bond-input" placeholder="Enter Bank Account Number" name="distributor_bank_account_number_confirm" />
                                                                                                            {/* {formikProps.errors?.distributor_bank.distributor_bank_account_number_confirm && <div style={{ color: "#FF0000" }}>{formikProps.errors.distributor_bank_account_number_confirm}</div>} */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='d-flex justify-content-center'>
                                                                                                        <div className='bond-add-btn-minus'>
                                                                                                            <a onClick={() => removeBank(index)} style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>

                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> SGL Account Details </h5>
                                                                        <button className='bond-add-btn' onClick={() => addSgl()}>
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </button>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.distributor_sgl.map((distributorsgl, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2}>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_sgl_account_bank_name" className='bond-label'>Bank Name</label>
                                                                                                    <input id="distributor_sgl_account_bank_name" name="distributor_sgl_account_bank_name" type="text" className='bond-input'
                                                                                                        value={distributorsgl.distributor_sgl_account_bank_name}
                                                                                                        onChange={e => { distributorsgl.distributor_sgl_account_bank_name = e.target.value; setFormikInitialData(({ ...formikInitialData })) }} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_sgl_account_name" className='bond-label'>SGL Name</label>
                                                                                                    <input id="distributor_sgl_account_name" name="distributor_sgl_account_name" type="text" className='bond-input'
                                                                                                        value={distributorsgl.distributor_sgl_account_name}
                                                                                                        onChange={e => { distributorsgl.distributor_sgl_account_name = e.target.value; setFormikInitialData(({ ...formikInitialData })) }} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_sgl_account_name" className='bond-label'>SGL Account Number</label>
                                                                                                    <input id="distributor_sgl_account_name" name="distributor_sgl_account_name" type="text" className='bond-input'
                                                                                                        value={distributorsgl.distributor_sgl_account_number}
                                                                                                        onChange={e => { distributorsgl.distributor_sgl_account_number = e.target.value; setFormikInitialData(({ ...formikInitialData })) }} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <div className='d-flex justify-content-center'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a onClick={() => removeSgl(index)} style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Demat Account Details </h5>
                                                                        <button className='bond-add-btn' onClick={() => addDemat()}>
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </button>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.distributor_demat.map((distributordemat, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2}>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_demat_account_depository" className='bond-label'>Depository</label>
                                                                                                    <input id="distributor_demat_account_depository" name="distributor_demat_account_depository" type="text" className='bond-input'
                                                                                                        value={distributordemat.distributor_demat_account_depository}
                                                                                                        onChange={e => { distributordemat.distributor_demat_account_depository = e.target.value; setFormikInitialData(({ ...formikInitialData })) }} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_demat_account_dp_name" className='bond-label'>DP Name</label>
                                                                                                    <input id="distributor_demat_account_dp_name" name="distributor_demat_account_dp_name" type="text" className='bond-input'
                                                                                                        value={distributordemat.distributor_demat_account_dp_name}
                                                                                                        onChange={e => { distributordemat.distributor_demat_account_dp_name = e.target.value; setFormikInitialData(({ ...formikInitialData })) }} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_demat_account_dp_id" className='bond-label'>DP ID</label>
                                                                                                    <input id="distributor_demat_account_dp_id" name="distributor_demat_account_dp_id" type="text" className='bond-input'
                                                                                                        value={distributordemat.distributor_demat_account_dp_id}
                                                                                                        onChange={e => { distributordemat.distributor_demat_account_dp_id = e.target.value; setFormikInitialData(({ ...formikInitialData })) }} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            {/* <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Client ID</label>
                                                                                    <input id="Bond Name" name="lastName" type="text" className='bond-input' />
                                                                                </div>
                                                                            </Grid> */}
                                                                                            <div className='d-flex justify-content-center'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a onClick={() => removeDemat(index)} style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Registration Details </h5>
                                                                        <button className='bond-add-btn' onClick={() => addReg()}>
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </button>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.distributor_reg.map((distributorreg, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2}>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_demat_account_dp_id" className='bond-label'>Exchange Name</label>
                                                                                                    <input id="distributor_demat_account_dp_id" name="distributor_demat_account_dp_id" type="text" className='bond-input'
                                                                                                        value={distributorreg.distributor_registration_exchange_name}
                                                                                                        onChange={e => { distributorreg.distributor_registration_exchange_name = e.target.value; setFormikInitialData(({ ...formikInitialData })) }} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_registration_ap_reg_name" className='bond-label'>AP Reg Name</label>
                                                                                                    <input id="distributor_registration_ap_reg_name" name="distributor_registration_ap_reg_name" type="text" className='bond-input'
                                                                                                        value={distributorreg.distributor_registration_ap_reg_name}
                                                                                                        onChange={e => { distributorreg.distributor_registration_ap_reg_name = e.target.value; setFormikInitialData(({ ...formikInitialData })) }} />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="distributor_registration_approval_date" className='bond-label'>Approval Date</label>
                                                                                                    <DatePicker
                                                                                                        selected={localStringToIST(distributorreg.distributor_registration_approval_date)}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        closeOnScroll={true}
                                                                                                        onChange={(date: Date) => {
                                                                                                            distributorreg.distributor_registration_approval_date = moment(date).format("YYYY-MM-DD").toString();
                                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                                        }}
                                                                                                        placeholderText="DD/MM/YYYY"
                                                                                                        className='date-picker-style'
                                                                                                        peekNextMonth
                                                                                                        showMonthDropdown
                                                                                                        showYearDropdown
                                                                                                        dropdownMode="select"
                                                                                                        customInput={(<CustomInput />)}
                                                                                                    />

                                                                                                </div>
                                                                                            </Grid>
                                                                                            <div className='d-flex justify-content-center'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a onClick={() => removeReg(index)} style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                                        <div className='d-flex justify-content-start'>
                                                                            <h5 className="m-0 header"> Specific Terms for Each Series of NCDs Details </h5>
                                                                        </div>
                                                                        <div className='d-flex justify-content-end align-items-center '>
                                                                            <div className="form-check form-switch mx-2">
                                                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Disable/Enable</label>
                                                                                <input className="form-check-input " type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                                                            </div>
                                                                            <button className='bond-add-btn' onClick={() => updateNDCList()}>
                                                                                <a className='text-white'>
                                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                    Add
                                                                                </a>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <Tabs forceRenderTabPanel>
                                                                        <div className="card-body">
                                                                            <TabList className="Product_detail_tablist">
                                                                                <Tab className="product_detail_tab">Contact Details</Tab>
                                                                                <Tab className="product_detail_tab">Address Details</Tab>
                                                                                <Tab className="product_detail_tab">Dealer Details</Tab>
                                                                                <Tab className="product_detail_tab">Document Details</Tab>
                                                                                <Tab className="product_detail_tab">Sharing Terms</Tab>
                                                                            </TabList>
                                                                            <TabPanel>
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <DataTable
                                                                                        data={formikProps.values?.distributor_contacts}
                                                                                        columns={columns}
                                                                                        noHeader
                                                                                        defaultSortFieldId={"broker_id"}
                                                                                        sortIcon={<i className="fas fa-sort"></i>}
                                                                                        defaultSortAsc={true}
                                                                                        highlightOnHover
                                                                                        dense
                                                                                    />

                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel>
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                                        <thead className="city-table">
                                                                                            <tr>
                                                                                                <th>Address Type</th>
                                                                                                <th>Address 1</th>
                                                                                                <th>Address 2</th>
                                                                                                <th>Address 3</th>
                                                                                                <th>City</th>
                                                                                                <th>State</th>
                                                                                                <th>Pin Code</th>
                                                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr >
                                                                                                <td>Brookstreet Wealth Private Limited</td>
                                                                                                <td>Brookstreet Wealth Private Limited</td>
                                                                                                <td>Brookstreet Wealth Private Limited</td>
                                                                                                <td></td>
                                                                                                <td>Kolkata</td>
                                                                                                <td>West Bengal</td>
                                                                                                <td>700015</td>
                                                                                                <td className="city-action-rable-row d-flex">
                                                                                                    <i className="fas fa-pencil table-icons pe-1 my-auto" aria-hidden="true"></i>
                                                                                                    {/* <i className="fas fa-eye table-icons pe-1 my-auto"></i> */}
                                                                                                    <img src='../../assets/images/delete.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel>
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                                        <thead className="city-table">
                                                                                            <tr>
                                                                                                <th>Dealer Type</th>
                                                                                                <th>Dealer Name</th>
                                                                                                <th>From Date</th>
                                                                                                <th>To Date</th>
                                                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr >
                                                                                                <td>Primary + Secondary </td>
                                                                                                <td>Dinendra Arya</td>
                                                                                                <td>13 / 09 / 2022</td>
                                                                                                <td>13 / 09 / 2022</td>
                                                                                                <td className="city-action-rable-row d-flex">
                                                                                                    <i className="fa fa-pencil table-icons pe-1 my-auto" aria-hidden="true"></i>
                                                                                                    {/* <i className="fas fa-eye table-icons pe-1 my-auto"></i> */}
                                                                                                    <img src='../../assets/images/delete.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel>
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                                        <thead className="city-table">
                                                                                            <tr>
                                                                                                <th>Document Type</th>
                                                                                                <th colSpan={3} data-priority="3" className="">Upload File</th>
                                                                                                <th>Download</th>
                                                                                                <th colSpan={3} data-priority="3" className="">Action</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr >
                                                                                                <td>
                                                                                                    <div className='d-grid'>
                                                                                                        <label htmlFor="Nature of Instrument" className='bond-label'>Address Proof</label>
                                                                                                        <select id="Nature of Instrument" name="email"
                                                                                                            // onChange={formikProps.handleChange}
                                                                                                            // value={formikProps.values.email}
                                                                                                            className='bond-input-select'
                                                                                                        >
                                                                                                            <option value="">Select</option>
                                                                                                            <option value="Food">Food</option>
                                                                                                            <option value="Entertainment">Entertainment</option>
                                                                                                            <option value="Academic">Academic</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td colSpan={3} data-priority="3" className="">
                                                                                                    <div className='d-grid'>
                                                                                                        <label htmlFor="Bond Name" className='bond-label'>Upload Bond Logo</label>
                                                                                                        <input id="Bond Name" name="lastName" type="text" className='bond-input-upload-file'
                                                                                                        // onChange={formikProps.handleChange} value={formikProps.values.lastName}
                                                                                                        />
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td><img src='../../assets/images/download.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} /></td>
                                                                                                <td className="city-action-rable-row d-flex">
                                                                                                    <i className="fa fa-pencil table-icons pe-1 my-auto" aria-hidden="true"></i>
                                                                                                    {/* <i className="fas fa-eye table-icons pe-1 my-auto"></i> */}
                                                                                                    <img src='../../assets/images/delete.svg' style={{ width: "10px", height: "auto", margin: "auto 4px", cursor: "pointer" }} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </TabPanel>
                                                                            <TabPanel><h1>hello</h1></TabPanel>
                                                                        </div>
                                                                    </Tabs>
                                                                    <div className='row text-end my-3'>
                                                                        <div className=''>
                                                                            <a className="cancel-btn mx-3" onClick={() => navigate(-1)}>
                                                                                Cancel
                                                                            </a>
                                                                            <button type='submit' className='save-btn'>
                                                                                Save
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)} >Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <SimpleModal contents={view_content} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" />

        </div>
    );
}

export default DistributorAdd;

