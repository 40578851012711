import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { BondType, DealerActiveBondStatus, Depository, PageLinks } from "../common/Constants";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { InitialData } from "../common/InitialValue";
import { ServerAPI } from "../common/ServerAPI";
import { getDealerStatus, getDisplayData, getDisplaySelectOption, getDownloadFile, getDownloadURLExtension, getFormattedLocalDateTime, getOrderStatus, getOrderTypeFilter, numberWithCommas } from "../common/Utilities";
import ConfirmationModal from "../modals/Confirmation-modal";
import SimpleModal from "../modals/SimpleModal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Grid } from "@mui/material";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DataTable from "react-data-table-component";
import Calculater from "../modals/Calculater";

var startSerialNo = 0;
const BondPriceDetailView: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passing_value = location.state;
  const [preData, setPreData] = useState(passing_value);
  const [bondSelectOption, setBondSelectOption] = useState("all");
  const [calcudata, setCalcudata] = useState<any>([]);
  const [calculater, setCalculater] = useState(false);
  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [open, setOpen] = useState(false);
  const [open_modal, setOpenModal] = useState(false);
  const [pricelogList, setPriceLogList] = useState<APIData.PriceChangeLogs[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [user_id, setUserId] = useState();
  const [view_content, setViewContent] = useState();
  const [search_text, setSearchText] = useState("");
  const { setShowPreloader } = useAppStateAPI();
  const searchref = React.createRef();
  const [bondDetails, setBondDetails] = useState<APIData.BondDetails>(InitialData.BondDetails);
  const [startDate, setStartDate] = useState<null | Date>();
  const [endDate, setEndDate] = useState<any>();
  const [orderType, setOrderType] = useState<number>(0);
  const [activatePublishMode, setactivatePublishMode] = useState(false);
  const [Issave, setIssave] = useState(false);
  const [confirmContent, setConfirmContent] = useState("");
  const [confirmPage, setconfirmPage] = useState("");
  const [bondID, setBondID] = useState(0);
  const [isin, setIsin] = useState('');
  const [price, setPrice] = useState<any>(0);
  const [Yield, setYield] = useState<any>();
  const [Qty, setQty] = useState<any>(1);
 const[perpage, setperpage] =useState(0)
  const [all_datas, setAllDatas] = useState({
    bondID: 0,
    price: "",
    yield: "",
    startDate: "",
    endDate: "",
    qty: "",
    buySell: "",
    publish_unpublish: 0,
    listingID: 0,
  });
  const resetfilter = () => {
    setFromDate(null);
    setToData(null);
   
}

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currPage = currentPage ? currentPage : 1;
  startSerialNo = currPage * perpage - perpage;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (e: any) => {
    setOpen(e);
  };
  
  let columns: any = [
    {
      name: 'Sr.No',
width: "90px",
      cell: (row: any, index: any) => startSerialNo + index + 1,  //RDT provides index by default
    },
    {
      name: "Date Modified",
      selector: (row: any) => getDisplayData(row.change_log_modified_at),
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row: any) => getDisplayData(row.change_log_start_date),
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row: any) => getDisplayData(row.change_log_end_date),
      sortable: true,
    },
    {
      name: "Price (₹)",
      selector: (row: any) => getDisplayData(row.change_log_last_price),
      sortable: true,
    },
    {
      name: "Yeild",
      selector: (row: any) => getDisplayData(row.change_log_last_yield),
      sortable: true,
    },
    {
      name: "Qty",
      selector: (row: any) => getDisplayData(row.change_log_quantity),
      sortable: true,
    },
    {
      name: "Order Type",
      selector: (row: any) => getDisplayData(row.buy_sell_name),
      sortable: true,
    },
    {
      name: "Modified by",
      selector: (row: any) => getDisplayData(row.change_log_modified_by),
      sortable: true,
    },
  ];
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setPageNumber(page);
  };

  useEffect(() => {
    if (passing_value.bondISINNumber != 0) {
      loadBondDetail(passing_value.bondISINNumber);
    }
  }, [passing_value.bondISINNumber]);

  const loadBondDetail = (isin_number: string) => {
    if (isin_number != "") {
      setShowPreloader(true);
      ServerAPI.bondDetail(isin_number)
        .then((res) => {
          console.log(res);
          setBondDetails(res);
          setIsin(res?.bond_isin_number)
          setBondID(res?.bond_id)
        })
        .finally(() => {
          setShowPreloader(false);
        });
    }
  };
  // useEffect(() => {
  //   setYield(calcudata?.data?.YieldVal);
  //   setPrice(calcudata?.data?.price)
  // }, [calcudata])

  // const BondEnquiry = () => {
  //   setShowPreloader(true);
  //   ServerAPI.Calculater(bondID, Yield, Qty, price, orderType).then((response) => {
  //     toast.success(response["message"]);
  //     setOpenModal(true);
  //     setConfirmContent("Are you sure you want to save the new price?");
  //     setconfirmPage("update-bond-new-price");
  //     // setAllDatas({
  //     //   bondID: passing_value.bondID,
  //     //   price: data.price,
  //     //   yield: data.yield,
  //     //   startDate: moment(startDate).format("YYYY-MM-DD"),
  //     //   endDate: moment(endDate).format("YYYY-MM-DD"),
  //     //   qty: data.quantity,
  //     //   buySell: data.buy_sell_id,
  //     //   publish_unpublish: passing_value.publishMode,
  //     //   listingID: passing_value.ListingID,
  //     // });

  //   }).finally(() => {
  //     setShowPreloader(false);
  //   })
  // }
  useEffect(() => {
    loadPriceChangeLogsList();
  }, [pageNumber, search_text, toData, fromDate, open]);

  const loadPriceChangeLogsList = () => {
    setShowPreloader(true);
    ServerAPI.getPriceChangeLogsList(pageNumber, search_text, toData, fromDate)
      .then((res) => {
        try {
          setQty(res.change_log_quantity)
          setPriceLogList(res.data);
          setTotalCount(res.total_records);
          setperpage(res.per_page);
         
        } catch (error) {
          toast.error(res.error);
        }
      })
      .finally(() => {
        setShowPreloader(false);
      });
  };

  const handlePublishBondFromdetailView = (mode: number, listing_id: number, bondListingBondId: number) => {
    setOpenModal(true);
    setConfirmContent(`Are you sure you want to ${mode == DealerActiveBondStatus.Publish ? "publish" : "Un Publish"} the Bond?`);
    setconfirmPage("update-bond-publish-mode");
    setAllDatas({
      bondID: 0,
      price: "",
      yield: "",
      startDate: "",
      endDate: "",
      qty: "",
      buySell: "",
      publish_unpublish: mode,
      listingID: listing_id,
    });
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => {
    return (
      <div className="datePickerStyle" style={{ display: "flex", justifyContent: "space-between", border: "1px solid #707070" }}>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <img src="../../assets/images/calender-icon.svg" onClick={props.onClick} ref={ref} />
      </div>
    );
  });

  const handleConfirmationClosed = (e: any) => {
    setOpenModal(e);
  };

  // const schema = Yup.object({
  //   price: Yup.number().typeError("you must specify a number"),
  //   yield: Yup.number().typeError("you must specify a number"),
  //   quantity: Yup.number().typeError("you must specify a number"),
  //   buy_sell_id: Yup.string().notOneOf(["0"], "Please select a valid option").required("Required"),
  // });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
  });
    console.log("🚀 ~ file: BondPriceDetailView.tsx:266 ~ register:", register)

  const onSubmit = (data: any) => {
    if(calcudata?.data?.price == "" || calcudata?.data?.YieldVal == "" || orderType == 0 || startDate == undefined || endDate == undefined){
      toast.error("Please fill all filed")
    }else{
      setOpenModal(true);
    }
    setConfirmContent("Are you sure you want to save the new price?");
    setconfirmPage("update-bond-new-price");
    setAllDatas({
      bondID: passing_value.bondID,
      price: calcudata?.data?.price || data.price,
      yield: calcudata?.data?.YieldVal || data.yield,
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      qty: data.quantity,
      buySell: data.buy_sell_id,
      publish_unpublish: passing_value.publishMode,
      listingID: passing_value.ListingID,
    });
   
    // setShowPreloader(true);
    // ServerAPI.ActiveBondNewPriceUpdate(passing_value.bondID, data.price, data.yeild, startDate, endDate, data.quantity, data.buy_sell_id, DealerActiveBondStatus.UnPublish, passing_value.ListingID)
    //   .then((res) => {
      //     toast.success(res.message);
      //     reset();
      //     setactivatePublishMode(true);
      //   })
      //   .finally(() => {
        //     setShowPreloader(false);
        //   });
        
      };
  useEffect(() => {
    if(Issave == true){
      setStartDate(null);
      setEndDate(null);
      setOrderType(0);
    }
  },[Issave])
      return (
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Users</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trust Mony Users</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Dealers</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a>{bondDetails.bond_name}</a>
                        </li>
                      </ol>
                    </div>
                    <div className="cursor-pointer" onClick={() => navigate(-1)}>
                      <p className="mb-0 active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                          <path id="Path_22590" data-name="Path 22590" d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z" transform="translate(1775.371 -858.797)" />
                        </svg>
                        <span> Back </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs>
                <TabList className="Product_detail_tablist" style={{ marginBottom: "0rem" }}>
                  <div className=" d-flex justify-content-between align-items-end">
                    <div>
                      <Tab className="product_detail_tab">Bonds Price</Tab>
                      <Tab className="product_detail_tab">Bonds Details</Tab>
                    </div>
                  </div>
                </TabList>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                          <div>
                            <div className="col-lg-12 col-md-12 col-sm-12 modal-box-content">
                              <h3>{bondDetails.bond_name}</h3>
                              <form
                              onSubmit={handleSubmit(onSubmit)}
                              >
                                <div className="form-group">
                                  <div className="new-price">
                                    <h6>Add New Price</h6>
                                    <Grid direction="row" container spacing={2}>
                                      <Grid item xs={2}>
                                        <div className="d-grid">
                                          <label htmlFor="bond_issuer_name" className="bond-label">
                                            Price
                                          </label>
                                          {/* <input id="bond_issuer_name" name="bond_issuer_name" type="text" className="bond-input" /> */}
                                          <input
                                            // onChange={(e: any) => {
                                            //   setPrice(e.target.value);
                                            // }}
                                            required
                                            defaultValue={calcudata?.data?.price}
                                             {...register("price")} 
                                            type="number" className="bond-input" />
                                             
                                          {/* <p style={{ fontSize: "12px", margin: "6px 4px", color: "red" }}>{errors.price?.message}</p> */}
                                        </div>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div className="d-grid">
                                          <label htmlFor="bond_issuer_name" className="bond-label">
                                            Yield
                                          </label>
                                          {/* <input id="bond_issuer_name" name="bond_issuer_name" type="text" className="bond-input" /> */}
                                          <input
                                            // onChange={(e: any) => {
                                            //   setYield(e.target.value);
                                            // }}
                                            required
                                            defaultValue={calcudata?.data?.YieldVal}
                                             {...register("yield")} 
                                            type="number" className="bond-input" />
                                            
                                          {/* <p style={{ fontSize: "12px", margin: "6px 4px", color: "red" }}>{errors.yield?.message}</p> */}
                                        </div>
                                      </Grid>
                                      {/* <Grid item xs={2}>
                                        <div className="d-grid">
                                          <label htmlFor="bond_issuer_name" className="bond-label">
                                            Quantity
                                          </label>
                                         
                                          <input {...register("quantity")} type="number" className="bond-input" />
                                          <p style={{ fontSize: "12px", margin: "6px 4px", color: "red" }}>{errors.quantity?.message}</p>
                                        </div>
                                      </Grid> */}
                                      <Grid item xs={2}>
                                        <div className="d-grid">
                                          <label htmlFor="Bond Name" className="bond-label">
                                            Start Date
                                          </label>
                                          <DatePicker
                                            selected={startDate}
                                            // selected={localStringToIST(formikProps.values.bond_opening_date)}
                                            dateFormat="dd/MM/yyyy"
                                            closeOnScroll={true}
                                            onChange={(date: Date) => {
                                              console.log(date);
                                              setStartDate(date);
                                            }}
                                            placeholderText="DD/MM/YYYY"
                                            className="date-picker-style"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={<CustomInput />}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <div className="d-grid">
                                          <label htmlFor="Bond Name" className="bond-label">
                                            End Date
                                          </label>
                                          <DatePicker
                                            selected={endDate}
                                            // selected={localStringToIST(formikProps.values.bond_opening_date)}
                                            dateFormat="dd/MM/yyyy"
                                            closeOnScroll={true}
                                            onChange={(date: Date) => {
                                              console.log(date);
                                              setEndDate(date);
                                            }}
                                            placeholderText="DD/MM/YYYY"
                                            className="date-picker-style"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            customInput={<CustomInput />}
                                          />
                                        </div>

                                      </Grid>
                                      <Grid item xs={2}>
                                        <div className="d-grid">
                                          <label htmlFor="Nature of Instrument" className="bond-label">
                                            Order Type
                                          </label>
                                          <select
                                            {...register("buy_sell_id")}
                                            onChange={(e) => {
                                              console.log(e.target.value);
                                              setOrderType(parseInt(e.target.value));
                                            }}
                                            value={orderType}
                                            className="bond-input-select"
                                          >
                                            <option value={0}>Select</option>
                                            <option value={1}>Buy</option>
                                            <option value={2}>Sell</option>
                                          </select>
                                          {/* <p style={{ fontSize: "12px", margin: "6px 4px", color: "red" }}>{errors.buy_sell_id?.message}</p> */}
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <div className="d-flex justify-content-between my-3">
                                      <button className="yeild-cal" onClick={(e: any) => {
                                        e.preventDefault();
                                        handleClickOpen();
                                      }}>
                                        <span className="fa fa-calculator" style={{ padding: "0px 10px" }}></span>Yield / Price Calculator
                                      </button>
                                      <div className="d-flex">
                                        <a className="cancel-btn mx-3" onClick={() => navigate(-1)}>
                                          Cancel
                                        </a>
                                        <button
                                          type="submit"
                                          className="save-btn"
                                        
                                          // style={{
                                          //   color: activatePublishMode == true ? "#22263D" : "#FFFFFF",
                                          //   backgroundColor: activatePublishMode == true ? "#E1E0E6" : "",
                                          //   cursor: activatePublishMode == true ? "not-allowed" : "pointer",
                                          //   boxShadow: "none",
                                          //   border: activatePublishMode == true ? "none" : "",
                                          // }}
                                          // disabled={activatePublishMode == true ? true : false}
                                          // onClick={(e: any) => {
                                          //   e.preventDefault();
                                          //   BondEnquiry();
                                          // }}
                                        >
                                          Save
                                        </button>
                                        {passing_value.publishMode == null || passing_value.publishMode == DealerActiveBondStatus.UnPublish ? (
                                          <a
                                            className="cancel-btn mx-3"
                                            style={{
                                              color: activatePublishMode != true ? "#22263D" : "#FFFFFF",
                                              backgroundColor: activatePublishMode != true ? "#E1E0E6" : "#00C6D8",
                                              boxShadow: "none",
                                              border: "none",
                                              cursor: activatePublishMode != true ? "wait" : "pointer",
                                            }}
                                            onClick={() => handlePublishBondFromdetailView(DealerActiveBondStatus.Publish, passing_value.ListingID, passing_value.bondID)}
                                          >
                                            Publish
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>

                            <div style={{ border: "1px solid #E1E0E6", padding: "20px 10px", borderRadius: "5px", margin: "20px 0px" }}>
                              <div className="row" style={{ paddingBottom: "10px" }}>
                                <div className="col-xl-8">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h6 style={{ margin: "0px" }}>Price Change Logs</h6>
                                    <div className="d-flex justify-content-start align-items-center">
                                      <h4 className="card-title text-end p-2 m-0">Search :</h4>
                                      <form className="app-search card-title p-2 m-0">
                                        <div className="position-relative">
                                          <input type="text" className="select-style-search" onChange={(e) => setSearchText(e.target.value)} placeholder="Date Modified, Start Date ..." />
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-xl-4 text-end">
                                  <div className="d-flex justify-content-end align-items-center">
                                    <form className="app-search card-title p-2 m-0 w-100">
                                      <div className="position-relative">
                                        <DatePicker
                                          selected={fromDate}
                                          onChange={(date: Date) => setFromDate(date)}
                                          placeholderText="DD/MM/YYYY"
                                          className="date-picker-style"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select" customInput={<CustomInput />} />
                                      </div>
                                    </form>
                                    <h4 className="card-title p-2 m-0">TO</h4>
                                    <form className="app-search card-title p-2 m-0 w-100">
                                      <div className="position-relative">
                                        <DatePicker
                                          selected={toData}
                                          onChange={(date: Date) => setToData(date)}
                                          placeholderText="DD/MM/YYYY"
                                          className="date-picker-style"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          customInput={<CustomInput />} />
                                      </div>
                                    </form>
                                    <button onClick={resetfilter} className=" btn-suspend text-white">Reset</button>
                                  </div>
                                </div>
                              </div>

                              <div className="table-rep-plugin">
                                <div className="table-responsive " data-pattern="priority-columns">
                                  {/* <table id="tech-companies-1" className="table table table-bordered">
                                    <thead className="city-table">
                                      <tr>
                                        <th>Sr.No</th>
                                        <th>Date Modified</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Price (₹)</th>
                                        <th>Yeild</th>
                                        <th>Qty</th>
                                        <th>Order Type</th>
                                        <th>Modified by</th>
                                      </tr>
                                    </thead>
                                    {pricelogList != undefined && pricelogList.length > 0 ? (
                                      <>
                                        {pricelogList.map((res: APIData.PriceChangeLogs, i: number) => (
                                          <tbody key={i}>
                                            <tr>
                                              <td> {i + 1} </td> */}
                                  {/* <td>{getFormattedLocalDateTime(res.change_log_modified_at)}</td>
                                              <td>{getFormattedLocalDateTime(res.change_log_start_date)}</td> */}
                                  {/* <td>{res.change_log_modified_at != null && moment(res.change_log_modified_at).format("DD-MM-YYYY,  hh:mm A")}</td>
                                              <td>{res.change_log_start_date != null && moment(res.change_log_start_date).format("DD-MM-YYYY,  hh:mm A")}</td> */}
                                  {/* <td>{getDisplayData(res.change_log_end_date)}</td> */}
                                  {/* <td>{res.change_log_end_date != null && moment(res.change_log_end_date).format("DD-MM-YYYY,  hh:mm A")}</td>
                                              <td>₹ {numberWithCommas(res.change_log_last_price)}</td>
                                              <td>{getDisplayData(res.change_log_last_yield)}</td>
                                              <td>{getDisplayData(res.change_log_quantity)}</td>
                                              <td>{getDisplayData(res.buy_sell_name)}</td>
                                              <td>{getDisplayData(res.change_log_modified_by)}</td>
                                            </tr>
                                          </tbody>
                                        ))}
                                      </>
                                    ) : (
                                      <tbody>
                                        <tr className="py-4">
                                          <td className="text-center py-5" colSpan={11}>
                                            No Data Found.
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table> */}
                                  <DataTable
                                    data={pricelogList}
                                    columns={columns}
                                    noHeader
                                    defaultSortFieldId={"bond_id"}
                                    sortIcon={<i className="fas fa-sort"></i>}
                                    defaultSortAsc={true}
                                    highlightOnHover
                                    dense
                                  />
                                </div>
                              </div>
                              <div className="row mt-3">
                                <div className="col-sm-12 col-md-5">
                                  {totalCount > 10 ? (
                                    <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                      Showing {pricelogList.length} of {totalCount} entries
                                    </div>
                                  ) : (
                                    <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                      Showing {totalCount == 0 ? 0 : totalCount} entries
                                    </div>
                                  )}
                                </div>
                                <div className="col-sm-12 col-md-7">
                                  <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                                    <Pagination
                                      count={Math.ceil(totalCount / perpage)}
                                      onChange={(event: any, value: any) => {
                                        setPageNumber(Number(value));
                                        setCurrentPage(Number(value));
                                      }}
                                      variant="outlined"
                                      color="primary"
                                      shape="rounded"
                                      className="pagination-style"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header col-xl-12 w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <h5 className="my-3 header"> Bond Details </h5>
                          </div>

                          <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issuer Name</p>
                                  <h6>{getDisplayData(bondDetails?.bond_issuer_name)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Bond Name</p>
                                  <h6>{getDisplayData(bondDetails?.bond_name)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Nature of Instrument</p>
                                  <h6>{getDisplaySelectOption(bondDetails?.bond_nature_of_instrument, "bond_nature_of_instrument")}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Type</p>
                                  <h6>{getDisplayData(bondDetails?.bond_security_type)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">ST</p>
                                  <h6>{getDisplayData(bondDetails?.bond_st)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Type Code</p>
                                  <h6>{getDisplayData(bondDetails?.bond_security_code)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Security Code</p>
                                  <h6>{getDisplayData(bondDetails?.bond_security_code)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Guaranted By</p>
                                  <h6>{getDisplayData(bondDetails?.bond_guaranted_by)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">ISIN Number</p>
                                  <h6>{getDisplayData(bondDetails?.bond_isin_number)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Face Value</p>
                                  <h6>{numberWithCommas(bondDetails?.bond_face_value)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issue Price</p>
                                  <h6>{numberWithCommas(bondDetails?.bond_issue_price)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Price per Bond</p>
                                  <h6>{numberWithCommas(bondDetails?.bond_price_per_bond)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issue Date</p>
                                  <h6>{getFormattedLocalDateTime(bondDetails?.bond_issue_date)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">First IP Date</p>
                                  <h6>{getFormattedLocalDateTime(bondDetails?.bond_ip_date)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Interest Frequency</p>
                                  <h6>{getDisplaySelectOption(bondDetails?.bond_interest_frequency, "bond_interest_frequency")}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Govt. Guarantee</p>
                                  <h6>{getDisplaySelectOption(bondDetails?.bond_govt_guranatee, "bond_govt_guranatee")}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Interest Type</p>
                                  <h6>{getDisplaySelectOption(bondDetails?.bond_interest_type, "bond_interest_type")}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Coupon Rate</p>
                                  <h6>{getDisplayData(bondDetails?.bond_coupon_date)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Interest Calculate by</p>
                                  <h6>n/a</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Book Closer Date</p>
                                  <h6>{getFormattedLocalDateTime(bondDetails?.bond_closer_date!)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">DMAT Book Closer Date</p>
                                  <h6>{getFormattedLocalDateTime(bondDetails?.bond_dmat_book_closer_date!)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">RBI Loan Code</p>
                                  <h6>{getDisplayData(bondDetails?.bond_rbi_loan_code)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Calc Int on Holiday</p>
                                  <h6>{getDisplayData(bondDetails?.bond_calc_int_on_holidays)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Calc Int on Maturity</p>
                                  <h6>{getDisplayData(bondDetails?.bond_int_on_maturity)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Exchange</p>
                                  <h6>{getDisplayData(bondDetails?.bond_exchange)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Listing</p>
                                  <h6>{getDisplaySelectOption(bondDetails?.bond_listing, "bond_listing")}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Minimum Application</p>
                                  <h6>{getDisplayData(bondDetails?.bond_minimum_application)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issue Size</p>
                                  <h6>{getDisplayData(bondDetails?.bond_issue_size)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Bond Logo</p>
                                  <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(bondDetails?.bond_logo)}>
                                    {getDownloadURLExtension(bondDetails?.bond_logo)}
                                  </h6>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                  <p className="detail-title">About This Bond</p>
                                  <h6>{getDisplayData(bondDetails?.bond_about_this_ipo)}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Category Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">I - Institutional</p>
                                  <h6>{getDisplayData(bondDetails?.bond_category_institutional)}%</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">II - Non-Institutiona1</p>
                                  <h6>{getDisplayData(bondDetails?.bond_category_non_institutional)}%</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">III - HNI</p>
                                  <h6>{getDisplayData(bondDetails?.bond_category_hni)}%</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">IV - Retail</p>
                                  <h6>{getDisplayData(bondDetails?.bond_category_retail)}%</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Maturity Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Maturity Date</p>
                                      <h6>{getDisplayData(bondDetails?.bond_maturity_date)}</h6>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Maturity Amount</p>
                                      <h6>{numberWithCommas(bondDetails?.bond_maturity_amount)}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Coupon Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Coupon Date</p>
                                      <h6>{getDisplayData(bondDetails?.bond_coupon_date)}</h6>
                                    </div>
                                    <div className="col-lg-6 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                      <p className="detail-title">Coupon Amount</p>
                                      <h6>{numberWithCommas(bondDetails?.bond_coupon_amount)}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Call Details</h5>
                                </div>
                                <div className="card-body">
                                  {bondDetails?.bond_call_details != undefined && bondDetails?.bond_call_details.length > 0 ? (
                                    bondDetails?.bond_call_details.map((call: APIData.BondCallDetails, index: number) => {
                                      return (
                                        <div className="row">
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Call Date</p>
                                            <h6>{getDisplayData(call.bond_call_detail_datetime)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Call Amount</p>
                                            <h6>{numberWithCommas(call.bond_call_detail_amount)}</h6>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <h5>No Data Found</h5>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Put Details</h5>
                                </div>
                                <div className="card-body">
                                  {/* <div className="row">
                                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Put Date</p>
                                                                            <h6>18 / 11 / 2022</h6>
                                                                        </div>
                                                                        <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                                                            <p className="detail-title">Put Amount</p>
                                                                            <h6>------------</h6>
                                                                        </div>
                                                                    </div> */}
                                  {bondDetails?.bond_put_details != undefined && bondDetails?.bond_put_details.length > 0 ? (
                                    bondDetails?.bond_put_details.map((put: APIData.BondPutDetails, index: number) => {
                                      return (
                                        <div className="row">
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Call Date</p>
                                            <h6>{getDisplayData(put.bond_put_detail_datetime)}</h6>
                                          </div>
                                          <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                            <p className="detail-title">Call Amount</p>
                                            <h6>{numberWithCommas(put.bond_put_detail_amount)}</h6>
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <h5>No Data Found</h5>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Interest Calculate by Yearly</h5>
                            </div>
                            <div className="card-body">
                              <div className="table-rep-plugin">
                                <div className="table-responsive " data-pattern="priority-columns">
                                  <table id="tech-companies-1" className="table table table-bordered">
                                    <thead className="city-table">
                                      <tr>
                                        <th className="text-center">Sr.No</th>
                                        <th className="text-center">Interest Date</th>
                                        <th className="text-center">Interest Rate</th>
                                        <th className="text-center">Interest Amount</th>
                                        <th className="text-center">No. of Days</th>
                                      </tr>
                                    </thead>
                                    {bondDetails?.bond_cashflow_details != undefined && bondDetails?.bond_cashflow_details.length > 0 ? (
                                      <tbody>
                                        {bondDetails?.bond_cashflow_details.map((cash: APIData.BondCashflowDetails, index: number) => {
                                          return (
                                            <tr>
                                              <td className="text-center">{index + 1}</td>
                                              <td className="text-center">{getFormattedLocalDateTime(cash.bond_cashflow_date)}</td>
                                              <td className="text-center">{getDisplayData(cash.bond_cashflow_type)}%</td>
                                              <td className="text-center">₹ {numberWithCommas(cash.bond_cashflow_amount)}</td>
                                              <td className="text-center">{getDisplayData(cash.bond_cashflow_days)}</td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    ) : (
                                      <h5>No Data Found</h5>
                                    )}
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Bond's Rating Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                  <p className="detail-title">Rating Agency</p>
                                  <h6>{getDisplayData(bondDetails?.bond_rating_details[0]?.bond_rating_agency)}</h6>
                                </div>
                                <div className="col-lg-2 col-md-4 col-sm-6  mb-4 col-sm-3">
                                  <p className="detail-title">Bond’s Rating Certification</p>
                                  <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(bondDetails?.bond_logo)}>
                                    {getDownloadURLExtension(bondDetails?.bond_rating_details[0]?.bond_rating_certificate)} <img src="../../assets/images/download.svg" style={{ width: "12px", marginBottom: "2px" }} />
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Brand Tags</h5>
                            </div>
                            <div className="card-body">
                              <div className="container text-center">
                                <div className="row row-cols-8">
                                  {bondDetails?.bond_brand_tags != undefined && bondDetails?.bond_brand_tags.length > 0 ? (
                                    bondDetails?.bond_brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
                                      return <div className={`col-3 brand-tag${tags.bond_brand_tag_id != null && tags.bond_brand_tag_id != 0 ? "-active" : ""} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(tags.brand_tag_name)}</div>;
                                    })
                                  ) : (
                                    <h5>No Data Found</h5>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Eligible Investors Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="container text-center">
                                <div className="row row-cols-12 ">
                                  {bondDetails?.bond_eligible_investors != undefined && bondDetails?.bond_eligible_investors.length > 0
                                    ? bondDetails?.bond_eligible_investors.map((inv: APIData.BondEligibleInvestors, index: number) => {
                                      return <div className={`col-3 brand-tag${inv.bond_eligible_investor_id != null && inv.bond_eligible_investor_id != 0 ? "-active" : ""} p-1 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}>{getDisplayData(inv.eligible_investor_name)}</div>;
                                    })
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />

                          <div className="card">
                            <div className="card-header">
                              <h5 className="m-0 header">Key Highlights</h5>
                            </div>
                            <div className="card-body">
                              <ul>
                                <li>{getDisplayData(bondDetails?.bond_key_highlights)}</li>
                                {/* <li>The bond issuer is of type Private Sector Bank. The Issuer raised total Rs. 500.0 Cr for this bond.</li>
                                                                <li>Use of Proceeds: Augmentation of tier-i capital to strengthen its capital adequacy and to enhance long-term resources.</li>
                                                                <li>It is rated A- by IND with STABLE outlook.</li>
                                                                <li>Vestibulum at eros</li> */}
                              </ul>
                            </div>
                          </div>
                          <br />

                          <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px" }}>
                            <div className="card-header">
                              <h5 className="m-0 header">Issuer Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issuer's Type</p>
                                  <h6>{getDisplaySelectOption(bondDetails?.bond_issuer_details[0]?.bond_issuer_type, "bond_issuers_type")}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Total Annual Revenue</p>
                                  <h6>{numberWithCommas(bondDetails?.bond_issuer_details[0]?.bond_issuer_total_annual_revenue)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Year of Inception</p>
                                  <h6>{getDisplayData(bondDetails?.bond_issuer_details[0]?.bond_issuer_year_of_inception)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Industry</p>
                                  <h6>{getDisplayData(bondDetails?.bond_issuer_details[0]?.bond_issuer_industry)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Head Office</p>
                                  <h6>{getDisplayData(bondDetails?.bond_issuer_details[0]?.bond_issuer_head_office)}</h6>
                                </div>

                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Type of Issuer</p>
                                  <h6>{getDisplaySelectOption(bondDetails?.bond_issuer_details[0]?.bond_type_of_issuer, "bond_type_of_issuer")}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issuers Profile Link</p>
                                  <h6>{getDisplayData(bondDetails?.bond_issuer_details[0]?.bond_issuer_profile_link)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">Issuers Finance Details</p>
                                  <h6>{getDisplayData(bondDetails?.bond_issuer_details[0]?.bond_issuer_finance_details)}</h6>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6  mb-4 col-sm-3 col-lg-3">
                                  <p className="detail-title">ISSUE DOCUMENTS</p>
                                  <h6 style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => getDownloadFile(bondDetails?.bond_logo)}>
                                    {getDownloadURLExtension(bondDetails?.bond_issuer_details[0]?.bond_issuer_documents)}
                                    <img src="../../assets/images/download.svg" style={{ width: "12px", marginBottom: "2px" }} />
                                  </h6>
                                </div>

                                <div className="col-lg-12 col-md-12 col-sm-12  mb-4 col-sm-12 col-lg-12">
                                  <p className="detail-title">Paragraph</p>
                                  <h6>{getDisplayData(bondDetails?.bond_issuer_details[0]?.bond_issuer_about)}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="card-body">
                            <div className="table-responsive " data-pattern="priority-columns">
                              <table id="tech-companies-1" className="table table table-bordered">
                                <thead className="city-table">
                                  <tr>
                                    <th></th>
                                    <th>Name</th>
                                    {bondDetails?.bond_ncd_series.map((res: APIData.BondNcdSeries, index: number) => {
                                      return <th>Series{index + 1}</th>;
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>Frequency of Interest Payment</td>
                                    {bondDetails?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                      return <td>{getDisplaySelectOption(feq.bond_ncd_frequency_of_interest_payment, "bond_ncd_frequency_of_interest_payment")}</td>;
                                    })}
                                  </tr>
                                  <tr>
                                    <td>2</td>
                                    <td>Tenor</td>
                                    {bondDetails?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                      return <td>{feq.bond_ncd_tenor}</td>;
                                    })}
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>Coupon (% per annum)</td>
                                    {bondDetails?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                      return <td>{feq.bond_ncd_coupon}</td>;
                                    })}
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>Effective Yield (% per annum)</td>
                                    {bondDetails?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                      return <td>{feq.bond_ncd_effective_yield}</td>;
                                    })}
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>Redemption Amount (₹ / NCD) on Maturity</td>
                                    {bondDetails?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                      return <td>{feq.bond_ncd_redemption_amount}</td>;
                                    })}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="row">
                            <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                              <div className="card-header">
                                <h5 className="m-0 header"> Disclaimer </h5>
                              </div>
                              <div className="card-body">
                                <h6>{getDisplayData(bondDetails?.bond_disclaimer)}</h6>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">ASBA Details</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <h6 style={{ textDecoration: "underline" }}>
                                      <img src="../../assets/images/download.svg" style={{ width: "12px", marginBottom: "2px", paddingRight: "5px" }} />
                                      Document.pdf
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  mb-4 col-sm-6 col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="m-0 header">Terms & Conditions Link</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <h6 style={{ textDecoration: "underline" }}>
                                      <img src="../../assets/images/link.svg" style={{ width: "12px", marginBottom: "2px", paddingRight: "5px" }} />
                                      https://www.dummylink.org/dummy.html
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Calculater handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} bondID={bondID} bondsDetail={bondDetails} isin={isin} setCalcudata={setCalcudata} />
      <ConfirmationModal
        contents={confirmContent}
        details_datas={all_datas}
        currentPage={confirmPage}
        openModal={open_modal}
        handleClose={(e: any) => handleConfirmationClosed(e)}
        user_id={passing_value.bondID}
        reloadlist={setCalcudata}
        setIssave={setIssave}
        listReload={(e: any) => {
          // tabReload(e);
          if (e === 'active_bonds') {
            navigate(-1);
          }
          reset();
          // setactivatePublishMode(true);
        }}
        
      />
    </div>
  );
};

export default BondPriceDetailView;
