import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface FormParameters {
  contents: string;
  openModal: any;
  handleClose: any;
}

const SuccessFailureModel: React.FC<FormParameters> = ({ contents, openModal, handleClose }) => {
  useEffect(() => {
    if (openModal) {
      const timeoutId = setTimeout(() => {
        handleClose();
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [openModal]);

  return (
    <div>
      <Dialog open={openModal} maxWidth="xs" className="dialog-box" onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ padding: "30px" }}>
            <img src="../assets/images/success.png" style={{ display: "block", margin: "0px auto" }}></img>
            <h3 className="text-center">Success !</h3>
            <p className="box-content m-0 text-center">{`CreditAccess Grameen Limited (${contents}) bond is now active.`}</p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SuccessFailureModel;
