import { createContext, useContext, useReducer } from 'react';

const initialState = {
    showPreloader: false,
    activeColor: "",
    activeMenuColor: "",
    activeHeadMenuColor: "",
    activeDealerTab:"",
    updateStatus: false
};
const AppStateContext = createContext(initialState);

export const StateActionTypes = {
    SET_SHOW_PRELOADER: "SET_SHOW_PRELOADER",
    SET_ACTIVE_MENU_COLOR: "SET_ACTIVE_MENU_COLOR",
    SET_ACTIVE_HEAD_MENU_COLOR: "SET_ACTIVE_HEAD_MENU_COLOR",
    SET_ACTIVE_DEALER_TAB: "SET_ACTIVE_DEALER_TAB",
    SET_UPDATE_STATUS: "SET_UPDATE_STATUS"
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case StateActionTypes.SET_SHOW_PRELOADER:
            return {
                ...state, showPreloader: action.showPreloader
            }
        case StateActionTypes.SET_ACTIVE_COLOR:
            return {
                ...state, activeColor: action.activeColor
            }
        case StateActionTypes.SET_ACTIVE_MENU_COLOR:
            return {
                ...state, activeMenuColor: action.activeMenuColor
            }
        case StateActionTypes.SET_ACTIVE_HEAD_MENU_COLOR:
            return {
                ...state, activeHeadMenuColor: action.activeHeadMenuColor
            }
        case StateActionTypes.SET_ACTIVE_DEALER_TAB:
            return {
                ...state, activeDealerTab: action.activeDealerTab
            }
        case StateActionTypes.SET_UPDATE_STATUS:
            return {
                ...state, updateStatus: action.updateStatus
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}

export const AppStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppStateContext.Provider value={{ state, dispatch }}>
            {children}
        </AppStateContext.Provider>
    )
}

export const useAppState = () => useContext(AppStateContext);