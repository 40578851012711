import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { PageLinks } from '../common/Constants';
import ConfirmationModal from './Confirmation-modal';
import { useAppStateAPI } from '../common/AppStateAPI';

interface modalDatas {
    openModal?: any,
    handleClose?: any,
    handleOpen?: any,
    userId?: any,
    // currentPage?:any
    // handleOpen
    reload?:any
}

const AccountStatus: React.FC<modalDatas> = ({ handleOpen, userId, openModal, handleClose,reload }) => {

    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false);
    const { setUpdateAccountStatus } = useAppStateAPI();
    const [all_datas, setAllDatas] = useState({
        // userId:userId,
        user_status: 2,
        account_status_reason: "Lorem ipsum dolor sit amet consectetur adipisicing elit 1.",
        account_status_remarks: ""
    })
    // const [select_status, setSelectStatus] = useState<any>(1)

    useEffect(() => {
        if (openModal === true) {
            setOpen(true);
        } else {
            setOpen(false);
        }

    }, [openModal])

    const handleCloses = () => {
        setOpen(false);
        // setOpenModal(false)
        handleClose(false);
    };
    const handleClosed = (e: any) => {
        setOpenModal(e)
        setOpen(e);
        handleClose(e);

    }

    const update_status = (e: any) => {

        setAllDatas({
            ...all_datas,
            [e.target.name]: e.target.value
        })
    }

    const save_modal = () => {
        setOpen(false);
        setOpenModal(true)
        handleClose(false)
    }

    const handleReload = (e:any) => {
        if(e === 'success'){
            setUpdateAccountStatus(true);
            reload(true);
        }
    }

    return (
        <div className='dialog'>
            <Dialog
                open={open}
                className="dialog-box"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" className="model-title">
                    <div className="modal-box-title">
                        <p className="m-0 quicksand-medium">Account Status</p>
                        <p className="m-0" onClick={handleCloses}><i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i></p>
                    </div>
                </DialogTitle>
                <hr />

                <DialogContent>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <form>
                            <div className='row'>

                                <div className='col-lg-12 col-md-12 col-sm-12 mb-2'>
                                    <label className='quicksand-medium'>Status</label>
                                    <select className='select-style' name='user_status' onChange={(e) => update_status(e)}>
                                        {/* <option value={0} selected>Select</option> */}
                                        <option value={2}>Active</option>
                                        <option value={4}>Closed</option>
                                        <option value={1}>Register</option>

                                    </select>
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 mb-2'>
                                    <label className='quicksand-medium'>Select Reason</label>
                                    <select className='select-style' placeholder='Select From drop down' name="account_status_reason" onChange={(e) => update_status(e)}>
                                        <option disabled> Select From drop down</option>
                                        <option value="Lorem ipsum dolor sit amet consectetur adipisicing elit 1." > Lorem ipsum dolor sit amet consectetur adipisicing elit 1.</option>
                                        <option value="Lorem ipsum dolor sit amet consectetur adipisicing elit 2." >Lorem ipsum dolor sit amet consectetur adipisicing elit 2.</option>
                                        <option value="Lorem ipsum dolor sit amet consectetur adipisicing elit 3." >Lorem ipsum dolor sit amet consectetur adipisicing elit 3.</option>

                                    </select>
                                </div>

                                <div className='col-lg-12 col-md-12 col-sm-12'>
                                    <label className='quicksand-medium'>Remarks</label>
                                    <textarea className=' form-control form-control-style' name='account_status_remarks' onChange={(e) => update_status(e)} rows={4} cols={50} maxLength={100} placeholder="100 words max*" />
                                </div>
                                <div className='col-lg-12 col-md-12 col-sm-12 mx-3 my-3 py-2 px-2 d-flex justify-content-around align-items-center'>
                                    <a className='cancel-btn' onClick={handleCloses} >Cancel</a>
                                    <a className='save-btn' onClick={() => save_modal()}>Submit</a>
                                </div>
                            </div>
                        </form>
                    </div>

                </DialogContent>


            </Dialog>
            <ConfirmationModal contents="Are you sure you want to confirm this?" details_datas={all_datas} currentPage="update-status" openModal={open_modal} handleClose={(e: any) => handleClosed(e)} user_id={userId} listReload={(e:any)=>handleReload(e)}/>

        </div>
    );
}

export default AccountStatus;