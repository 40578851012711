import Pagination from '@mui/material/Pagination';
import { Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { BondIPOStatus, Depository, NCDDetailsList, NCDStatus, PageLinks, ShortTermList, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData, FormDataTypes } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { InitialData } from '../common/InitialValue';
import BondDetails from './BondDetails';
import toast from 'react-hot-toast';
import { DropZone, frameSelectOptions, getURLExtension, localStringToIST } from '../common/Utilities';
import { useDropzone } from 'react-dropzone';
import { saveAs } from 'file-saver';

var dmp: string = '';
var dmpIndex: number = 0;

const IPOAdd: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value)

    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false)
    const [userList, setUserList] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.IPOAdd>(InitialData.IPOAdd);
    const [shortTermList, setShortTermList] = useState<any[]>(ShortTermList);

    const [addDisclamer, setAddDisclamer] = useState<any | undefined>(EditorState.createEmpty());
    const [addDisclamerText, setAddDisclamerText] = useState("");
    const [addKeyHeighlights, setAddKeyHeighlights] = useState<any | undefined>(EditorState.createEmpty());
    const [addIssuerAbout, setAddIssuerAbout] = useState<any | undefined>(EditorState.createEmpty());

    const formikRef = useRef<FormikProps<APIData.IPOAdd>>(null);
    console.log("🚀 ~ file: IPOAdd.tsx:66 ~ formikRef:", formikRef)
    const [uploadFeild, setUploadFeild] = useState<{ feild_type: string, inx: number }>({ feild_type: '', inx: 0 });
    const [bondID, setBondID] = useState(0);
    const [selectedBrandTag, setSelectedBrandTag] = useState<APIData.BondBrandTags[]>([]);
    const [bondRatingOptions, setBondRatingOptions] = useState<FormDataTypes.SelectOption[]>([]);
    const [dynamicText, setDynamicText] = useState('');
    const [Highlight, setHighlight] = useState('');


    useEffect(() => {
        dmp = uploadFeild.feild_type;
        dmpIndex = uploadFeild.inx;
    }, [uploadFeild])

    const onDisclamerEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddDisclamer(editorState);
        // setAddDisclamerText(htmlResult);
        setFormikInitialData(({ ...formikInitialData, bond_disclaimer: htmlResult }));
    };
    useEffect(() => {
        // Fetch your existing data here and convert it to ContentState
        const existingData = {
            blocks: [
                {
                    key: 'abcde',
                    text: dynamicText,
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                },
            ],
            entityMap: {},
        };
        const contentState = convertFromRaw(existingData);

        // Set the converted contentState as the initial editorState
        setAddDisclamer(EditorState.createWithContent(contentState));
        setFormikInitialData({ ...formikInitialData });
    }, [dynamicText]); // Empty dependency array, runs only once on mount

    const onKeyHeighlightsEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddKeyHeighlights(editorState);
        setFormikInitialData(({ ...formikInitialData, bond_key_highlights: htmlResult }));
    };
    useEffect(() => {
        // Fetch your existing data here and convert it to ContentState
        const existingData = {
            blocks: [
                {
                    key: 'abcde',
                    text: Highlight,
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {},
                },
            ],
            entityMap: {},
        };
        const contentState = convertFromRaw(existingData);

        // Set the converted contentState as the initial editorState
        setAddKeyHeighlights(EditorState.createWithContent(contentState));
        setFormikInitialData({ ...formikInitialData });
    }, [Highlight]); // Empty dependency array, runs only once on mount

    const onIssuerAboutEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddIssuerAbout(editorState);
        // setFormikInitialData(({...formikInitialData, bond_issuer_details.: htmlResult}));
    };

    function DateIcon() { return (<SvgIcon viewBox="0 0 72.872 83.283" style={{ padding: "2px" }}><path id="Path_27181" data-name="Path 27181" d="M-1078.15,955.569v7.808h-72.872v-7.808a7.809,7.809,0,0,1,7.808-7.808h7.807v-7.808a2.61,2.61,0,0,1,2.6-2.6h5.205a2.61,2.61,0,0,1,2.6,2.6v7.808h20.82v-7.808a2.609,2.609,0,0,1,2.6-2.6h5.2a2.609,2.609,0,0,1,2.6,2.6v7.808h7.808A7.81,7.81,0,0,1-1078.15,955.569Zm-72.872,13.013h72.872v44.243a7.811,7.811,0,0,1-7.808,7.809h-57.256a7.81,7.81,0,0,1-7.808-7.809Zm10.41,18.869a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.821-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.82-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Z" transform="translate(1151.022 -937.351)" /></SvgIcon>); }
    // const [selectedItems, setSelectedItems] = React.useState<any[]>([]);
    const [openingDate, setOpeningDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [closingDate, setClosingDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [allotmentDate, setAllotmentDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [firstIPDate, setFirstIPDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [maturityDate, setMaturityDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));
    const [couponDate, setCouponDate] = React.useState<Dayjs | null>(dayjs(moment().format('YYYY-MM-DDTHH:mm:ss')));

    const handleChangeMaturityDate = (newValue: Dayjs | null) => {
        setMaturityDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_maturity_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };
    const handleChangeCouponDate = (newValue: Dayjs | null) => {
        setCouponDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_coupon_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };

    const handleChangeOpeningDate = (newValue: Dayjs | null) => {
        setOpeningDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_opening_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };
    const handleChangeClosingDate = (newValue: Dayjs | null) => {
        const close_date: any = handleCalculateDay(newValue?.format('YYYY-MM-DD').toString());
        if (close_date != undefined && close_date == 'true') {
            setClosingDate(newValue);
            setFormikInitialData(({ ...formikInitialData, bond_closing_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
        } else {
            toast.error('Please select date greater than opening date');
        }
    };

    const handleChangeAllotmentDate = (newValue: Dayjs | null) => {
        const allo_date: any = handleCalculateAllocateDate(newValue?.format('YYYY-MM-DD').toString());
        if (allo_date != undefined && allo_date == 'true') {
            setAllotmentDate(newValue);
            setFormikInitialData(({ ...formikInitialData, bond_allotment_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
        } else {
            toast.error('Please select date greater than closing date');
        }
    };

    const handleChangeFirstIPDate = (newValue: Dayjs | null) => {
        setFirstIPDate(newValue);
        setFormikInitialData(({ ...formikInitialData, bond_ip_date: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));
    };

    useEffect(() => {
        if (location.state.mode && location.state.mode == 'add') {
            loadBrandTags();
            loadEligibleInvestor();
        }
        if (location.state.mode && location.state.mode == 'edit') {
            loadIntitalIPO(location.state.id);
        }
        loadBondRatingList();
        console.log("ipo:" + formikInitialData);
    }, [])

    useEffect(() => {
        console.log(formikInitialData);
    }, [formikInitialData])


    const loadBondRatingList = () => {
        setShowPreloader(true);
        ServerAPI.getBondRatingList().then((res) => {
            try {
                let Options = frameSelectOptions(res.message, 'bond_rating_list_id', 'bond_rating_name');
                setBondRatingOptions(Options);
            } catch (error) {
                toast.error(res.error)
            }
        }).finally(() => {
            setShowPreloader(false)
        })
    }

    const loadIntitalIPO = (id: number) => {
        if (id) {
            setShowPreloader(true);
            ServerAPI.IPODetail(id).then((res) => {
                setDynamicText(res?.bond_disclaimer)
                setHighlight(res?.bond_key_highlights)
                setBondID(res.bond_id);
                if (res?.bond_issuer_details == "") {
                    setFormikInitialData((e) => ({
                        ...res,
                        bond_issuer_details: [InitialData.BondIssuerDetails],
                    }))
                } else {

                    setFormikInitialData((e) => ({
                        ...res
                    }));
                }
            }).finally(() => {
                setShowPreloader(false)
            })
        }
    }

    const loadBrandTags = () => {
        setShowPreloader(true);
        ServerAPI.getBrandTagsList().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.IPOAdd = ({ ...formikRef.current.values });
                if ((currValues.bond_brand_tags).length == 0) {
                    res.brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
                        currValues.bond_brand_tags.push({
                            bond_brand_tag_available: tags.bond_brand_tag_available,
                            brand_tag_id: tags.brand_tag_id,
                            brand_tag_name: tags.brand_tag_name,
                            bond_brand_tag_id: null,
                            brand_tag_status: tags.brand_tag_status
                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
            // if (selectedBrandTag && selectedBrandTag.length == 0) {
            //     res.brand_tags.map((tags: APIData.BondBrandTags, index: number) => {
            //         selectedBrandTag.push({
            //             brand_tag_id: null,
            //             brand_tag_name: tags.brand_tag_name,
            //             bond_brand_tag_available: tags.bond_brand_tag_available ? tags.bond_brand_tag_available : 0
            //         })
            //     })
            //     setSelectedBrandTag([...selectedBrandTag]);
            // }
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const loadEligibleInvestor = () => {
        setShowPreloader(true);
        ServerAPI.getEligibleInvestorList().then((res) => {
            if (formikRef.current?.values) {
                var currValues: APIData.IPOAdd = ({ ...formikRef.current.values });
                if ((currValues.bond_eligible_investors).length == 0) {
                    res.eligible_investors.map((inv: APIData.BondEligibleInvestors, index: number) => {
                        currValues.bond_eligible_investors.push({
                            eligible_investors_available: inv.eligible_investors_available,
                            eligible_investor_id: inv.eligible_investor_id,
                            eligible_investor_name: inv.eligible_investor_name,
                            bond_eligible_investor_id: null,
                eligible_investor_status: inv.eligible_investor_status

                        })
                    })
                    setFormikInitialData(({ ...formikInitialData }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })

    }

    const updateBrandTags = (checked: boolean, index: number) => {
        console.log(checked)
        console.log(index)
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = ({ ...formikRef.current.values });
            currValues.bond_brand_tags.map((tags: APIData.BondBrandTags) => {
                if (currValues.bond_brand_tags.filter((e, i) => i == index).length == 1) {
                    currValues.bond_brand_tags[index].bond_brand_tag_available = checked == true ? 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
        // if (selectedBrandTag) {
        //     selectedBrandTag.map((tags: APIData.BondBrandTags, inx: number) => {
        //         selectedBrandTag[index].brand_tag_id = checked == true ? index : null;
        //     })
        //     setSelectedBrandTag([...selectedBrandTag]);
        // }
    }
    const updateEligibleTags = (checked: boolean, index: number) => {
        console.log(checked)
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = ({ ...formikRef.current.values });
            currValues.bond_eligible_investors.map((tags: APIData.BondEligibleInvestors) => {
                if (currValues.bond_eligible_investors.filter((e, i) => i == index).length == 1) {
                    currValues.bond_eligible_investors[index].eligible_investors_available = checked == true ? 1 : null;
                    // currValues.bond_brand_tags[index].brand_tag_checked = !checked
                }
            })
            setFormikInitialData(({ ...formikInitialData }))
        }
    }


    useEffect(() => {
        // suspenist();
    }, [pageNumber, search_text, toData, fromDate, preData, open])


    const suspended_list = () => {
        setShowPreloader(true);
        ServerAPI.SuspentedUserList(pageNumber, search_text, toData, fromDate).then((res) => {
            setUserList(res.datas);
            setTotalCount(res.total_count);

        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const onUpdateNCDAvailable = (checked: boolean, type: string) => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = ({ ...formikRef.current.values });
            if (type == 'bond_ncd_frequency_of_interest_payment') {
                currValues.bond_ncd_available[0].bond_ncd_frequency_of_interest_payment = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_minimum_application') {
                currValues.bond_ncd_available[0].bond_ncd_minimum_application = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_in_multiple_terms_of_thereafter') {
                currValues.bond_ncd_available[0].bond_ncd_in_multiple_terms_of_thereafter = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_issue_price_of_ncd') {
                currValues.bond_ncd_available[0].bond_ncd_issue_price_of_ncd = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_tenor') {
                currValues.bond_ncd_available[0].bond_ncd_tenor = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_coupon') {
                currValues.bond_ncd_available[0].bond_ncd_coupon = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_effective_yield') {
                currValues.bond_ncd_available[0].bond_ncd_effective_yield = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_mode_of_interest_payment') {
                currValues.bond_ncd_available[0].bond_ncd_mode_of_interest_payment = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_redemption_amount') {
                currValues.bond_ncd_available[0].bond_ncd_redemption_amount = checked == true ? 1 : 0;
            } else if (type == 'bond_ncd_put_and_option') {
                currValues.bond_ncd_available[0].bond_ncd_put_and_option = checked == true ? 1 : 0;
            }
        }
        setFormikInitialData(e => ({ ...e, currValues }));
    }

    const onUpdateNCDSeries = (value: any, type: string, index: number) => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = ({ ...formikRef.current.values });
            if (type == 'bond_ncd_frequency_of_interest_payment') {
                currValues.bond_ncd_series[index].bond_ncd_frequency_of_interest_payment = value;
            } else if (type == 'bond_ncd_minimum_application') {
                currValues.bond_ncd_series[index].bond_ncd_minimum_application = value;
            } else if (type == 'bond_ncd_in_multiple_terms_of_thereafter') {
                currValues.bond_ncd_series[index].bond_ncd_in_multiple_terms_of_thereafter = value;
            } else if (type == 'bond_ncd_issue_price_of_ncd') {
                currValues.bond_ncd_series[index].bond_ncd_issue_price_of_ncd = value;
            } else if (type == 'bond_ncd_tenor') {
                currValues.bond_ncd_series[index].bond_ncd_tenor = value;
            } else if (type == 'bond_ncd_coupon') {
                currValues.bond_ncd_series[index].bond_ncd_coupon = value;
            } else if (type == 'bond_ncd_effective_yield') {
                currValues.bond_ncd_series[index].bond_ncd_effective_yield = value;
            } else if (type == 'bond_ncd_mode_of_interest_payment') {
                currValues.bond_ncd_series[index].bond_ncd_mode_of_interest_payment = value;
            } else if (type == 'bond_ncd_redemption_amount') {
                currValues.bond_ncd_series[index].bond_ncd_redemption_amount = value;
            } else if (type == 'bond_ncd_put_and_option') {
                currValues.bond_ncd_series[index].bond_ncd_put_and_option = value;
            }
        }
        setFormikInitialData(({ ...formikInitialData }));
    }

    const onClearNCDSeries = () => {
        setFormikInitialData(({ ...formikInitialData, bond_ncd_series: [] }));
    }

    const updateNDCSeriesList = () => {
        let initial_data = JSON.stringify(InitialData.BondNcdSeries);
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = ({ ...formikRef.current.values });
            if (currValues.bond_ncd_series.length >= 6) {
                toast.error('Max 6 series value is allowed');
            } else {
                if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_frequency_of_interest_payment == '' || e.bond_ncd_frequency_of_interest_payment == null).length != 0) {
                    toast.error('Please fill ncd frequency of interest payment')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_minimum_application == 0).length != 0) {
                    toast.error('Please fill minimum application')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_in_multiple_terms_of_thereafter == 0).length != 0) {
                    toast.error('Please fill multiple thereafter')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_issue_price_of_ncd == '' || e.bond_ncd_issue_price_of_ncd == null).length != 0) {
                    toast.error('Please fill ncd issue price of ncd')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_tenor == 0).length != 0) {
                    toast.error('Please fill tenor')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_coupon == '' || e.bond_ncd_coupon == null).length != 0) {
                    toast.error('Please fill ncd coupon')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_effective_yield == '' || e.bond_ncd_effective_yield == null).length != 0) {
                    toast.error('Please fill ncd effective yeild')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_mode_of_interest_payment == '').length != 0) {
                    toast.error('Please fill interest payment')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_redemption_amount == '' || e.bond_ncd_redemption_amount == null).length != 0) {
                    toast.error('Please fill ncd redemption amount')
                } else if (currValues.bond_ncd_series.filter((e) => e.bond_ncd_put_and_option == 0).length != 0) {
                    toast.error('Please fill put and call options')
                } else {
                    currValues.bond_ncd_series.push(JSON.parse(initial_data));
                }
            }
        }
        setFormikInitialData(({ ...formikInitialData }));
    }

    const updateShortTermList = () => {
        // let updatedList = NDCList;
        // setNDCList(prev => ({ ...prev, header: [...prev, { name: 'Serial 2' }] }))
    }

    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
    }

    const handleClose_modal = (e: any) => {
        setOpenModal(e);
        // suspended_list();
    }

    const activate_user = (id: any) => {
        setOpenModal(true);
        setUserId(id);
    }

    const view_more_content = (e: any) => {
        setViewContent(e)
        setOpen(true)
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerInputFeildStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    /* Document upload */
    // const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
    //     acceptedFiles.map((file: any) => {
    //         console.log(file)
    //         loadUploadedfile(file);
    //     });
    //     fileRejections.forEach((file: any) => {
    //         file.errors.forEach((err: any) => {
    //             if (err.code === "dimension") {
    //                 toast.error(`Error: ${err.message}`);
    //             }
    //         });
    //     });

    // }, [])
    // const { getRootProps, getInputProps } = DropZone(onDrop);
    /* Exce */
    const onDrop = useCallback((acceptedFiles: File[], fileRejections: any[]) => {
        acceptedFiles.map((file: any) => {
            loadUploadedfile(file);
        });
        fileRejections.forEach((file: any) => {
            file.errors.forEach((err: any) => {
                if (err.code === "dimension") {
                    toast.error(`Error: ${err.message}`);
                }
            });
        });

    }, [])
    const { getRootProps, getInputProps } = useDropzone(
        {
            onDrop,
            multiple: true
        }
    );

    const loadUploadedfile = (file: any) => {
        setShowPreloader(true);
        ServerAPI.setUploadedFile(file).then(response => {
            if (response.filename != undefined && response.filename != "") {
                console.log(dmp);
                if (formikRef.current?.values) {
                    var currValues: APIData.IPOAdd = (formikRef.current.values);
                    if (dmp != undefined && dmp == 'bond_asba_details') {
                        currValues.bond_asba_details = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_logo') {
                        currValues.bond_logo = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_category_instructions') {
                        currValues.bond_category_instructions = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_sub_category_code') {
                        currValues.bond_sub_category_code = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_app_no_series') {
                        currValues.bond_app_no_series = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_series_instructions') {
                        currValues.bond_series_instructions = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_product_note') {
                        currValues.bond_product_note = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_listing_circle') {
                        currValues.bond_listing_circle = response.filename;
                    } else if (dmp != undefined && dmp == 'bond_brokerage_structure') {
                        currValues.bond_brokerage_structure = response.filename;
                    } else if (dmp !== undefined && dmp == 'bond_rating_certificate') {
                        currValues.bond_rating_details[dmpIndex].bond_rating_certificate = response.filename;
                    } else if (dmp !== undefined && dmp == 'bond_issuer_documents') {
                        currValues.bond_issuer_details[0].bond_issuer_documents = response.filename;
                    }
                    setFormikInitialData(e => ({ ...e, currValues }))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        });
    }

    const addBonCallDetail = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
            if (currValues.bond_call_details.filter((e) => e.bond_call_detail_amount == '' || e.bond_call_detail_amount == null).length == 0) {
                currValues.bond_call_details.push({ bond_call_detail_id: 0, bond_call_detail_datetime: moment().format('YYYY-MM-DD'), bond_call_detail_amount: '' });
            } else {
                toast.error('Please fill call amount feild');
            }
            setFormikInitialData(currValues);
        }
    }

    const removeBonCallDetail = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_call_details.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_call_details: paramValues });
        }
    }

    const addBondPutDetail = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
            if (currValues.bond_put_details.filter((e) => e.bond_put_detail_amount == '' || e.bond_put_detail_amount == null).length == 0) {
                currValues.bond_put_details.push({ bond_put_detail_id: 0, bond_put_detail_datetime: moment().format('YYYY-MM-DD'), bond_put_detail_amount: '' });
            } else {
                toast.error('Please fill put amount feild');
            }
            setFormikInitialData(currValues);
        }
    }

    const removeBondPutDetail = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_put_details.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_put_details: paramValues });
        }
    }

    const addBondRating = () => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
            if (currValues.bond_rating_details.filter((e) => e.bond_rating_agency == '' || e.bond_rating == 0 || e.bond_rating_certificate == '').length == 0) {
                currValues.bond_rating_details.push({ bond_rating_agency: '', bond_rating: 0, bond_rating_certificate: '' });
            } else {
                toast.error('Please fill rating feild');
            }
            setFormikInitialData(currValues);
        }
    }

    const removeBondRating = (removeIndex: any) => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_rating_details.filter((paramValue, index) => index !== removeIndex);
            setFormikInitialData({ ...formikInitialData, bond_rating_details: paramValues });
        }
    }

    const handleRemoveNCDseries = (removeIndex: number) => {
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
            let paramValues = currValues.bond_ncd_series.filter((paramValue, index) => index !== removeIndex);
            console.log(paramValues)
            if (paramValues.length != 0) {
                setFormikInitialData(e => ({ ...e, bond_ncd_series: paramValues }));
            } else {
                toast.error('Series can not be empty');
            }
        }
    }

    const deepCopyIPO = (IPO: APIData.IPOAdd) => {
        let returnIPOAdd: APIData.IPOAdd = { ...IPO };
        if (IPO.bond_call_details !== undefined) {
            returnIPOAdd.bond_call_details = [...IPO.bond_call_details];
        } else {
            returnIPOAdd.bond_call_details = [];
        }
        if (IPO.bond_put_details !== undefined) {
            returnIPOAdd.bond_put_details = [...IPO.bond_put_details];
        } else {
            returnIPOAdd.bond_put_details = [];
        }
        if (IPO.bond_brand_tags !== undefined) {
            returnIPOAdd.bond_brand_tags = [...IPO.bond_brand_tags];
        } else {
            returnIPOAdd.bond_brand_tags = [];
        }
        if (IPO.bond_ncd_series !== undefined) {
            returnIPOAdd.bond_ncd_series = [...IPO.bond_ncd_series];
        } else {
            returnIPOAdd.bond_ncd_series = [];
        }
        return returnIPOAdd;
    }

    const handleCalculateDay = (value: any) => {
        if (formikInitialData.bond_opening_date != '') {
            var specific_date = new Date(value);
            var current_date = new Date(formikInitialData.bond_opening_date);
            if (specific_date.getTime() > current_date.getTime()) {
                return 'true'
            } else {
                return 'false'
            }
        } else {
            toast.error('Please select the issue date')
        }
    }
    const handleCalculateAllocateDate = (value: any) => {
        if (formikInitialData.bond_closing_date != '') {
            var allocate_date = new Date(value);
            var close_date = new Date(formikInitialData.bond_closing_date);
            if (allocate_date.getTime() > close_date.getTime()) {
                return 'true'
            } else {
                return 'false'
            }
        } else {
            toast.error('Please select the close date')
        }
    }

    const handleDownload = () => {
        fetch('../../assets/files/asba_bank_location.xlsx')
            .then(response => response.blob())
            .then(blob => saveAs(blob, 'asba_bank_location.xlsx'))
    }

    const yupSchema = Yup.object().shape({
        bond_issuer_name: Yup.string().required('Issuer Name is required'),
        // bond_status: Yup.string().notOneOf(["0"], Strings.ValidationSelect).required(Strings.ValidationRequired),
        bond_form_start_number: Yup.number().typeError('you must specify a number').min(1, 'Min value 1.').required(Strings.ValidationRequired),
        bond_form_end_number: Yup.number().typeError('you must specify a number').min(1, 'Min value 1.').required(Strings.ValidationRequired),
        bond_category_institutional: Yup.number().max(99, 'Institutional cannot be more than 2 digits').typeError('you must specify a number'),
        bond_category_non_institutional: Yup.number().max(99, 'Non Institutional cannot be more than 2 digits').typeError('you must specify a number'),
        bond_category_hni: Yup.number().max(99, 'HNI cannot be more than 2 digits').typeError('you must specify a number'),
        bond_category_retail: Yup.number().max(99, 'Retail cannot be more than 2 digits').typeError('you must specify a number'),
        bond_asba_details: Yup.string().required('ASBA Detail required'),
        // bond_early_bid_incentive: Yup.string().required('Early bid incentive is required'),
        // bond_other_incentive: Yup.string().required('Other incentive is required'),
        // bond_e_form_incentive: Yup.string().required('E Form incentive is required'),
        // bond_face_value: Yup.string().required('Face Value is required'),
        // bond_procurement_amount: Yup.string().required('Procurement Amount is required'),
        // bond_category_institutional: Yup.string().required('Category Institutional is required'),
        // bond_category_non_institutional: Yup.string().required('Category Non Institutional is required'),
        // bond_category_hni: Yup.string().required('Category HNI is required'),
        // bond_category_retail: Yup.string().required('Category retail is required'),
        // bond_ncd_series: Yup.array().of(
        //     Yup.object().shape({
        //         bond_ncd_coupon: Yup.string().required('Bond NCD coupon is required').notOneOf(['', '0', 'null']),
        //         bond_ncd_effective_yield: Yup.string().required('Bond NCD Effective yeild is required').notOneOf(['', '0', 'null']),
        //         bond_ncd_frequency_of_interest_payment: Yup.string().required('Bond NCD frequency of interest payment is required').notOneOf(['', '0', 'null']),
        //         bond_ncd_in_multiple_terms_of_thereafter: Yup.number().required('Bond NCD frequency of interest payment is required').notOneOf([NaN, 0]),
        //         bond_ncd_issue_price_of_ncd: Yup.string().required('Bond NCD frequency of interest payment is required').notOneOf(['', '0', 'null']),
        //         bond_ncd_minimum_application: Yup.number().required('Bond NCD frequency of interest payment is required').notOneOf([NaN, 0]),
        //         bond_ncd_mode_of_interest_payment: Yup.string().required('Bond NCD frequency of interest payment is required').notOneOf(['', '0', 'null']),
        //         bond_ncd_put_and_option: Yup.number().required('Bond NCD frequency of interest payment is required').notOneOf([NaN, 0]),
        //         bond_ncd_redemption_amount: Yup.string().required('Bond NCD frequency of interest payment is required').notOneOf(['', '0', 'null']),
        //     })
        // )

    });

    const submitData = (data: any, setSubmitting: any) => {
        // console.log(data.bond_name);
        // console.log(data.bond_isin_number);
        if (formikRef.current?.values) {
            var currValues: APIData.IPOAdd = deepCopyIPO(formikRef.current.values);
            let dataValid = currValues.bond_ncd_series.filter((e, index) =>
            ((e.bond_ncd_coupon != '' && e.bond_ncd_coupon != null && e.bond_ncd_coupon != 0) &&
                (e.bond_ncd_effective_yield != '' && e.bond_ncd_effective_yield != null && e.bond_ncd_effective_yield != 0) &&
                (e.bond_ncd_frequency_of_interest_payment != '' && e.bond_ncd_frequency_of_interest_payment != null && e.bond_ncd_frequency_of_interest_payment != 0) &&
                (!Number.isNaN(e.bond_ncd_in_multiple_terms_of_thereafter) && e.bond_ncd_in_multiple_terms_of_thereafter != 0) &&
                (e.bond_ncd_issue_price_of_ncd != '' && e.bond_ncd_issue_price_of_ncd != null && e.bond_ncd_issue_price_of_ncd != 0) &&
                (!Number.isNaN(e.bond_ncd_minimum_application) && e.bond_ncd_minimum_application != 0) &&
                (e.bond_ncd_mode_of_interest_payment != '' && e.bond_ncd_mode_of_interest_payment != null && e.bond_ncd_mode_of_interest_payment != '0') &&
                (e.bond_ncd_put_and_option != 0) &&
                (e.bond_ncd_redemption_amount != '' && e.bond_ncd_redemption_amount != null && e.bond_ncd_redemption_amount != 0) &&
                (!Number.isNaN(e.bond_ncd_tenor) && e.bond_ncd_tenor != null))
            )
            if (dataValid.length == currValues.bond_ncd_series.length) {
                if (location.state.mode == 'edit') {
                    setShowPreloader(true);
                    ServerAPI.putIPO(data).then((res) => {
                        try {
                            toast.success(res.message);
                            navigate(PageLinks.BOND_LIST, { state: res.id });
                            setSubmitting(false);
                            if (res.message) {
                                window.location.reload();
                            }
                        } catch (error) {
                            toast.success(res.errors);
                        }
                    }).finally(() => {
                        window.location.reload();
                        setShowPreloader(false);
                    })
                } else {
                    setShowPreloader(true);
                    ServerAPI.postIPO(data).then((res) => {
                        try {
                            toast.success(res.message);
                            navigate(PageLinks.BOND_LIST, { state: res.id });
                            setSubmitting(false);
                            if (res.message) {
                                window.location.reload();
                            }
                        } catch (error) {
                            toast.success(res.errors);
                        }
                    }).finally(() => {
                        window.location.reload();
                        setShowPreloader(false);
                    })
                }
            } else {
                toast.error('Please enter all feilds in ncd series values')
            }
        }

    }


    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item"><a> Master </a></li>
                                                <li className="breadcrumb-item active ">{location.state?.mode != undefined && location.state.mode == 'edit' ? 'Edit' : 'Add'} IPO Bond</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    values.bond_type = 1;
                                                    values.bond_id = location.state.mode == 'edit' ? bondID : 0;
                                                    if (values.bond_maturity_date == moment().format('YYYY-MM-DD')) {
                                                        toast.error('Please select the date greater than today for bond maturity date');
                                                        setSubmitting(true);
                                                    } else if (values.bond_closing_date == moment().format('YYYY-MM-DD')) {
                                                        toast.error('Please select the closing date greater than opening date');
                                                        setSubmitting(true);
                                                    } else {
                                                        submitData(values, setSubmitting);
                                                    }
                                                }}
                                            >{(formikProps) => {
                                                // { console.log(formikProps.handleSubmit) }
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> {location.state?.mode != undefined && location.state.mode == 'edit' ? 'Edit' : 'Add'} New IPO Bond </h5>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <label className="form-check-label" htmlFor="distributorSubTypeChecked">Disable/Enable</label>
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="distributorSubTypeChecked"
                                                                                onChange={(e) => { setFormikInitialData(({ ...formikInitialData, bond_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                checked={formikProps.values?.bond_status == BondIPOStatus.Active ? true : false} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_issuer_name" className='bond-label'>Issuer Name</label>
                                                                                    <input id="bond_issuer_name" name="bond_issuer_name" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_issuer_name: e.target.value }))}
                                                                                        value={formikProps.values?.bond_issuer_name} />
                                                                                    {/* {formikProps.errors.bond_issuer_name ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_issuer_name}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_name" className='bond-label'>Bond Name</label>
                                                                                    <input id="bond_name" name="bond_name" type="text" className='bond-input' onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_name: e.target.value })); console.log(e.target.value) }} value={formikProps.values?.bond_name} />
                                                                                    {/* <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "Bond Name *", name: "bond_name" }} /> */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_form_start_number" className='bond-label'>Application Start No.</label>
                                                                                    <input id="bond_form_start_number" name="bond_form_start_number" type="number" className='bond-input'
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_form_start_number: parseInt(e.target.value) })); console.log(e.target.value) }}
                                                                                        value={formikProps.values?.bond_form_start_number} />
                                                                                    {formikProps.errors.bond_form_start_number ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_form_start_number}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_form_end_number" className='bond-label'>Application End No.</label>
                                                                                    <input id="bond_form_end_number" name="bond_form_end_number" type="number" className='bond-input'
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_form_end_number: parseInt(e.target.value) })); console.log(e.target.value) }}
                                                                                        value={formikProps.values?.bond_form_end_number} />
                                                                                    {formikProps.errors.bond_form_end_number ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_form_end_number}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Nature of Instrument" className='bond-label'>Nature of Instrument</label>
                                                                                    <select id="Nature of Instrument" name="bond_nature_of_instrument"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_nature_of_instrument: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_nature_of_instrument}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Taxable</option>
                                                                                        <option value={2}>Tax Free</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_isin_number" className='bond-label'>ISIN Number</label>
                                                                                    <input id="bond_isin_number" name="bond_isin_number" type="text" className='bond-input'
                                                                                        value={formikProps.values?.bond_isin_number}
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_isin_number: e.target.value }))}
                                                                                    />
                                                                                    {/* <FormField formik={formikProps} fieldProps={{ fieldType: "textbox", label: "ISIN Number", name: "bond_isin_number" }} /> */}
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_status" className='bond-label'>Our Status</label>
                                                                                    <select id="bond_status" name="bond_status"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_status: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_status}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Open</option>
                                                                                        <option value={2}>Close</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_listing" className='bond-label'>Listing</label>
                                                                                    <select id="bond_listing" name="bond_listing"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_listing: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_listing}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>BSE</option>
                                                                                        <option value={2}>NSE</option>
                                                                                        <option value={3}>Both BSE & NSE</option>
                                                                                        <option value={4}>UNLISTED</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Opening Date</label>
                                                                                    {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={openingDate}
                                                                                        onChange={handleChangeOpeningDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_opening_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_opening_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Closing Date</label>
                                                                                    {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={closingDate}
                                                                                        onChange={handleChangeClosingDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_closing_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_closing_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bonds_yeild" className='bond-label'>Yeild (%)</label>
                                                                                    <input id="bonds_yeild" name="bonds_yeild" type="string" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bonds_yeild: e.target.value }))}
                                                                                        value={formikProps.values?.bonds_yeild} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_face_value" className='bond-label'>Face Value (₹)</label>
                                                                                    <input id="bond_face_value" name="bond_face_value" type="number" className='bond-input'
                                                                                        value={formikProps.values?.bond_face_value}
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_face_value: e.target.value }))}
                                                                                    />
                                                                                    {/* {formikProps.errors.bond_face_value ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_face_value}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_deposit" className='bond-label'>Depository</label>
                                                                                    <select id="bond_deposit" name="bond_deposit"
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_deposit: e.target.value })); }}
                                                                                        value={formikProps.values.bond_deposit}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>National Securities Depository Limited (NSDL)</option>
                                                                                        <option value={2}>Central Depository Services (India) Limited (CDSL)</option>
                                                                                        <option value={3}>Both</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Script ID</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_script_id: e.target.value }))} value={formikProps.values?.bond_script_id} />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid' style={{ marginTop: "7px" }}>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>IPO Sell Window Days</label>
                                                                                    <input id="Issuer Name" name="firstName" type="number" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_ipo_sell_windows_days: parseInt(e.target.value) }))} value={formikProps.values?.bond_ipo_sell_windows_days} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Coupon on</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_on: e.target.value }))} value={formikProps.values?.bond_coupon_on} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Minimum Application (₹)</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_minimum_application: e.target.value }))} value={formikProps.values?.bond_minimum_application} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Issue Size (In crores)</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_issue_size: e.target.value }))} value={formikProps.values?.bond_issue_size} />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Bond Logo</label>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file" onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bond_logo' }))}>{getURLExtension(formikRef.current?.values.bond_logo, 'Upload Bond Logo')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            {/* <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Category Instruction</label>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file" onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bond_category_instructions' }))}>{getURLExtension(formikRef.current?.values.bond_category_instructions, 'Upload Category Instruction')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Sub Category Code</label>
                                                                                    <div {...getRootProps()}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file" onClick={() => setUploadFeild(e => ({ ...e, feild_type: 'bond_sub_category_code' }))}>{getURLExtension(formikRef.current?.values.bond_sub_category_code, 'Upload Sub Category Code')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>App No Series (Distributor)</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_app_no_series' })) } })}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_app_no_series, 'App No Series (Distributor)')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Series Instruction</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_series_instructions' })) } })}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_series_instructions, 'Upload Series Instruction')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid> */}
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Product Note</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_product_note' })) } })}>
                                                                                        <input {...getInputProps()} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_product_note, 'Upload Product Note')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            {/* <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Listing Circle</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_listing_circle' })) } })}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_listing_circle, 'Upload Listing Circle')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Upload Brokerage Structure</label>
                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_brokerage_structure' })) } })}>
                                                                                        <input {...getInputProps()} disabled={true} />
                                                                                        {
                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_brokerage_structure, 'Upload Brokerage Structure')}</label>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid> */}
                                                                            {/* <Grid item xs={12}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>About this</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" className='bond-input' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_about_this_ipo: e.target.value }))} value={formikProps.values?.bond_about_this_ipo} />
                                                                                </div>
                                                                            </Grid> */}


                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_security_type" className='bond-label'>Security Type</label>
                                                                                    <select id="bond_security_type" name="email" disabled={true}
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_security_type: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_security_type}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Private Sector Bond (Non Convertible Debentures)</option>
                                                                                        <option value={2}>PSU Bond/ Government Security </option>
                                                                                        <option value={3}>Fixed Deposit</option>
                                                                                        <option value={4}>54 EC Capital Gains Bonds</option>
                                                                                        <option value={5}>RBI Sovereign Gold BondFloating Rate Savings Bonds</option>
                                                                                        <option value={6}>Corporate Fixed Deposit</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_issue_status" className='bond-label'>Issue Status</label>
                                                                                    <select id="bond_issue_status" name="bond_issue_status" disabled={true}
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_issue_status: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_issue_status}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value="0">Select</option>
                                                                                        <option value="1">Yet to Open</option>
                                                                                        <option value="2">Open</option>
                                                                                        <option value="3">Closed</option>
                                                                                    </select>
                                                                                    {formikProps.errors.bond_status ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_status}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_effort_basis" className='bond-label'>Effort Basis</label>
                                                                                    <select id="bond_effort_basis" name="bond_effort_basis" disabled={true}
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_effort_basis: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_effort_basis}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Effort Basis 1</option>
                                                                                        <option value={2}>Effort Basis 2</option>
                                                                                        <option value={3}>Effort Basis 3</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_registrar_name" className='bond-label'>Registrar</label>
                                                                                    <select id="bond_registrar_name" name="bond_registrar_name" disabled={true}
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_registrar_name: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_registrar_name}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Each Issue will have a separate Registrar</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_trustee_name" className='bond-label'>Trustee</label>
                                                                                    <select id="bond_trustee_name" name="bond_trustee_name" disabled={true}
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_trustee_name: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_trustee_name}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Each Issue will have a separate Trustee</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Int on App Money</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_int_on_app_money: e.target.value }))} value={formikProps.values?.bond_int_on_app_money} />
                                                                                    {/* {formikProps.errors.bond_int_on_app_money ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_int_on_app_money}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Int on Refund Money</label>
                                                                                    <input id="Bond Name" name="lastName" type="text" disabled={true} className='bond-input bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_int_on_refund_money: e.target.value }))} value={formikProps.values?.bond_int_on_refund_money} />
                                                                                    {/* {formikProps.errors.bond_int_on_refund_money ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_int_on_refund_money}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Early Bird Incentive</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_early_bid_incentive: e.target.value }))} value={formikProps.values?.bond_early_bid_incentive} />
                                                                                    {/* {formikProps.errors.bond_early_bid_incentive ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_early_bid_incentive}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Other Incentive</label>
                                                                                    <input id="Bond Name" name="lastName" type="text" disabled={true} className='bond-input bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_other_incentive: e.target.value }))} value={formikProps.values?.bond_other_incentive} />
                                                                                    {/* {formikProps.errors.bond_other_incentive ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_other_incentive}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>E-Form Incentive</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_e_form_incentive: e.target.value }))} value={formikProps.values?.bond_e_form_incentive} />
                                                                                    {/* {formikProps.errors.bond_e_form_incentive ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_e_form_incentive}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond Name" className='bond-label'>Allotment Date</label>
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_allotment_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_allotment_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style-bond-input-disabled'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                        disabled={true}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_green_shoe" className='bond-label'>Green Shoe</label>
                                                                                    <select id="bond_green_shoe" name="bond_green_shoe" disabled={true}
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_green_shoe: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_green_shoe}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>Yes</option>
                                                                                        <option value={2}>No</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Green Shoe Size</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_green_shoe_size: e.target.value }))} value={formikProps.values?.bond_green_shoe_size} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Final Issue Amount (₹)</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_final_issue_amount: e.target.value }))} value={formikProps.values?.bond_final_issue_amount} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Issuer Name" className='bond-label'>Procurement Amount (₹)</label>
                                                                                    <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_procurement_amount: e.target.value }))} value={formikProps.values?.bond_procurement_amount} />
                                                                                    {/* {formikProps.errors.bond_procurement_amount ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_procurement_amount}</div>) : null} */}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_gst" className='bond-label'>GST</label>
                                                                                    <select id="bond_gst" name="bond_gst" disabled={true}
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_gst: parseInt(e.target.value) })); }}
                                                                                        value={formikProps.values.bond_gst}
                                                                                        className='bond-input-select bond-input-disabled'
                                                                                    >
                                                                                        <option value={0}>Select</option>
                                                                                        <option value={1}>5%</option>
                                                                                        <option value={2}>10%</option>
                                                                                        <option value={3}>18%</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_arranger_name" className='bond-label'>Arranger Name (if any)</label>
                                                                                    <input id="bond_arranger_name" name="bond_arranger_name" type="text" disabled={true} className='bond-input bond-input-disabled'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_arranger_name: e.target.value }))}
                                                                                        value={formikProps.values?.bond_arranger_name} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={8}>
                                                                                <div className='row mt-2'>
                                                                                    <div className='col-6 col-md-6 col-lg-6 '>
                                                                                        <label htmlFor="Issuer Name" className='bond-label'>UPI App No Series (For Trust)</label>
                                                                                        <div className='col-12 col-md-12 col-lg-12 d-flex'>
                                                                                            <div className='col-6 col-md-6 col-lg-6 mx-1'>
                                                                                                <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input  w-100 bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_upi_app_no_series_1: e.target.value }))} value={formikProps.values?.bond_upi_app_no_series_1} />
                                                                                            </div>
                                                                                            <div className='col-6 col-md-6 col-lg-6 mx-1'>
                                                                                                <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input  w-100 bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_upi_app_no_series_2: e.target.value }))} value={formikProps.values?.bond_upi_app_no_series_2} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-6 col-md-6 col-lg-6 '>
                                                                                        <label htmlFor="Issuer Name" className='bond-label'>ASBA App No Series (For Trust)</label>
                                                                                        <div className='col-12 col-md-12 col-lg-12 d-flex'>
                                                                                            <div className='col-6 col-md-6 col-lg-6 mx-1'>
                                                                                                <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input  w-100 bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_asba_app_no_series_1: e.target.value }))} value={formikProps.values?.bond_asba_app_no_series_1} />
                                                                                            </div>
                                                                                            <div className='col-6 col-md-6 col-lg-6 mx-1'>
                                                                                                <input id="Issuer Name" name="firstName" type="text" disabled={true} className='bond-input  w-100 bond-input-disabled' onChange={e => setFormikInitialData(({ ...formikInitialData, bond_asba_app_no_series_2: e.target.value }))} value={formikProps.values?.bond_asba_app_no_series_2} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>

                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Category Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_category_institutional" className='bond-label'>I - Institutional</label>
                                                                                    <input id="bond_category_institutional" name="bond_category_institutional" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_institutional: e.target.value }))}
                                                                                        value={formikProps.values?.bond_category_institutional}
                                                                                        onBlur={formikProps.handleBlur}
                                                                                    />
                                                                                    {formikProps.touched.bond_category_institutional && formikProps.errors.bond_category_institutional ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_institutional).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_category_non_institutional" className='bond-label'>II - Non-Institutional</label>
                                                                                    <input id="bond_category_non_institutional" name="bond_category_non_institutional" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_non_institutional: e.target.value }))}
                                                                                        value={formikProps.values?.bond_category_non_institutional}
                                                                                        onBlur={formikProps.handleBlur} />
                                                                                    {formikProps.touched.bond_category_non_institutional && formikProps.errors.bond_category_non_institutional ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_non_institutional).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_category_hni" className='bond-label'>III - HNI</label>
                                                                                    <input id="bond_category_hni" name="bond_category_hni" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_hni: e.target.value }))}
                                                                                        value={formikProps.values?.bond_category_hni}
                                                                                        onBlur={formikProps.handleBlur} />
                                                                                    {formikProps.touched.bond_category_hni && formikProps.errors.bond_category_hni ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_hni).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_category_retail" className='bond-label'>IV - Retail</label>
                                                                                    <input id="bond_category_retail" name="bond_category_retail" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_category_retail: e.target.value }))}
                                                                                        value={formikProps.values?.bond_category_retail}
                                                                                        onBlur={formikProps.handleBlur} />
                                                                                    {formikProps.touched.bond_category_retail && formikProps.errors.bond_category_retail ?
                                                                                        (<div style={{ color: "#FF0000" }}>{(formikProps.errors.bond_category_retail).toString()}</div>)
                                                                                        : null}
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Maturity Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                {/* <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_date" className='bond-label'>Maturity Date</label>
                                                                                    <input id="bond_maturity_date" name="bond_maturity_date" type="text" className='bond-input'
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, bond_maturity_date: e.target.value })) }}
                                                                                        value={formikProps.values?.bond_maturity_date!} />
                                                                                </div> */}
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_date" className='bond-label'>Maturity Date</label>
                                                                                    {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={maturityDate}
                                                                                        onChange={handleChangeMaturityDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_maturity_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_maturity_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_amount" className='bond-label'>Maturity Amount</label>
                                                                                    <input id="bond_maturity_amount" name="bond_maturity_amount" disabled={true} type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_maturity_amount: e.target.value }))}
                                                                                        value={formikProps.values?.bond_maturity_amount} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Coupon Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={3}>
                                                                                {/* <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_date" className='bond-label'>Coupon Date</label>
                                                                                    <input id="bond_coupon_date" name="bond_coupon_date" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_date: e.target.value }))}
                                                                                        value={formikProps.values?.bond_coupon_date!} />
                                                                                </div> */}
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_maturity_date" className='bond-label'>Coupon Date</label>
                                                                                    {/* <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={couponDate}
                                                                                        onChange={handleChangeCouponDate}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    /> */}
                                                                                    <DatePicker
                                                                                        selected={localStringToIST(formikProps.values.bond_coupon_date)}
                                                                                        dateFormat="dd/MM/yyyy"
                                                                                        closeOnScroll={true}
                                                                                        onChange={(date: Date) => { setFormikInitialData(({ ...formikInitialData, bond_coupon_date: moment(date).format('YYYY-MM-DD').toString() })); }}
                                                                                        placeholderText="DD/MM/YYYY"
                                                                                        className='date-picker-style'
                                                                                        peekNextMonth
                                                                                        showMonthDropdown
                                                                                        showYearDropdown
                                                                                        dropdownMode="select"
                                                                                        customInput={(<CustomInput />)}
                                                                                        disabled={true}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={3}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="bond_coupon_amount" className='bond-label'>Coupon Amount</label>
                                                                                    <input id="bond_coupon_amount" name="bond_coupon_amount" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, bond_coupon_amount: e.target.value }))}
                                                                                        value={formikProps.values?.bond_coupon_amount} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Call Details </h5>
                                                                        <div className='bond-add-btn'
                                                                            onClick={() => addBonCallDetail()}
                                                                        >
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_call_details.map((call_detail, index) => {
                                                                                return (
                                                                                    <Grid direction="row" container spacing={2} alignItems="center">
                                                                                        <Grid item xs={3}>
                                                                                            {/* <div className='d-grid'>
                                                                                                <label htmlFor="bond_call_detail_datetime" className='bond-label'>Call Date</label>
                                                                                                <input id="bond_call_detail_datetime" name="bond_call_detail_datetime" type="text" className='bond-input'
                                                                                                    value={call_detail.bond_call_detail_datetime!}
                                                                                                    onChange={e => { call_detail.bond_call_detail_datetime = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div> */}
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_date" className='bond-label'>Interest Date</label>
                                                                                                {/* <DesktopDatePicker
                                                                                                    inputFormat="DD/MM/YYYY"
                                                                                                    value={call_detail.bond_call_detail_datetime}
                                                                                                    onChange={e => { call_detail.bond_call_detail_datetime = e; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    renderInput={(params: any) => <TextField {...params} />}
                                                                                                    className="date-picker-input-style"
                                                                                                    components={{
                                                                                                        OpenPickerIcon: DateIcon
                                                                                                    }}
                                                                                                /> */}
                                                                                                <DatePicker
                                                                                                    selected={localStringToIST(call_detail.bond_call_detail_datetime)}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    closeOnScroll={true}
                                                                                                    onChange={(date: Date) => { call_detail.bond_call_detail_datetime = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                    placeholderText="DD/MM/YYYY"
                                                                                                    className='date-picker-style'
                                                                                                    peekNextMonth
                                                                                                    showMonthDropdown
                                                                                                    showYearDropdown
                                                                                                    dropdownMode="select"
                                                                                                    customInput={(<CustomInput />)}
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={3}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_call_detail_amount" className='bond-label'>Call Amount</label>
                                                                                                <input id="bond_call_detail_amount" name="bond_call_detail_amount" type="number" disabled={true} className='bond-input'
                                                                                                    value={call_detail.bond_call_detail_amount}
                                                                                                    onChange={e => { call_detail.bond_call_detail_amount = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid direction="row" item xs={1} justifyContent="center" alignItems="center">
                                                                                            <div className='d-flex justify-content-center'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a
                                                                                                        // onClick={() => removeBonCallDetail(index)} 
                                                                                                        style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Put Details </h5>
                                                                        <div className='bond-add-btn'
                                                                            onClick={() => addBondPutDetail()}
                                                                        >
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_put_details.map((put_detail, index) => {
                                                                                return (
                                                                                    <Grid direction="row" container spacing={2} alignItems="center">
                                                                                        <Grid item xs={3}>
                                                                                            {/* <div className='d-grid'>
                                                                                                <label htmlFor="bond_put_detail_datetime" className='bond-label'>Put Date</label>
                                                                                                <input id="bond_put_detail_datetime" name="bond_put_detail_datetime" type="text" className='bond-input'
                                                                                                    value={put_detail.bond_put_detail_datetime!}
                                                                                                    onChange={e => { put_detail.bond_put_detail_datetime = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div> */}
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_cashflow_date" className='bond-label'>Put Date</label>
                                                                                                {/* <DesktopDatePicker
                                                                                                    inputFormat="DD/MM/YYYY"
                                                                                                    value={put_detail.bond_put_detail_datetime}
                                                                                                    onChange={e => { put_detail.bond_put_detail_datetime = e; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    renderInput={(params: any) => <TextField {...params} />}
                                                                                                    className="date-picker-input-style"
                                                                                                    components={{
                                                                                                        OpenPickerIcon: DateIcon
                                                                                                    }}
                                                                                                /> */}
                                                                                                <DatePicker
                                                                                                    selected={localStringToIST(put_detail.bond_put_detail_datetime)}
                                                                                                    dateFormat="dd/MM/yyyy"
                                                                                                    closeOnScroll={true}
                                                                                                    onChange={(date: Date) => { put_detail.bond_put_detail_datetime = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                    placeholderText="DD/MM/YYYY"
                                                                                                    className='date-picker-style'
                                                                                                    peekNextMonth
                                                                                                    showMonthDropdown
                                                                                                    showYearDropdown
                                                                                                    dropdownMode="select"
                                                                                                    customInput={(<CustomInput />)}
                                                                                                    disabled={true}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>
                                                                                        <Grid item xs={3}>
                                                                                            <div className='d-grid'>
                                                                                                <label htmlFor="bond_put_detail_amount" className='bond-label'>Put Amount</label>
                                                                                                <input id="bond_put_detail_amount" name="bond_put_detail_amount" type="number"
                                                                                                    disabled={true} className='bond-input'
                                                                                                    value={put_detail.bond_put_detail_amount}
                                                                                                    onChange={e => { put_detail.bond_put_detail_amount = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                />
                                                                                            </div>
                                                                                        </Grid>

                                                                                        <Grid direction="row" item xs={1} justifyContent="center" alignItems="center">
                                                                                            <div className='d-flex justify-content-end'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a
                                                                                                        // onClick={() => removeBondPutDetail(index)} 
                                                                                                        style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Bond's Rating Details </h5>
                                                                        <div className='bond-add-btn' onClick={() => addBondRating()}>
                                                                            <a className='text-white'>
                                                                                <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                Add
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikProps.values?.bond_rating_details.map((rating, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2} justifyContent="center" alignItems="center">
                                                                                            <Grid item xs={3.5}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_crisil_short_rating" className='bond-label'>Rating Agency Name</label>
                                                                                                    <input id="bond_crisil_short_rating" name="bond_crisil_short_rating" type="text" className='bond-input'
                                                                                                        value={rating.bond_rating_agency}
                                                                                                        onChange={e => { rating.bond_rating_agency = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={3.5}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_crisil_short_rating" className='bond-label'>Choose Rating</label>
                                                                                                    <select id="bond_id" name="bond_id"
                                                                                                        value={rating.bond_rating}
                                                                                                        onChange={e => { rating.bond_rating = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                        className='bond-input-select'
                                                                                                    >
                                                                                                        {bondRatingOptions !== undefined && !bondRatingOptions.some(bondRatingOption => bondRatingOption.value === "0") && (
                                                                                                            <option value="0">Select</option>
                                                                                                        )}
                                                                                                        {bondRatingOptions && bondRatingOptions.map((option, index) => {
                                                                                                            return (<option key={'option-' + index} value={option.value}>{option.label}</option>)
                                                                                                        })}
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_rating_certificate" className='bond-label'>Upload Rating Certificate</label>
                                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild({ feild_type: 'bond_rating_certificate', inx: index }) } })}>
                                                                                                        <input {...getInputProps()} />
                                                                                                        {
                                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_rating_details[index]?.bond_rating_certificate, 'Upload Rating Ceritificate')}</label>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid direction="row" item xs={1} justifyContent="center" alignItems="center">
                                                                                                <div className='d-flex justify-content-end'>
                                                                                                    <div className='bond-add-btn-minus'>
                                                                                                        <a onClick={() => removeBondRating(index)} style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            {/* <div className='d-flex align-items-center'>
                                                                                                <div className='bond-add-btn-minus'>
                                                                                                    <a onClick={() => removeBondRating(index)} style={{ cursor: "pointer" }}><span>-</span></a>
                                                                                                </div>
                                                                                            </div> */}
                                                                                        </Grid>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Investment Purpose Categories </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            <div className="row row-cols-12">
                                                                                {
                                                                                    formikProps.values?.bond_brand_tags != undefined && formikProps.values?.bond_brand_tags.length > 0 ?
                                                                                        <>
                                                                                            {
                                                                                                formikProps.values?.bond_brand_tags.map((brand_tag, index) => {
                                                                                                    return (
                                                                                                        <>
                                                  
                                                                                                        {brand_tag?.brand_tag_status == 1 && 
                                                                                                        <div className={`col-3 brand-tag${brand_tag.bond_brand_tag_available != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            onClick={() =>
                                                                                                                updateBrandTags(brand_tag.bond_brand_tag_available != null ? false : true, index)
                                                                                                            }
                                                                                                        >{brand_tag.brand_tag_name}</div>
                                                                                                        }</>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </>
                                                                                        : ''
                                                                                }
                                                                                {/* {
                                                                                    selectedBrandTag != undefined && selectedBrandTag.length > 0 ?
                                                                                        <>
                                                                                            {
                                                                                                selectedBrandTag.map((brand_tags, index) => {
                                                                                                    return (
                                                                                                        <div className={`col-3 brand-tag${brand_tags.brand_tag_id != null ? '-active' : ''} p-3 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                            onClick={() =>
                                                                                                                updateBrandTags(brand_tags.brand_tag_id != null ? false : true, index)
                                                                                                            }
                                                                                                        >{brand_tags.brand_tag_name}</div>
                                                                                                    )
                                                                                                })

                                                                                            }
                                                                                        </>
                                                                                        : ''
                                                                                } */}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> Eligible Investors </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-center">
                                                                            {
                                                                                formikProps.values?.bond_eligible_investors != undefined && formikProps.values?.bond_eligible_investors.length > 0 ?
                                                                                    <div className="row row-cols-12 ">
                                                                                        {
                                                                                            formikProps.values?.bond_eligible_investors.map((eligible, index) => {
                                                                                                return (
                                                                                                    <>
                                                                                                    {eligible?.eligible_investor_status == 1 &&
                                                                                                    <div className={`col-3 brand-tag${eligible.eligible_investors_available != null ? '-active' : ''} px-1 py-2 mx-2 my-2 fs-6 text-center d-flex align-items-center justify-content-center`}
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        onClick={() =>
                                                                                                            updateEligibleTags(eligible.eligible_investors_available != null ? false : true, index)
                                                                                                        }
                                                                                                    >{eligible.eligible_investor_name}</div>
                                                                                            }</>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header"> About This Bond</h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="text-left">
                                                                            <div className="d-grid">
                                                                                <textarea
                                                                                    id="bond_about_this_ipo"
                                                                                    name="bond_about_this_ipo"
                                                                                    rows={4} cols={50} maxLength={100} placeholder="100 words max*"
                                                                                    className="bond-input"
                                                                                    onChange={e => setFormikInitialData(({ ...formikInitialData, bond_about_this_ipo: e.target.value }))}
                                                                                    value={formikProps.values?.bond_about_this_ipo}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Add Key Highlights </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Editor
                                                                            editorState={addKeyHeighlights}
                                                                            toolbarClassName="toolbar_form-control"
                                                                            wrapperClassName="editor_form-control_feedDetail"
                                                                            editorClassName="editor_textFeild_form-control"
                                                                            onEditorStateChange={(e: any) => onKeyHeighlightsEditorStateChange(e)}
                                                                            placeholder="Write a KeyHeight..."
                                                                            toolbar={wysiwygToolbar}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Add Issuer Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            formikRef.current?.values.bond_issuer_details.map((issuer_details, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <Grid direction="row" container spacing={2}>

                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_total_annual_revenue" className='bond-label'>Total Annual Revenue</label>
                                                                                                    <input id="bond_total_annual_revenue" name={`bond_issuer_details.${index}.bond_total_annual_revenue`} type="number" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_total_annual_revenue}
                                                                                                        onChange={e => { issuer_details.bond_issuer_total_annual_revenue = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                    {formikProps.errors.bond_issuer_details != undefined && formikProps.errors.bond_issuer_details.length > 0 ? (<div style={{ color: "#FF0000" }}>Issuer total annual revenue is required</div>) : null}
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_year_of_inception" className='bond-label'>Year of Inception</label>
                                                                                                    <input id="bond_year_of_inception" name="bond_year_of_inception" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_year_of_inception}
                                                                                                        onChange={e => { issuer_details.bond_issuer_year_of_inception = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_industry" className='bond-label'>Industry</label>
                                                                                                    <input id="bond_industry" name="bond_industry" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_industry}
                                                                                                        onChange={e => { issuer_details.bond_issuer_industry = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_head_office" className='bond-label'>Head Office</label>
                                                                                                    <input id="bond_head_office" name="lastName" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_head_office}
                                                                                                        onChange={e => { issuer_details.bond_issuer_head_office = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="type_of_issuer" className='bond-label'>Type of Issuer</label>
                                                                                                    {/* <input id="Nature of Instrument" name="email" type="email" className='bond-input' onChange={formikProps.handleChange} value={formikProps.values.email} /> */}
                                                                                                    <select id="type_of_issuer" name="email"
                                                                                                        onChange={formikProps.handleChange}
                                                                                                        className='bond-input-select'
                                                                                                    >
                                                                                                        <option value="0">Select</option>
                                                                                                        <option value="1">Private Sector Corporate</option>
                                                                                                        <option value="2">Public Sector Undertaking</option>
                                                                                                        <option value="3">Private  Bank</option>
                                                                                                        <option value="4">Public Sector Bank</option>
                                                                                                        <option value="5">State Government</option>
                                                                                                        <option value="6">Central Government</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_series" className='bond-label'>Series</label>
                                                                                                    <input id="bond_issuer_series" name="bond_issuer_series" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_series}
                                                                                                        onChange={e => { issuer_details.bond_issuer_series = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>


                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="type_of_issuer" className='bond-label'>Frequency of Interest</label>
                                                                                                    {/* <input id="Nature of Instrument" name="email" type="email" className='bond-input' onChange={formikProps.handleChange} value={formikProps.values.email} /> */}
                                                                                                    <select id="type_of_issuer" name="email"
                                                                                                        onChange={formikProps.handleChange}
                                                                                                        className='bond-input-select'
                                                                                                    >
                                                                                                        <option value={0}>Select</option>
                                                                                                        <option value={1}>Annual</option>
                                                                                                        <option value={2}>Semi Annual</option>
                                                                                                        <option value={3}>Quarterly</option>
                                                                                                        <option value={3}>Monthly</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>


                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuer_effective_yield" className='bond-label'>Effective Yield</label>
                                                                                                    <input id="bond_issuer_effective_yield" name="bond_issuer_effective_yield" type="text" className='bond-input'
                                                                                                        value={issuer_details.bond_issuer_effective_yield}
                                                                                                        onChange={e => { issuer_details.bond_issuer_effective_yield = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuers_profile_link" className='bond-label'>Issuers Profile Link</label>
                                                                                                    <input id="bond_issuers_profile_link" name="bond_issuers_profile_link" type="text" className='bond-input-upload-link'
                                                                                                        value={issuer_details.bond_issuer_profile_link}
                                                                                                        onChange={e => { issuer_details.bond_issuer_profile_link = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuers_finance_details" className='bond-label'>Issuers Finance Details</label>
                                                                                                    <input id="bond_issuers_finance_details" name="bond_issuers_finance_details" type="text" className='bond-input-upload-link'
                                                                                                        value={issuer_details.bond_issuer_finance_details}
                                                                                                        onChange={e => { issuer_details.bond_issuer_finance_details = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issue_documents" className='bond-label'>Upload ISSUE DOCUMENTS</label>
                                                                                                    {/* <input id="bond_issue_documents" name="bond_issue_documents" type="text" className='bond-input-upload-file'
                                                                                                        value={issuer_details.bond_issuer_documents}
                                                                                                        onChange={e => { issuer_details.bond_issuer_documents = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    /> */}
                                                                                                    <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_issuer_documents' })) } })}>
                                                                                                        <input {...getInputProps()} />
                                                                                                        {
                                                                                                            <label className="bond-input-upload-file">{getURLExtension(formikRef.current?.values.bond_issuer_details[index].bond_issuer_documents, 'Upload ISSUE DOCUMENTS')}</label>
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                <div className="d-grid">
                                                                                                    <label htmlFor="bond_issuer_about" className="bond-label">
                                                                                                        About issuer
                                                                                                    </label>
                                                                                                    <textarea
                                                                                                        id="bond_issuer_about"
                                                                                                        name="bond_issuer_about"
                                                                                                        rows={4} cols={50} maxLength={100}
                                                                                                        className="bond-input"
                                                                                                        placeholder="100 words max*"
                                                                                                        value={issuer_details.bond_issuer_about}
                                                                                                        onChange={(e) => {
                                                                                                            issuer_details.bond_issuer_about = e.target.value;
                                                                                                            setFormikInitialData({ ...formikInitialData });
                                                                                                        }}
                                                                                                    />
                                                                                                </div>


                                                                                                {/* <Editor
                                                  editorState={addIssuerAbout}
                                                  toolbarClassName="toolbar_form-control"
                                                  wrapperClassName="editor_form-control_feedDetail"
                                                  editorClassName="editor_textFeild_form-control"
                                                  onEditorStateChange={(e: any) => onIssuerAboutEditorStateChange(e)}
                                                  placeholder="Write a Comment..."
                                                  toolbar={wysiwygToolbar}
                                                  wrapperStyle={{ margin: "10px 0px" }}
                                                /> */}
                                                                                            </Grid>

                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="issuer_type" className='bond-label'>Issuer's Type</label>
                                                                                                    {/* <input id="Nature of Instrument" name="email" type="email" className='bond-input' onChange={formikProps.handleChange} value={formikProps.values.email} /> */}
                                                                                                    <select id="issuer_type" name="email"
                                                                                                        //   onChange={e => { setFormikInitialData(({ ...formikInitialData, : parseInt(e.target.value) })); }}
                                                                                                        //   value={formikProps.values.bond_gst}
                                                                                                        className='bond-input-select bond-input-disabled'
                                                                                                        disabled={true}
                                                                                                    >
                                                                                                        <option value="0">Select</option>
                                                                                                        <option value="1">Private Sector Corporate</option>
                                                                                                        <option value="2">Public Sector Undertaking</option>
                                                                                                        <option value="3">Private  Bank</option>
                                                                                                        <option value="4">Public Sector Bank</option>
                                                                                                        <option value="5">State Government</option>
                                                                                                        <option value="6">Central Government</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="type_of_issuer" className='bond-label'>Tenor (years)</label>
                                                                                                    <input id="bond_issuer_series" name="bond_issuer_series" type="number" disabled={true} className='bond-input bond-input-disabled'
                                                                                                        value={issuer_details.bond_issuer_tenor}
                                                                                                        onChange={e => { issuer_details.bond_issuer_tenor = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                    {/* <select id="type_of_issuer" name="email"
                                                                                                        onChange={formikProps.handleChange}
                                                                                                        className='bond-input-select'
                                                                                                    >
                                                                                                        <option value={0}>Select</option>
                                                                                                        <option value={1}>1</option>
                                                                                                        <option value={2}>2</option>
                                                                                                        <option value={3}>3</option>
                                                                                                    </select> */}
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="type_of_issuer" className='bond-label'>Coupon Type</label>
                                                                                                    {/* <input id="Nature of Instrument" name="email" type="email" className='bond-input' onChange={formikProps.handleChange} value={formikProps.values.email} /> */}
                                                                                                    <select id="type_of_issuer" name="email"
                                                                                                        onChange={formikProps.handleChange}
                                                                                                        className='bond-input-select bond-input-disabled'
                                                                                                        disabled={true}
                                                                                                    >
                                                                                                        <option value={0}>Select</option>
                                                                                                        <option value={1}>Interest Rate to be paid (cannot be part of Drop Down Values)</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="Bond Name" className='bond-label'>First IP Date</label>
                                                                                                    {/* <DesktopDatePicker
                                                                                                        inputFormat="DD/MM/YYYY"
                                                                                                        value={firstIPDate}
                                                                                                        onChange={handleChangeFirstIPDate}
                                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                                        className="date-picker-input-style"
                                                                                                        components={{
                                                                                                            OpenPickerIcon: DateIcon
                                                                                                        }}
                                                                                                    /> */}
                                                                                                    <DatePicker
                                                                                                        selected={localStringToIST(issuer_details.bond_issuer_first_ip_date)}
                                                                                                        dateFormat="dd/MM/yyyy"
                                                                                                        closeOnScroll={true}
                                                                                                        onChange={(date: Date) => { issuer_details.bond_issuer_first_ip_date = moment(date).format('YYYY-MM-DD').toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        placeholderText="DD/MM/YYYY"
                                                                                                        className='date-picker-style bond-input-disabled'
                                                                                                        peekNextMonth
                                                                                                        showMonthDropdown
                                                                                                        showYearDropdown
                                                                                                        dropdownMode="select"
                                                                                                        customInput={(<CustomInput />)}
                                                                                                        disabled={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <div className='d-grid'>
                                                                                                    <label htmlFor="bond_issuers_profile_link" className='bond-label'>IP Dates</label>
                                                                                                    <input id="bond_issuers_profile_link" name="bond_issuers_profile_link" type="text" disabled={true} className='bond-input-upload-link bond-input-disabled'
                                                                                                    // value={issuer_details.}
                                                                                                    // onChange={e => { issuer_details.bond_issuer_profile_link = e.target.value; setFormikInitialData(({ ...formikInitialData })) }}
                                                                                                    />
                                                                                                </div>
                                                                                            </Grid>
                                                                                        </Grid>




                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                                        <div className='d-flex justify-content-start'>
                                                                            <h5 className="m-0 header"> Specific Terms for Each Series of NCDs Details </h5>
                                                                        </div>
                                                                        <div className="d-flex justify-content-end align-items-center ">
                                                                            <div className="form-check form-switch mx-2">
                                                                                <label className="form-check-label mx-1" htmlFor="flexSwitchCheckChecked">
                                                                                    Disable
                                                                                </label>
                                                                                <input className="form-check-input mx-0 mt-1 float-none" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                                                                                    onChange={(e) => { setFormikInitialData(({ ...formikInitialData, bond_ncd_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                    checked={formikProps.values?.bond_ncd_status == NCDStatus.Active ? true : false}
                                                                                />
                                                                                <label className="form-check-label mx-1" htmlFor="flexSwitchCheckChecked">
                                                                                    Enable
                                                                                </label>
                                                                            </div>
                                                                            <div className="bond-add-btn" onClick={() => updateNDCSeriesList()}>
                                                                                <a className="text-white">
                                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                    Add
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="table-responsive " data-pattern="priority-columns">
                                                                            <table id="tech-companies-1"  className={formikProps.values?.bond_ncd_status == 0 ? "table table table-bordered disabled" : "table table table-bordered"}>
                                                                                <thead className="city-table">
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        <th>Name</th>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((res: APIData.BondNcdSeries, index: number) => {
                                                                                                return (
                                                                                                    <th>Series{index + 1}<span onClick={() => { handleRemoveNCDseries(index) }}><img src='../../assets/images/close-btn.svg' className='ncd-series-close-btn'></img></span></th>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_frequency_of_interest_payment"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_frequency_of_interest_payment") }}
                                                                                                checked={formikRef.current?.values.bond_ncd_available[0].bond_ncd_frequency_of_interest_payment == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Frequency of Interest Payment</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <select
                                                                                                            id={'bond_ncd_frequency_of_interest_payment' + inx}
                                                                                                            name="bond_ncd_frequency_of_interest_payment"
                                                                                                            style={{ width: "100%" }}
                                                                                                            onChange={e => {
                                                                                                                feq.bond_ncd_frequency_of_interest_payment = e.target.value;
                                                                                                                console.log(e.target.value)
                                                                                                                setFormikInitialData(({ ...formikInitialData }));
                                                                                                            }}
                                                                                                            value={feq.bond_ncd_frequency_of_interest_payment}
                                                                                                            className='bond-input-select'
                                                                                                        >
                                                                                                            <option value={0}>Select</option>
                                                                                                            <option value={1}>Monthly</option>
                                                                                                            <option value={2}>Cumulative</option>
                                                                                                            <option value={3}>Yearly</option>
                                                                                                            <option value={4}>Maturity</option>
                                                                                                        </select>
                                                                                                        {formikProps.errors.bond_ncd_series ? (<div style={{ color: "#FF0000" }}></div>) : null}
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_minimum_application"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_minimum_application") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_minimum_application == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Minimum Application (₹)</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_minimum_application" + inx} name="bond_ncd_minimum_application" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_minimum_application}
                                                                                                            onChange={e =>
                                                                                                            // onUpdateNCDSeries(e.target.value, "bond_ncd_minimum_application", inx)
                                                                                                            { feq.bond_ncd_minimum_application = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); console.log(typeof e.target.value) }
                                                                                                            }
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_in_multiple_terms_of_thereafter"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_in_multiple_terms_of_thereafter") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_in_multiple_terms_of_thereafter == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>In Multiples of thereafter (₹)</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_in_multiple_terms_of_thereafter" + inx} name="bond_ncd_in_multiple_terms_of_thereafter" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_in_multiple_terms_of_thereafter}
                                                                                                            onChange={e => { feq.bond_ncd_in_multiple_terms_of_thereafter = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_issue_price_of_ncd"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_issue_price_of_ncd") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_issue_price_of_ncd == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Face Value/ Issue Price of NCDs (`/ NCD) (₹)</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_issue_price_of_ncd" + inx} name="bond_ncd_issue_price_of_ncd" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_issue_price_of_ncd}
                                                                                                            onChange={e => { feq.bond_ncd_issue_price_of_ncd = (e.target.value).toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_tenor"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_tenor") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_tenor == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Tenor</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_tenor" + inx} name="bond_ncd_tenor" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_tenor}
                                                                                                            onChange={e => { feq.bond_ncd_tenor = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_coupon"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_coupon") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_coupon == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Coupon (% per annum)</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_coupon" + inx} name="bond_ncd_coupon" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_coupon}
                                                                                                            onChange={e => { feq.bond_ncd_coupon = (e.target.value).toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_effective_yield"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_effective_yield") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_effective_yield == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Effective Yield (% per annum)</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_effective_yield" + inx} name="bond_ncd_effective_yield" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_effective_yield}
                                                                                                            onChange={e => { feq.bond_ncd_effective_yield = (e.target.value).toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_mode_of_interest_payment"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_mode_of_interest_payment") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_mode_of_interest_payment == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Mode of Issuer</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        {/* <input id={"bond_ncd_mode_of_interest_payment" + inx} name="bond_ncd_mode_of_interest_payment" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_mode_of_interest_payment}
                                                                                                            onChange={e => { feq.bond_ncd_mode_of_interest_payment = e.target.value; setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        /> */}
                                                                                                        <select
                                                                                                            id={'bond_ncd_mode_of_interest_payment' + inx}
                                                                                                            name="bond_ncd_mode_of_interest_payment"
                                                                                                            style={{ width: "100%" }}
                                                                                                            onChange={e => {
                                                                                                                console.log(typeof e.target.value)
                                                                                                                console.log(e.target.value)
                                                                                                                feq.bond_ncd_mode_of_interest_payment = e.target.value;
                                                                                                                setFormikInitialData(({ ...formikInitialData }))
                                                                                                            }}
                                                                                                            value={feq.bond_ncd_mode_of_interest_payment}
                                                                                                            className='bond-input-select'
                                                                                                        >
                                                                                                            <option value={0}>Select</option>
                                                                                                            <option value={1}>Public Placement</option>
                                                                                                            <option value={2}>Private</option>
                                                                                                        </select>
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_redemption_amount"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_redemption_amount") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_redemption_amount == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Redemption Amount (₹ / NCD) on Maturity</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        <input id={"bond_ncd_redemption_amount" + inx} name="bond_ncd_redemption_amount" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_redemption_amount}
                                                                                                            onChange={e => { feq.bond_ncd_redemption_amount = (e.target.value).toString(); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        />
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <input className="form-check-input" type="checkbox" id="bond_ncd_put_and_option"
                                                                                                onChange={(check) => { onUpdateNCDAvailable(check.target.checked, "bond_ncd_put_and_option") }}
                                                                                                checked={formikProps.values?.bond_ncd_available[0].bond_ncd_put_and_option == 0 ? false : true}
                                                                                            />
                                                                                        </td>
                                                                                        <td>Put and Call Option</td>
                                                                                        {
                                                                                            formikProps.values?.bond_ncd_series.map((feq: APIData.BondNcdSeries, inx: number) => {
                                                                                                return (
                                                                                                    <td>
                                                                                                        {/* <input id={"bond_ncd_put_and_option" + inx} name="bond_ncd_put_and_option" type="number" className='bond-input'
                                                                                                            value={feq.bond_ncd_put_and_option}
                                                                                                            onChange={e => { feq.bond_ncd_put_and_option = parseInt(e.target.value); setFormikInitialData(({ ...formikInitialData })); }}
                                                                                                        /> */}
                                                                                                        <select
                                                                                                            id={'bond_ncd_put_and_option' + inx}
                                                                                                            name="bond_ncd_put_and_option"
                                                                                                            style={{ width: "100%" }}
                                                                                                            onChange={e => {
                                                                                                                feq.bond_ncd_put_and_option = parseInt(e.target.value);
                                                                                                                setFormikInitialData(({ ...formikInitialData }));
                                                                                                                console.log(e.target.value);
                                                                                                            }}
                                                                                                            value={feq.bond_ncd_put_and_option}
                                                                                                            className='bond-input-select'
                                                                                                        >
                                                                                                            <option value={0}>Select</option>
                                                                                                            <option value={1}>Put</option>
                                                                                                            <option value={2}>Call</option>
                                                                                                            <option value={3}>Both</option>
                                                                                                        </select>
                                                                                                    </td>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='row text-end my-3'>
                                                                        <div className=''>
                                                                            <a className='cancel-btn mx-3' onClick={() => onClearNCDSeries()} >Clear</a>
                                                                            <a className='save-btn'>
                                                                                Save
                                                                            </a>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                                        <div className='d-flex justify-content-start'>
                                                                            <h5 className="m-0 header">Brokerage Details</h5>
                                                                        </div>
                                                                        <div className='d-flex justify-content-end align-items-center '>
                                                                            <a className='bond-add-btn'
                                                                            // onClick={() => updateShortTermList()}
                                                                            >
                                                                                <a className='text-white'>
                                                                                    <i className="fa fa-plus-circle mx-2" aria-hidden="true"></i>
                                                                                    Add
                                                                                </a>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                            shortTermList.length > 0 ?
                                                                                <div className="table-responsive " data-pattern="priority-columns">
                                                                                    <table id="tech-companies-1" className="table table table-bordered">
                                                                                        <thead className="city-table">
                                                                                            <tr>
                                                                                                {
                                                                                                    shortTermList[0].header.map((res: any, index: number) => {
                                                                                                        return (
                                                                                                            <th>{res.name}</th>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </tr>
                                                                                        </thead>
                                                                                        {
                                                                                            shortTermList[0].content.map((res: any, i: any) => (
                                                                                                <tbody key={i}>
                                                                                                    <tr >
                                                                                                        <td>{i + 1}</td>
                                                                                                        <td>{res.name}</td>
                                                                                                        {/* <td>{res.series_Value}</td> */}
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            ))
                                                                                        }
                                                                                    </table>
                                                                                </div>
                                                                                : <tbody>
                                                                                    <tr className='py-4'>
                                                                                        <td className='text-center py-5' colSpan={11}>No Data Found.</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                        }
                                                                    </div>
                                                                    {/* <div className='row text-end my-3'>
                                                                        <div className=''>
                                                                            <Link to={PageLinks.USERSDETAILS} state={user_id} className='cancel-btn mx-3' >Clear</Link>
                                                                            <a type='submit' className='save-btn'>
                                                                                Save
                                                                            </a>
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Disclaimer </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Editor
                                                                            editorState={addDisclamer}
                                                                            toolbarClassName="toolbar_form-control"
                                                                            wrapperClassName="editor_form-control_feedDetail"
                                                                            editorClassName="editor_textFeild_form-control"
                                                                            onEditorStateChange={(e: any) => onDisclamerEditorStateChange(e)}
                                                                            placeholder="Write a Disclamer..."
                                                                            toolbar={wysiwygToolbar}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <Grid direction="row" container spacing={2}>
                                                                <Grid item xs={4}>
                                                                    <div className='d-grid'>
                                                                        <div className='d-flex justify-content-between'>
                                                                            <label htmlFor="bond_asba_details" className='bond-label'>Upload ASBA Details</label>
                                                                            <a onClick={handleDownload}>Sample Excel<img src="../../assets/images/download.svg" style={{ width: "12px", marginBottom: "2px", cursor: "pointer", marginLeft: "4px" }} /></a>
                                                                        </div>
                                                                        <div {...getRootProps({ onClick: event => { setUploadFeild(e => ({ ...e, feild_type: 'bond_asba_details' })) } })}>
                                                                            <input {...getInputProps()} name='bond_asba_details' />
                                                                            {
                                                                                <label className="bond-input-upload-file">{getURLExtension(formikProps.values?.bond_asba_details, 'Upload ASBA Details')}</label>
                                                                            }
                                                                        </div>
                                                                        {/* {formikProps.errors.bond_asba_details ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.bond_asba_details}</div>) : null} */}
                                                                    </div>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <div className='d-grid'>
                                                                        <label htmlFor="bond_term_condition_link" className='bond-label'>Terms & Conditions Link</label>
                                                                        <input id="bond_term_condition_link" name="bond_term_condition_link" type="text" className='bond-input-upload-link'
                                                                            onChange={e => setFormikInitialData(({ ...formikInitialData, bond_term_condition_link: e.target.value }))} value={formikProps.values?.bond_term_condition_link}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </Grid>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)} >Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        {location.state.mode == 'edit' ? 'Update' : 'Save'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <SimpleModal contents={view_content} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" />
        </div >
    );
}

export default IPOAdd;

