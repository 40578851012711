import Pagination from "@mui/material/Pagination";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStateAPI } from "../common/AppStateAPI";
import { APIData } from "../common/DataTypes";
import Footer from "../common/Footer";
import Header from "../common/Header";
import { ServerAPI } from "../common/ServerAPI";
// import ConfirmationModal from '../modals/Confirmation-modal';
// import SimpleModal from '../modals/SimpleModal';

const Individualuserlogs: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state;

  // const [value, onChange] = useState(new Date());
  const [toData, setToData] = useState<null | Date>();
  const [fromDate, setFromDate] = useState<null | Date>();
  const [userLogList, setUserlogList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [search_text, setSearchText] = useState("");
  const [select_module, setSelectModule] = useState<any>(null);
  const [module_list, setModuleList] = useState<any[]>([]);
  const { setShowPreloader } = useAppStateAPI();

  useEffect(() => {
    setShowPreloader(true);
    ServerAPI.UserLogs(pageNumber, userId, search_text, toData, fromDate, select_module)
      .then((res) => {
        setUserlogList(res.datas);
        setTotalCount(res.total_pages);
      })
      .finally(() => {
        setShowPreloader(false);
      });
  }, [pageNumber, search_text, toData, fromDate, select_module, userId]);

  useEffect(() => {
    ServerAPI.UserLogsModule().then((res: any) => {
      setModuleList(res);
    });
  }, [pageNumber]);

  return (
    // <div>
    <div>
      <div id="layout-wrapper">
        <Header />
        <div className="main-content py-4">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                    {/* <h4 className="mb-sm-0 font-size-18 "> Suspend Users</h4> */}
                    <div className="page-title-right">
                      <ol className="breadcrumb ">
                        {/* TrustMoney > Users > Detail View > User Logs */}
                        <li className="breadcrumb-item">
                          <a style={{ color: "#90929E" }}>Trustmony</a>
                        </li>
                        <li className="breadcrumb-item  ">Users </li>
                        <li className="breadcrumb-item  ">Detail View </li>
                        <li className="breadcrumb-item active ">User Logs </li>
                      </ol>
                    </div>
                    <div className="cursor-pointer" onClick={() => navigate(-1)}>
                      <p className="mb-0 active">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 51.303 50.003">
                          <path
                            id="Path_22590"
                            data-name="Path 22590"
                            d="M-1748.422,907.993a2.738,2.738,0,0,1-3.882,0l-22.259-22.248a2.736,2.736,0,0,1,0-3.882L-1752.3,859.6a2.738,2.738,0,0,1,3.882,0l2.542,2.542a2.751,2.751,0,0,1-.046,3.928l-13.8,13.145h32.908a2.741,2.741,0,0,1,2.748,2.748v3.664a2.742,2.742,0,0,1-2.748,2.748h-32.908l13.8,13.144a2.732,2.732,0,0,1,.046,3.928Z"
                            transform="translate(1775.371 -858.797)"
                          />
                        </svg>
                        <span> Back </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                      <div className="row">
                        <div className="col-xl-5">
                          <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-5 mt-4">
                              <h4 className="card-heading">User Logs</h4>
                            </div>
                            <div className="col-xl-2 mx-xl-0 col-lg-2 col-md-3 col-sm-4 mt-4">
                              <h4 className="card-title text-end">Search :</h4>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-4">
                              <form className="app-search card-title ">
                                <div className="position-relative">
                                  <input type="text" className="select-style" onChange={(e) => setSearchText(e.target.value)} />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-7 text-end">
                          <div className="row">
                            <div className="col-xl-5">
                              <form className="app-search card-title ">
                                <div className="position-relative ">
                                  <select className="select-style" name="user_status" onChange={(e) => setSelectModule(e.target.value)}>
                                    {module_list.map((res: any, i: any) => (
                                      <option key={i} id={res.user_logs_module_id} value={res.user_logs_module_name}>
                                        {res.user_logs_module_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </form>
                            </div>
                            <div className="col-xl-3">
                              <form className="app-search card-title ">
                                <div className="position-relative">
                                  <DatePicker
                                    className="date-picker-input"
                                    maxDate={new Date()}
                                    clearIcon={null}
                                    closeCalendar={true}
                                    name="dob"
                                    format="dd/MM/yyyy"
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    yearPlaceholder="YYYY"
                                    onChange={setFromDate}
                                    value={fromDate}
                                    calendarIcon={
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                                        <path
                                          id="Path_23208"
                                          data-name="Path 23208"
                                          d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z"
                                          transform="translate(-308.128)"
                                        />
                                      </svg>
                                    }
                                  />
                                </div>
                              </form>
                            </div>
                            <div className="col-xl-1 mt-4 text-center">
                              <h4 className="card-title">TO</h4>
                            </div>
                            <div className="col-xl-3">
                              <form className="app-search card-title ">
                                <div className="position-relative">
                                  <DatePicker
                                    className="date-picker-input"
                                    maxDate={new Date()}
                                    clearIcon={null}
                                    closeCalendar={false}
                                    name="dob"
                                    format="dd/MM/yyyy"
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    yearPlaceholder="YYYY"
                                    onChange={setToData}
                                    value={toData}
                                    calendarIcon={
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 69.398 79.312">
                                        <path
                                          id="Path_23208"
                                          data-name="Path 23208"
                                          d="M377.526,17.349V71.877a7.438,7.438,0,0,1-7.435,7.435H315.565a7.438,7.438,0,0,1-7.437-7.435V17.349a7.438,7.438,0,0,1,7.437-7.435H323V1.859A1.864,1.864,0,0,1,324.859,0H326.1a1.864,1.864,0,0,1,1.859,1.859V9.914H357.7V1.859A1.864,1.864,0,0,1,359.557,0H360.8a1.864,1.864,0,0,1,1.859,1.859V9.914h7.435a7.438,7.438,0,0,1,7.435,7.435m-64.441,0v7.437h59.484V17.349a2.486,2.486,0,0,0-2.478-2.478H315.565a2.487,2.487,0,0,0-2.48,2.478m59.484,54.528V29.743H313.085V71.877a2.486,2.486,0,0,0,2.48,2.478h54.526a2.485,2.485,0,0,0,2.478-2.478m-47.71-22.306A1.864,1.864,0,0,1,323,47.712v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871A1.864,1.864,0,0,1,323,62.583v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM339.73,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859ZM354.6,49.571a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Zm0,14.871a1.864,1.864,0,0,1-1.859-1.859v-6.2a1.864,1.864,0,0,1,1.859-1.859h6.2a1.864,1.864,0,0,1,1.859,1.859v6.2a1.864,1.864,0,0,1-1.859,1.859Z"
                                          transform="translate(-308.128)"
                                        />
                                      </svg>
                                    }
                                  />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                      {/* <div className="card-body"> */}
                      <div className="table-rep-plugin">
                        <div className="table-responsive " data-pattern="priority-columns">
                          <table id="tech-companies-1" className="table table table-bordered">
                            <thead className="city-table">
                              <tr>
                                <th>Sr.No</th>
                                <th>Cust. ID</th>
                                {/* <th>Customer Name</th>
                                                                <th>Mobile Number</th> */}
                                <th>Updated Date & Time</th>
                                <th>IP Address</th>
                                <th className="w-10">Platform</th>
                                <th>Module</th>
                                <th>Method</th>
                                <th>Status</th>
                                <th>Last Login Date&Time</th>
                              </tr>
                            </thead>
                            {userLogList != undefined && userLogList.length > 0 ? (
                              <>
                                {userLogList.map((res: APIData.AlluserLogs, i: any) => (
                                  <tbody key={i}>
                                    <tr>
                                      <td> {i + 1} </td>
                                      <td>{res.user_customer_id !== "" || res.user_customer_id !== null ? <>{res.user_customer_id}</> : "N/A"}</td>
                                      <td>{res.user_logs_created_at != null && moment(res.user_logs_created_at).format("DD-MM-YYYY,  hh:mm A")}</td>
                                      <td>{res.ip_address !== "" || res.ip_address !== null ? <>{res.ip_address}</> : "N/A"}</td>
                                      <td>{res.platform !== "" || res.platform !== null ? <>{res.platform}</> : "N/A"}</td>
                                      <td>{res.module !== "" || res.module !== null ? <>{res.module}</> : "N/A"}</td>

                                      {res.operation_type === "" || (res.operation_type === null && <td>N/A</td>)}
                                      {res.operation_type === "1" && <td>POST</td>}
                                      {res.operation_type === "2" && <td>UPDATE</td>}
                                      {res.operation_type === "3" && <td>DELETE</td>}
                                      {res.operation_type === "4" && <td>GET</td>}

                                      {res.operation_status === "" || (res.operation_status === null && <td>N/A</td>)}
                                      {res.operation_status === "1" && <td>Success</td>}
                                      {res.operation_status === "2" && <td>Failed</td>}
                                      {res.user_last_login && <td>{moment(res.user_last_login).format("DD-MM-YYYY,  hh:mm A")}</td>}
                                      {/* {res.operation_status === "0" && <td>Success</td>} */}
                                    </tr>
                                  </tbody>
                                ))}
                              </>
                            ) : (
                              <tbody>
                                <tr className="py-4">
                                  <td className="text-center py-5" colSpan={11}>
                                    No Data Found.
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-5">
                          {totalCount > 10 ? (
                            <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                              Showing {userLogList.length} of {totalCount} entries
                            </div>
                          ) : (
                            <div className="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                              Showing {totalCount == 0 ? 0 : totalCount} entries
                            </div>
                          )}
                        </div>
                        <div className="col-sm-12 col-md-7">
                          <div className="dataTables_paginate paging_simple_numbers float-end mb-3" id="datatable_paginate">
                            <Pagination
                              count={Math.ceil(totalCount / 10)}
                              onChange={(event: any, value: any) => {
                                setPageNumber(Number(value));
                              }}
                              variant="outlined"
                              color="primary"
                              shape="rounded"
                            />
                          </div>
                        </div>
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Individualuserlogs;
