import { Dialog, DialogTitle, DialogContent, DialogContentText, Grid } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { Form, useNavigate } from "react-router-dom";
import { PageLinks } from "../common/Constants";
import ConfirmationModal from "./Confirmation-modal";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DatePicker from "react-datepicker";
import { APIData } from "../common/DataTypes";
import { InitialData } from "../common/InitialValue";
import { Formik, FormikProps } from "formik";
import { ServerAPI } from "../common/ServerAPI";
import * as Yup from "yup";
import { useAppStateAPI } from "../common/AppStateAPI";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import moment from "moment";

interface modalDatas {
    openModal?: any;
    handleClose?: any;
    handleOpen?: any;
    customerData?: any;
    // currentPage?:any
    // handleOpen
    bondID: number,
    bondsDetail: any,
    isin: any,
    setCalcudata: any
    listReload?: any;
}
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 950,
    bgcolor: "background.paper",
    border: "2px solid #fff",
    boxShadow: 24,
    p: 2,
    borderRadius: 3,
};

const Calculater: React.FC<modalDatas> = ({ handleOpen, customerData, openModal, bondID, bondsDetail, isin, handleClose,setCalcudata, listReload }) => {
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false);
    const navigate = useNavigate();
    const formikRef = useRef<FormikProps<APIData.CustomerEnquire>>(null);
    const [calculater, setCalculater] = useState(false);
    const { setShowPreloader } = useAppStateAPI();
    const [nomineeAdd, setNomineeAdd] = useState(false);
    const [settlementID, setSettlementID] = useState(0);
    const [YieldID, setYieldID] = useState(0);
    const [aidccID, setAidccID] = useState(0);
    const [price, setPrice] = useState<any>(0);
    const [YieldVal, setYieldVal] = useState<any>();
    const [aidcc, setAidcc] = useState<APIData.aidcc[]>([]);
    const [Yield, setYield] = useState<APIData.Yield[]>([]);
    const [settlement, setSettlement] = useState<APIData.settlement[]>([]);
    const [caldata, setCaldata] = useState<any>();

    useEffect(() => {
        setShowPreloader(true);
        ServerAPI.settlements().then((res: any) => {
            setSettlement(res);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.Yields().then((res: any) => {
            setYield(res);
        }).finally(() => {
            setShowPreloader(false);
        })
        setShowPreloader(true);
        ServerAPI.aidcc().then((res: any) => {
            setAidcc(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }, [])
    const BondEnquiryCal = () => {

        setShowPreloader(true);
        ServerAPI.price_calculator(isin, settlementID, YieldID, price, YieldVal, aidccID).then((response) => {
            if (response != undefined) {
                setCaldata(response)
                setCalculater(true);
                if (response.price_value) {
                    setPrice(response.price_value?.toFixed(2))
                } else if (response.yield_value) {
                    setYieldVal(response.yield_value?.toFixed(2))
                }
            }
        }).finally(() => {
            setShowPreloader(false);
        })
    }
    useEffect(() => {
        if (openModal === true) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [openModal]);

    const handleCloses = () => {
        setOpen(false);
        // setOpenModal(false)
        handleClose(false);
    };
    const handleClosed = (e: any) => {
        setOpenModal(e);
        setOpen(e);
        handleClose(e);
    };
    const Accept = () => {

        let params: any = {};
        params.price = price;
        params.yield_value = caldata?.yield_value;
        params.YieldVal = YieldVal;

        //  items.push(params);
        handleClose({state: { data: params } });
        setCalcudata({ data: params })
        setCalculater(false)
    }


    return (
        <div className="dialog">
            <Modal open={open} className="dialog-box" aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" className="bond_enquiry_details">
                        <form className="quote-form res-text-light row mt-2">
                            <div className="modal-box-title">
                                <p className="m-0 quicksand-medium">Bond Enquiry Details</p>
                                <p className="m-0" onClick={handleCloses}>
                                    <i className="fa fa-times cursor-pointer font-style" aria-hidden="true"></i>
                                </p>
                            </div>
                            <hr />
                            <div className="row align-items-center res-text-none mt-4">
                                <div className="col-lg-10 col-md-9 col-sm-9 col-9 detail-view-description ">
                                    <div className="d-flex align-items-center mb-2">
                                    <img src={bondsDetail?.bond_logo != '' ? bondsDetail?.bond_logo : "assets/img/img_placeholder.png"} alt="ipo" className="bond-title-img" />
                                        <p className="title fs-20 text-quick font-weight-500 mx-2">{bondsDetail?.bond_name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-5 col-md-9 float-left">
                                    <div className="choice-list political_switch mt-1  input_field d-flex align-items-end">
                                        <div className="input-title1">Mode Of Calculation</div>
                                        <div className="input-title-filled nominee-toggle-switch">
                                            <div className="custom-switch custom-switch-label-yesno">
                                                <input type="checkbox" value="None" id="checkbox-toggle-with-text" checked={nomineeAdd} onChange={(e: any) => {
                                                    // if (e.target.checked == true) {
                                                    setNomineeAdd(e.target.checked);
                                                    // } else {
                                                    //     setNomineeAdd(false);
                                                    // }

                                                }} className="checkbox-toggle" name="checkbox-toggle-with-text" />
                                                <label className="checkbox-toggle-label" htmlFor="checkbox-toggle-with-text"><span className="on">Price</span><span className="off">Yield</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="email-input input_field input-pt pl-0 col-lg-6 mt-3">
                                <label className="input-title1">Settlement Date</label>
                                <select className="bond-input-select" name="settlement_date_id" onChange={(e: any) => {
                                    setSettlementID(e.target.value);
                                }}>
                                    <option value="0">select</option>
                                    {settlement != undefined &&
                                        settlement.length > 0 &&
                                        settlement.map((title: APIData.settlement) => {
                                            return <option value={title.settlement_date_id}>{title.settlement_date_name}</option>;
                                        })}
                                </select>

                            </div>
                            {nomineeAdd === false ?
                                <div className="email-input input_field input-pt pl-0 col-lg-6 mt-3">
                                    <label className="input-title1">Enter Yield(%) to Maturity </label>
                                    <input type="text" placeholder="Type here"
                                        onChange={(e: any) => {
                                            setYieldVal(e.target.value);
                                        }}
                                        name="yield"
                                        id="yield"

                                    />
                                </div>
                                :
                                <div className="email-input input_field input-pt pl-0 col-lg-6 mt-3">
                                    <label className="input-title1">Enter Price</label>
                                    <input type="text"
                                        onChange={(e: any) => {
                                            setPrice(e.target.value);
                                        }}
                                        name="price"
                                        id="price" placeholder="Type here" />
                                </div>
                            }
                            <div className="email-input input_field input-pt pl-0 col-lg-6">
                                <label className="input-title1">Yield Type</label>
                                <select className="bond-input-select" name="yield_type_name" onChange={(e: any) => {
                                    setYieldID(e.target.value);
                                }}>
                                    <option value="0">select</option>
                                    {Yield != undefined &&
                                        Yield.length > 0 &&
                                        Yield.map((yields: APIData.Yield) => {
                                            return <option value={yields.yield_type_id}>{yields.yield_type_name}</option>;
                                        })}
                                </select>

                            </div>
                            <div className="email-input input_field input-pt pl-0 col-lg-6">
                                <label className="input-title1">aiDcc</label>
                                <select className="bond-input-select" name="ai_dcc_name" onChange={(e: any) => {
                                    setAidccID(e.target.value);
                                }}>
                                    <option value="0">select</option>
                                    {aidcc != undefined &&
                                        aidcc.length > 0 &&
                                        aidcc.map((aidccs: APIData.aidcc) => {
                                            return <option value={aidccs.ai_dcc_id}>{aidccs.ai_dcc_name}</option>;
                                        })}
                                </select>

                            </div>

                            <div className="col-lg-12 mb-2 mt-3 text-center">
                                    <button  onClick={(e: any) => {
                                    e.preventDefault();
                                    BondEnquiryCal();
                                }} className="btn-suspend_calculater text-white">Calculate</button>
                            </div>

                            {calculater == true ?
                                <div className="box-shadow1 mt-4">
                                    <div className="col-lg-12 row">
                                        <div className="col-lg-3">
                                            <p className="bg-1">My New Offer</p>
                                        </div>
                                        <div className="col-lg-6 mt-1">
                                            <div className="row row-sec mr-1 pb-1">
                                                <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                                                    <p className="price">Price</p>
                                                    <h5 className="text-left">{price}</h5>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-4 col-3">
                                                    <p className="price">Yield(%)</p>
                                                    <h5 className="text-left">{YieldVal}</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 mt-3 mt-sm-0">
                                            <div className="d-flex btn-box justify-content-end">
                                                <button onClick={(e: any) => {
                                                    e.preventDefault();
                                                    Accept();
                                                }} className="cancel-btn mx-3" style={{ padding: "3px 20px" }}>Accept</button>
                                                <button className="save-btn mx-2"onClick={handleCloses} style={{ padding: "3px 20px" }}>Reject</button>
                                            </div>
                                        </div>
                                    </div>

                                </div> : null}
                        </form>

                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

export default Calculater;
