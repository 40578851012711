import Pagination from '@mui/material/Pagination';
import { Field, Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from 'react-date-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppStateAPI } from '../common/AppStateAPI';
import { DealerStatus, Depository, NCDDetailsList, PageLinks, Strings, wysiwygToolbar } from '../common/Constants';
import Footer from '../common/Footer';
import Header from '../common/Header';
import { ServerAPI } from '../common/ServerAPI';
import ConfirmationModal from '../modals/Confirmation-modal';
import SimpleModal from '../modals/SimpleModal';
import * as Yup from 'yup';
import { Grid, Input, SvgIcon } from '@mui/material';
import FormField from './form_items/FormField';
import { APIData, FormDefaultConstants } from '../common/DataTypes';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { getIsLogin } from '../common/Utilities';
import { InitialData } from '../common/InitialValue';
import { toast } from 'react-hot-toast';

const DealerAdd: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const passing_value = location.state;
    const [preData, setPreData] = useState(passing_value);
    // const [preMode, setPreMode] = useState('add');
    // const [value, onChange] = useState(new Date());
    const [toData, setToData] = useState<null | Date>();
    const [fromDate, setFromDate] = useState<null | Date>();
    const [open, setOpen] = useState(false);
    const [open_modal, setOpenModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [user_id, setUserId] = useState()
    const [view_content, setViewContent] = useState();
    const [search_text, setSearchText] = useState('');
    const { setShowPreloader } = useAppStateAPI();
    const [formikInitialData, setFormikInitialData] = useState<APIData.DealerAdd>(InitialData.DealerAdd);
    const [DOJDate, setDOJDate] = React.useState<Dayjs | null>(dayjs(new Date()),);
    const [DOBDate, setDOBDate] = React.useState<Dayjs | null>(dayjs(new Date()),);
    const formikRef = useRef<FormikProps<APIData.DealerAdd>>(null);
    const [unicodeValue, setunicode_value] = useState('');
    const [userNameValue, setUserNameValue] = useState('');
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const [addComments, setAddComments] = useState<any | undefined>(EditorState.createEmpty());
    const [addComments_text, setAddComments_text] = useState("");

    const onEditorStateChange = (editorState: any) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const htmlDescription = draftToHtml(rawContentState);
        const htmlResult = htmlDescription.replace(/(<([^>]+)>)/ig, '');
        setAddComments(editorState);
        setAddComments_text(htmlResult);
    };

    function DateIcon() {
        return (
            <SvgIcon viewBox="0 0 72.872 83.283" style={{ padding: "2px" }}>
                <path id="Path_27181" data-name="Path 27181" d="M-1078.15,955.569v7.808h-72.872v-7.808a7.809,7.809,0,0,1,7.808-7.808h7.807v-7.808a2.61,2.61,0,0,1,2.6-2.6h5.205a2.61,2.61,0,0,1,2.6,2.6v7.808h20.82v-7.808a2.609,2.609,0,0,1,2.6-2.6h5.2a2.609,2.609,0,0,1,2.6,2.6v7.808h7.808A7.81,7.81,0,0,1-1078.15,955.569Zm-72.872,13.013h72.872v44.243a7.811,7.811,0,0,1-7.808,7.809h-57.256a7.81,7.81,0,0,1-7.808-7.809Zm10.41,18.869a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.821-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Zm20.82-20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.507a1.957,1.957,0,0,0-1.952-1.952h-6.506a1.957,1.957,0,0,0-1.952,1.952Zm0,20.82a1.957,1.957,0,0,0,1.952,1.952h6.506a1.957,1.957,0,0,0,1.952-1.952v-6.506a1.958,1.958,0,0,0-1.952-1.952h-6.506a1.958,1.958,0,0,0-1.952,1.952Z" transform="translate(1151.022 -937.351)" />
            </SvgIcon>
        );
    }

    useEffect(() => {
        getIsLogin().then(status => {
            if (!status) {
                navigate(PageLinks.LOGIN);
            }
        })
    }, [])

    useEffect(() => {
        if (location.state.mode == 'edit') {
            loadIntitalDealer(location.state.id);
        }
    }, [location])

    const loadIntitalDealer = (id: number) => {
        setShowPreloader(true);
        ServerAPI.getDealerDetail(id).then((res) => {
            setFormikInitialData(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    const handleDOJChange = (newValue: Dayjs | null) => {
        setDOJDate(newValue);
        setFormikInitialData(({ ...formikInitialData, dealer_doj: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }));

    };
    const handleDOBChange = (newValue: Dayjs | null) => {
        setDOBDate(newValue);
        setFormikInitialData(({ ...formikInitialData, dealer_dob: newValue ? newValue?.format('YYYY-MM-DD').toString() : '' }))
    };

    useEffect(() => {
        // loadIntitalDealer(preData);
    }, [pageNumber, search_text, toData, fromDate, preData, open])

    const handleClickOpen = () => { };

    const handleClose = (e: any) => {
        setOpen(e);
    }

    const handleClose_modal = (e: any) => {
        setOpenModal(e);
        // suspended_list();
    }

    const activate_user = (id: any) => {
        setOpenModal(true);
        setUserId(id);
    }

    const view_more_content = (e: any) => {
        setViewContent(e)
        setOpen(true)
    }

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        return (
            <div className='datePickerStyle'>
                <label onClick={props.onClick} ref={ref}>
                    {props.value || props.placeholder}
                </label>
                <img src='../../assets/images/calender-icon.svg' onClick={props.onClick} ref={ref} />
            </div>
        );
    });

    const loadDealerLoginDetails = (dealer_id: number, dealer_user_name: string, dealer_password: string, dealer_unique_code: string) => {
        setShowPreloader(true);
        ServerAPI.putDealerLoginDetail(dealer_id, dealer_user_name, dealer_password, dealer_unique_code).then((res) => {
            console.log(res);
        }).finally(() => {
            setShowPreloader(false);
        })
    }

    useEffect(() => {
        if (unicodeValue != '' && userNameValue != '') {
            if (unicodeValue == userNameValue) {
                toast.error('Not allowed to use same name for both user login');
            }
        }
    }, [unicodeValue, userNameValue])

    const yupSchema = Yup.object().shape({
        dealer_name: Yup.string().required('Dealer Name is required'),
        dealer_mobile: Yup.string().required("User Contact is Required").matches(phoneRegExp, 'Phone number is not valid').min(10, "to short").max(10, "to long"),
        dealer_unique_code: Yup.string().required('Unique Code is required'),
        dealer_employee_code: Yup.string().required('Dealer Employee Code is required'),
        dealer_user_name: Yup.string().required('User Name is required'),
        dealer_email: Yup.string().email().required('Enter valid email-id'),
    });

    const submitData = (data: any, setSubmitting: any) => {
        if (location.state.mode == 'edit') {
            setShowPreloader(true);
            ServerAPI.putDealer(data).then((res) => {
                try {
                    toast.success(res.message);
                    navigate(PageLinks.DEALER_Detail, { state: res.id });
                    setSubmitting(false);
                } catch (error) {
                    toast.error(res.error)
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        } else {
            setShowPreloader(true);
            ServerAPI.postDealer(data).then((res) => {
                try {
                    toast.success(res.message);
                    navigate(PageLinks.DEALER_Detail, { state: res.id });
                    setSubmitting(false);
                } catch (error) {
                    toast.error(res.error)
                }
            }).finally(() => {
                setShowPreloader(false);
            })
        }
    }

    return (
        <div>
            <div id="layout-wrapper">
                <Header />
                <div className="main-content py-4">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between pt-4 pb-0">
                                        <div className="page-title-right">
                                            <ol className="breadcrumb ">
                                                <li className="breadcrumb-item"><a style={{ color: "#90929E" }}>Trustmony</a></li>
                                                <li className="breadcrumb-item" onClick={() => navigate(PageLinks.DEALER_LIST)}><a> Dealer List </a></li>
                                                <li className="breadcrumb-item active ">  {location.state.mode == 'edit' ? 'Edit' : 'Add'} Dealer </li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body col-sm-12 col-md-12 col-lg-12 w-100">
                                            <Formik
                                                validationSchema={yupSchema}
                                                enableReinitialize={true}
                                                validateOnChange={false}
                                                initialValues={formikInitialData}
                                                innerRef={formikRef}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    values.dealer_dob = DOBDate ? DOBDate?.format('YYYY-MM-DD').toString() : null;
                                                    values.dealer_doj = DOJDate ? DOJDate?.format('YYYY-MM-DD').toString() : null;
                                                    submitData(values, setSubmitting);
                                                }}
                                            >{(formikProps) => {
                                                return (
                                                    <Form onSubmit={formikProps.handleSubmit}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header d-flex justify-content-between">
                                                                        <h5 className="m-0 header">  {location.state.mode == 'edit' ? 'Edit' : 'Add'} Dealer </h5>
                                                                        <div className="form-check form-switch mx-2">
                                                                            <label className="form-check-label" htmlFor="dealerStatusChecked">Disable/Enable</label>
                                                                            <input className="form-check-input " type="checkbox" role="switch" id="dealerStatusChecked"
                                                                                onChange={(e) => { setFormikInitialData(({ ...formikInitialData, dealer_status: e.target.checked == true ? 1 : 0 })) }}
                                                                                checked={formikProps.values?.dealer_status == DealerStatus.Active ? true : false} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="user_type" className='bond-label'>User Type</label>
                                                                                    <select id="user_type" name="email"
                                                                                        onChange={formikProps.handleChange}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="">Select</option>
                                                                                        <option value="Food">User Type 1</option>
                                                                                        <option value="Entertainment">User Type 2</option>
                                                                                        <option value="Academic">User Type 3</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="gender" className='bond-label'>Gender</label>
                                                                                    <select id="gender" name="dealer_gender"
                                                                                        onChange={formikProps.handleChange}
                                                                                        value={formikProps.values?.dealer_gender}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {
                                                                                            FormDefaultConstants.GenderOption.map((gen) => {
                                                                                                return <option value={gen.value}>{gen.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Name of User *</label>
                                                                                    <input id="Bond_Name" name="lastName" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_name: e.target.value }))}
                                                                                        value={formikProps.values?.dealer_name} />
                                                                                    {formikProps.errors.dealer_name ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.dealer_name}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Employee Code *</label>
                                                                                    <input id="Bond_Name" name="lastName" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_employee_code: e.target.value }))}
                                                                                        value={formikProps.values?.dealer_employee_code} />
                                                                                    {formikProps.errors.dealer_employee_code ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.dealer_employee_code}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_status" className='bond-label'>Current Status</label>
                                                                                    <select id="dealer_status" name="dealer_status"
                                                                                        onChange={formikProps.handleChange}
                                                                                        value={formikProps.values?.dealer_status}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {
                                                                                            FormDefaultConstants.dealerStatusOption.map((gen) => {
                                                                                                return <option value={gen.value}>{gen.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_managed_by" className='bond-label'>Manage By</label>
                                                                                    <select id="dealer_managed_by" name="dealer_managed_by"
                                                                                        onChange={formikProps.handleChange}
                                                                                        value={formikProps.values?.dealer_managed_by}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        {
                                                                                            FormDefaultConstants.dealerManagedbyOption.map((gen) => {
                                                                                                return <option value={gen.value}>{gen.label}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_branch_name" className='bond-label'>Branch Name</label>
                                                                                    <select id="dealer_branch_name" name="dealer_branch_name"
                                                                                        onChange={formikProps.handleChange}
                                                                                        value={formikProps.values?.dealer_branch_name}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="">Select</option>
                                                                                        <option value="Food">barnch 1</option>
                                                                                        <option value="Entertainment">branch 2</option>
                                                                                        <option value="Academic">branch 3</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Date of Joining</label>
                                                                                    <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={DOJDate}
                                                                                        onChange={handleDOJChange}
                                                                                        renderInput={(params: any) => <TextField {...params} style={{ borderColor: "none" }} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="Bond_Name" className='bond-label'>Date of Birth</label>
                                                                                    <DesktopDatePicker
                                                                                        inputFormat="DD/MM/YYYY"
                                                                                        value={DOBDate}
                                                                                        onChange={handleDOBChange}
                                                                                        renderInput={(params: any) => <TextField {...params} />}
                                                                                        className="date-picker-input-style"
                                                                                        components={{
                                                                                            OpenPickerIcon: DateIcon
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </Grid>

                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_phone" className='bond-label'>Phone No</label>
                                                                                    <input id="dealer_phone" name="dealer_phone" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_phone: e.target.value }))}
                                                                                        value={formikProps.values?.dealer_phone} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_mobile" className='bond-label'>Mobile No *</label>
                                                                                    <input id="dealer_mobile" name="dealer_mobile" type="number" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_mobile: e.target.value }))}
                                                                                        value={formikProps.values?.dealer_mobile} />
                                                                                    {formikProps.errors.dealer_mobile ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.dealer_mobile}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_email" className='bond-label'>Email ID *</label>
                                                                                    <input id="dealer_email" name="dealer_email" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_email: e.target.value }))}
                                                                                        value={formikProps.values?.dealer_email} />
                                                                                    {formikProps.errors.dealer_email ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.dealer_email}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_address_line1" className='bond-label'>Address Line 1</label>
                                                                                    <input id="dealer_address_line1" name="dealer_address_line1" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_address_line1: e.target.value }))}
                                                                                        value={formikProps.values?.dealer_address_line1} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_address_line2" className='bond-label'>Address Line 2</label>
                                                                                    <input id="dealer_address_line2" name="dealer_address_line2" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_address_line2: e.target.value }))}
                                                                                        value={formikProps.values?.dealer_address_line2} />
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_state_id" className='bond-label'>State</label>
                                                                                    <select id="dealer_state_id" name="dealer_state_id"
                                                                                        onChange={formikProps.handleChange}
                                                                                        value={formikProps.values?.dealer_state_id}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="1">Tamil Nadu</option>
                                                                                        <option value="2">Karnataka</option>
                                                                                        <option value="3">Kerala</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_city_id" className='bond-label'>City</label>
                                                                                    <select id="dealer_city_id" name="dealer_city_id"
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_city_id: parseInt(e.target.value) }))}
                                                                                        value={formikProps.values?.dealer_city_id}
                                                                                        className='bond-input-select'
                                                                                    >
                                                                                        <option value="1">Chennai</option>
                                                                                        <option value="2">Salem</option>
                                                                                        <option value="3">Coimbatore</option>
                                                                                    </select>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_remarks" className='bond-label'>Remarks</label>
                                                                                    <input id="dealer_remarks" name="dealer_remarks" type="text" className='bond-input'
                                                                                        onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_remarks: e.target.value }))}
                                                                                        value={formikProps.values?.dealer_remarks} />
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </div>
                                                            <div className="row">
                                                                <div className="card" style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px", paddingTop: "10px", paddingBottom: "20px" }}>
                                                                    <div className="card-header">
                                                                        <h5 className="m-0 header"> Login Details </h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <Grid direction="row" container spacing={2}>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_unique_code" className='bond-label'>Unique Code*</label>
                                                                                    <input id="dealer_unique_code" name="dealer_unique_code" type="text" className='bond-input'
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, dealer_unique_code: e.target.value })); }}
                                                                                        value={formikProps.values?.dealer_unique_code}
                                                                                        onBlur={e => setunicode_value(e.target.value)}
                                                                                    />
                                                                                    {formikProps.errors.dealer_unique_code ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.dealer_unique_code}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_user_name" className='bond-label'>User Name*</label>
                                                                                    <input id="dealer_user_name" name="dealer_user_name" type="text" className='bond-input'
                                                                                        onChange={e => { setFormikInitialData(({ ...formikInitialData, dealer_user_name: e.target.value })); }}
                                                                                        value={formikProps.values?.dealer_user_name}
                                                                                        onBlur={e => setUserNameValue(e.target.value)}
                                                                                    />
                                                                                    {formikProps.errors.dealer_user_name ? (<div style={{ color: "#FF0000" }}>{formikProps.errors.dealer_user_name}</div>) : null}
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item xs={4}>
                                                                                <div className='d-grid'>
                                                                                    <label htmlFor="dealer_password" className='bond-label'>Password</label>
                                                                                    <div className='row'>
                                                                                        <div className={`col-lg-${location.state.mode == 'edit' ? '8' : '12'}`}>
                                                                                            <input id="dealer_password" name="dealer_password" type="text" style={{ width: "100%" }} className='bond-input'
                                                                                                onChange={e => setFormikInitialData(({ ...formikInitialData, dealer_password: e.target.value }))}
                                                                                                value={formikProps.values?.dealer_password} />
                                                                                        </div>
                                                                                        {
                                                                                            location.state.mode == 'edit' ?
                                                                                                <div className='col-lg-4'>
                                                                                                    <button className='resend-btn' onClick={() => loadDealerLoginDetails(formikProps.values?.dealer_id, formikProps.values?.dealer_user_name, formikProps.values?.dealer_password, formikProps.values?.dealer_unique_code)}>ReSend</button>
                                                                                                </div> : ""
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row text-end my-3 py-5 '>
                                                                <div className=''>
                                                                    <a className='cancel-btn mx-3' onClick={() => navigate(-1)} >Cancel</a>
                                                                    <button type='submit' className='save-btn'>
                                                                        {location.state.mode == 'edit' ? 'Update' : 'Save'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </LocalizationProvider>
                                                    </Form>
                                                )
                                            }}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
            <SimpleModal contents={view_content} handleOpen={() => handleClickOpen} openModal={open} handleClose={(e: any) => handleClose(e)} />
            <ConfirmationModal contents="Are you sure you want to activate the user/account?" details_datas={user_id} openModal={open_modal} handleClose={(e: any) => handleClose_modal(e)} currentPage="suspend-user-list" />

        </div>
    );
}

export default DealerAdd;

